import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddressService } from "../../../../shared/services/address.service";
import { Address } from "../../../../shared/models";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Country } from "../../../../shared/models";
import { State } from "../../../../shared/models";
import { City } from "../../../../shared/models";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ZonesService } from "../../../../shared/services/zones.service";
import { SelectedZone } from "../../../../shared/models";
import { REGULAR_EXPRESSION } from "../../../../shared/enum/regular-expression.enum";

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit, OnDestroy {

  isModeEdit = false;
  isCheckout = false;
  isShippingAddress = false;
  isProfileAddress = false;
  modalHeader: string = 'ADD_ADDRESS';
  address: Address | null = null;

  addressForm: FormGroup = new FormGroup({
    id: new FormControl(this.address?.id || null),
    firstname: new FormControl(null, [
      Validators.required,
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
      Validators.maxLength(35)
    ]),
    lastname: new FormControl(null, [
      Validators.required,
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
      Validators.maxLength(35)
    ]),
    email_receiver: new FormControl(null),
    address1: new FormControl(null, [
      Validators.required,
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
      Validators.maxLength(100)
    ]),
    address2: new FormControl(null, [
      Validators.maxLength(100),
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING)
    ]),
    city: new FormControl(null, [Validators.required, Validators.pattern(/^[\w\sñÑáéíóúÁÉÍÓÚ.-]+$/)]),
    state_name: new FormControl(null, Validators.required),
    country_iso: new FormControl(null, Validators.required),
    phone: new FormControl(null, Validators.required),
    zipcode: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9-]+$/)]),
    // label: new FormControl(null, Validators.required),
  });

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedZipCodes: string[] = [];
  isLoading: boolean = true;
  isLoadingCountries: boolean = true;
  isLoadingStates: boolean = true;
  isLoadingCities: boolean = true;
  isSaving = false;
  faSpinner = faSpinner;

  countries: Country[] = [];
  states: State[] = [];
  cities: City[] = [];
  postalCodes: string[] = [];
  showSelectInput: boolean = true;
  countryCuIso: string = 'CU';
  countryIsoSelected: string = 'cu';
  isLoadingPhones = true;

  formMarkAsTouched: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private addressService: AddressService,
    private zonesService: ZonesService,
  ) {
    this.isModeEdit = data.isModeEdit;
    this.isCheckout = data.isCheckout;
    this.isShippingAddress = data.isShippingAddress;
    this.isProfileAddress = data.isProfileAddress;
    if (this.isModeEdit) {
      this.address = data.data;
      if (this.isProfileAddress) {
        this.modalHeader = 'COMPONENTS.CHECKOUT.EDIT_ADDRESS';
      } else {
        this.modalHeader = this.isShippingAddress ? 'COMPONENTS.CHECKOUT.EDIT_SHIPPING_ADDRESS' : 'COMPONENTS.CHECKOUT.EDIT_BILLING_ADDRESS';
      }
    } else {
      if (this.isProfileAddress) {
        this.modalHeader = 'COMPONENTS.CHECKOUT.ADD_ADDRESS';
      } else {
        this.modalHeader = this.isShippingAddress ? 'COMPONENTS.CHECKOUT.ADD_SHIPPING_ADDRESS' : 'COMPONENTS.CHECKOUT.ADD_BILLING_ADDRESS';
      }
    }
  }

  // get label() { return this.addressForm.get('label'); }
  get firstname() { return this.addressForm.get('firstname'); }
  get lastname() { return this.addressForm.get('lastname'); }
  get email_receiver() { return this.addressForm.get('email_receiver'); }
  get address1() { return this.addressForm.get('address1'); }
  get address2() { return this.addressForm.get('address2'); }
  get country_iso() { return this.addressForm.get('country_iso'); }
  get state_name() { return this.addressForm.get('state_name'); }
  get city() { return this.addressForm.get('city'); }
  get zipcode() { return this.addressForm.get('zipcode'); }
  get phone() { return this.addressForm.get('phone'); }

  ngOnInit(): void {
    if (this.isModeEdit) {
      this.addressForm.patchValue({
        id: new FormControl(this.address?.id),
        firstname: this.address?.firstname,
        lastname: this.address?.lastname,
        email_receiver: this.isCheckout ? this.address?.email_receiver : null,
        address1: this.address?.address1,
        address2: this.address?.address2 || null,
        city: this.address?.city,
        state_name: this.address?.state_code, //state_name
        country_iso: this.address?.country_iso,
        phone: this.address?.phone,
        zipcode: this.address?.zipcode,
        // label: this.address?.label || null,
      });
      if (this.isCheckout) {
        this.addressForm.get('email_receiver')?.setValidators([Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._-]+@[a-z]+\.[a-z.]{2,5}$/)]);
      }
      this.countryIsoSelected = this.address?.country_iso.toLowerCase() || 'cu';
    } else {
      if (!this.isShippingAddress) {
        this.changeViewPhone();
      }
    }

    let requestCountries;
    if (this.isProfileAddress) {
      requestCountries = this.addressService.getCountries();
    } else {
      requestCountries = this.isShippingAddress ? this.zonesService.getShippableCountries() : this.zonesService.getBillableCountries();
    }


    requestCountries
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: Country[]) => {
        this.countries = response.sort((a, b) => a.name.localeCompare(b.name));
        this.isLoadingCountries = false;
        if (this.isModeEdit) {
          const countryIso = this.addressForm.get('country_iso')?.value;
          const countryAux = this.countries.find(it => it.iso === countryIso);
          this.showSelectInput = this.countryCuIso == countryIso;
          if (countryAux) {
            this.changeViewPhone();
            this.addressService.getStates(countryAux.id).pipe(takeUntil(this.destroy$)).subscribe((states: State[]) => {
              this.states = [...states.sort((a, b) => a.name.localeCompare(b.name))];
              this.isLoadingStates = false;
              const stateAbbr = this.addressForm.get('state_name')?.value;
              const stateAux = this.states.find(it => it.abbr === stateAbbr);
              if (stateAux && this.showSelectInput) {
                if (this.isShippingAddress) {
                  this.setDisableAddress(this.showSelectInput);
                }
                this.addressService.getCities(countryIso, stateAux.abbr).pipe(takeUntil(this.destroy$)).subscribe((cities: City[]) => {
                  this.cities = [...cities.sort((a, b) => a.name.localeCompare(b.name))];
                  const cityName = this.addressForm.get('city')?.value;
                  const cityAux = this.cities.find(it => it.name == cityName);
                  if (cityAux) {
                    this.postalCodes = cityAux.zip_code;
                  }
                  this.isLoadingCities = false;
                });
              } else {
                this.isLoadingCities = false;
              }
            });
          } else {
            this.isLoadingStates = false;
            this.isLoadingCities = false;
          }
        } else {
          if (this.isShippingAddress) {
            this.getZoneDataAndPatchFormOnShippingAddress(this.countries);
          } else {
            this.isLoadingStates = false;
            this.isLoadingCities = false;
          }
        }
      });

    // this.addressService.getCountries().subscribe((countries: Country[]) => {
    //   // this.countries = countries.sort((a, b) => a.name.localeCompare(b.name));
    //   this.countries = countries.filter(country => country.iso == 'CU');
    //   this.isLoadingCountries = false;
    //   if (this.isModeEdit) {
    //     const countryIso = this.addressForm.get('country_iso')?.value;
    //     const countryAux = this.countries.find(it => it.iso === countryIso);
    //     if (countryAux) {
    //       this.addressService.getStates(countryAux.id).subscribe((states: State[]) => {
    //         this.states = [...states.sort((a, b) => a.name.localeCompare(b.name))];
    //         this.isLoadingStates = false;
    //         const stateAbbr = this.addressForm.get('state_name')?.value;
    //         const stateAux = this.states.find(it => it.abbr === stateAbbr);
    //         if (stateAux) {
    //           this.addressService.getCities(countryIso, stateAux.abbr).subscribe((cities: City[]) => {
    //             this.cities = [...cities.sort((a, b) => a.name.localeCompare(b.name))];
    //             const cityName = this.addressForm.get('city')?.value;
    //             const cityAux = this.cities.find(it => it.name == cityName);
    //             if (cityAux) {
    //               this.postalCodes = cityAux.zip_code;
    //             }
    //             this.isLoadingCities = false;
    //           });
    //         } else {
    //           this.isLoadingCities = false;
    //         }
    //       });
    //     } else {
    //       this.isLoadingStates = false;
    //       this.isLoadingCities = false;
    //     }
    //   } else {
    //     this.isLoadingStates = false;
    //     this.isLoadingCities = false;
    //   }
    // });
  }

  getZoneDataAndPatchFormOnShippingAddress(countries: Country[]) {
    const selectedZone = this.getSelectedZone;
    const countryAux = countries.find(it => it.iso === selectedZone.area_selected);
    if (countryAux) {
      const patchData: { country_iso: string, state_name?: string, city?: string } = { country_iso: countryAux.iso };
      this.addressService.getStateByCountryIdAndZoneId(countryAux.id, selectedZone.zone)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: state => {
            if (state) {
              patchData['state_name'] = state.abbr;
              patchData['city'] = selectedZone.municipality_name;
              this.addressForm.patchValue(patchData);

              this.showSelectInput = this.countryCuIso == countryAux.iso;

              this.setDisableAddress(this.showSelectInput);

              this.countryIsoSelected = countryAux.iso.toLowerCase();
              this.changeViewPhone();

              this.addressService.getStates(countryAux.id).pipe(takeUntil(this.destroy$)).subscribe((states: State[]) => {
                this.states = [...states.sort((a, b) => a.name.localeCompare(b.name))];
                this.isLoadingStates = false;
                const stateAbbr = this.addressForm.get('state_name')?.value;
                const stateAux = this.states.find(it => it.abbr === stateAbbr);
                if (stateAux && this.showSelectInput) {
                  this.addressService.getCities(countryAux.iso, stateAux.abbr).pipe(takeUntil(this.destroy$)).subscribe((cities: City[]) => {
                    this.cities = [...cities.sort((a, b) => a.name.localeCompare(b.name))];
                    const cityName = this.addressForm.get('city')?.value;
                    const cityAux = this.cities.find(it => it.name == cityName);
                    if (cityAux) {
                      this.postalCodes = cityAux.zip_code;
                    }
                    this.isLoadingCities = false;
                  });
                } else {
                  this.isLoadingCities = false;
                }
              });
            }
          }
        });
    }

  }

  changeCountry(event: any) {
    this.isLoadingStates = true;
    this.showSelectInput = this.countryCuIso == event.value;
    this.addressForm.patchValue({
      state_name: null,
      city: null,
      zipcode: null,
      phone: null,
    });
    const usCountry = this.countries.find(it => it.iso === event.value);
    if (usCountry) {
      this.countryIsoSelected = event.value.toLowerCase();
      this.changeViewPhone();
      this.addressService.getStates(usCountry.id).subscribe((states) => {
        this.states = [...states.sort((a, b) => a.name.localeCompare(b.name))];
        this.cities = [];
        this.postalCodes = [];
        this.isLoadingStates = false;
      });
    }
  }

  changeViewPhone() {
    this.isLoadingPhones = true;
    setTimeout(() => {
      this.isLoadingPhones = false;
    }, 300);
  }

  changeState(event: any) {
    this.isLoadingCities = true;
    this.addressForm.patchValue({
      city: null,
      zipcode: null,
    });
    const countryIso = this.addressForm.get('country_iso')?.value;
    const usCountry = this.countries.find(it => it.iso === countryIso);
    if (usCountry && this.countryCuIso == countryIso) {
      this.addressService.getCities(countryIso, event.value).subscribe(cities => {
        this.cities = this.cities = [...cities.sort((a, b) => a.name.localeCompare(b.name))];
        this.postalCodes = [];
        this.isLoadingCities = false;
      });
    } else {
      this.isLoadingCities = false;
    }
  }

  changeCity(event: any) {
    this.addressForm.patchValue({
      zipcode: null,
    });
    const city = this.cities.find(it => it.name === event.value);
    if (city) {
      this.postalCodes = city.zip_code;
    } else {
      this.postalCodes = [];
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  onSubmit(): void {
    this.addressForm.markAllAsTouched();
    this.addressForm.get('phone')?.updateValueAndValidity();
    this.formMarkAsTouched = true;
    if (this.addressForm.valid) {
      this.isSaving = true;
      const address = this.addressForm.getRawValue();
      address.city = address.city.trim();
      this.setDisableForm();

      if (!this.isModeEdit) {
        delete address.id;
        this.addressService.createAddress(address).pipe(takeUntil(this.destroy$))
          .subscribe((response) => {
            this.isSaving = false;
            this.setEnableForm();
            this.dialogRef.close(Object.assign(response, { modified: true }));
          }, (err) => {
            // this.notificationService.showAndSubscribe('ADD_ADDRESS_SUCCESS', 'ACCEPT');
            this.setEnableForm();
            this.isSaving = false;
          });
      } else {
        this.addressService.updateAddress(this.address!.id!, address).pipe(takeUntil(this.destroy$))
          .subscribe((response) => {
            this.isSaving = false;
            this.setEnableForm();
            this.dialogRef.close(Object.assign(response, { modified: true, email_receiver: address.email_receiver }));
          }, (err) => {
            this.setEnableForm();
            this.isSaving = false;
          });
      }
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  // loadDataOnEdit() {
  //   this.addressService.getCountries()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((countries: Country[]) => {
  //     this.countries = countries.sort((a, b) => a.name.localeCompare(b.name));
  //     const countrySelected = countries.find((country: Country) => country.iso === this.address!.country_iso);
  //     this.addressForm.get('country_iso')!.setValue(countrySelected);
  //     this.addressForm.get('state_name')!.enable();
  //
  //     this.addressService.getStates(countrySelected!.id)
  //       .pipe(takeUntil(this.destroy$))
  //       .subscribe((states: State[]) => {
  //       this.states = [...states.sort((a, b) => a.name.localeCompare(b.name))];
  //       countrySelected!.states = [...this.states];
  //       const stateSelected = states.find((state: State) => state.abbr === this.address!.state_code);
  //       this.addressForm.get('state_name')!.setValue(stateSelected);
  //       this.addressForm.get('city')!.enable();
  //
  //       this.addressService.getCities(countrySelected!.iso, stateSelected!.abbr)
  //         .pipe(takeUntil(this.destroy$))
  //         .subscribe((cities: City[]) => {
  //         this.cities = [...cities.sort((a, b) => a.name.localeCompare(b.name))];
  //         stateSelected!.cities = [...this.cities];
  //         const citySelected = cities.find((city: City) => city.name === this.address!.city);
  //         this.selectedZipCodes = citySelected?.zip_code || [];
  //         this.addressForm.get('city')!.setValue(citySelected);
  //         this.addressForm.get('zipcode')!.enable();
  //         this.isFirstLoad = false;
  //         this.isLoading = false;
  //       });
  //     });
  //   });
  // }

  setDisableForm() {
    this.addressForm.get('zipcode')?.disable();
    this.addressForm.get('firstname')?.disable();
    this.addressForm.get('lastname')?.disable();
    this.addressForm.get('address1')?.disable();
    this.addressForm.get('address2')?.disable();
    this.addressForm.get('city')?.disable();
    this.addressForm.get('phone')?.disable();
    this.addressForm.get('zipcode')?.disable();
    this.addressForm.get('state_name')?.disable();
    this.addressForm.get('country_iso')?.disable();
  }

  setDisableAddress(showSelectInput: boolean) {
    if (showSelectInput) {
      this.addressForm.get('city')?.disable();
    }
    this.addressForm.get('state_name')?.disable();
    this.addressForm.get('country_iso')?.disable();
  }

  setEnableForm() {
    this.addressForm.get('zipcode')?.enable();
    this.addressForm.get('firstname')?.enable();
    this.addressForm.get('lastname')?.enable();
    this.addressForm.get('address1')?.enable();
    this.addressForm.get('address2')?.enable();
    this.addressForm.get('city')?.enable();
    this.addressForm.get('phone')?.enable();
    this.addressForm.get('zipcode')?.enable();
    this.addressForm.get('state_name')?.enable();
    this.addressForm.get('country_iso')?.enable();
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

}
