import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {CartPreviewComponent} from "../../../payment/components/cart-preview/cart-preview.component";
import {InternalNotificationDto} from "../internal-notifications/internalNotificationDto.interface";
import {InternalNotificationService} from "../../services/internal-notification.service";
import {faClose, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";

@Component({
  selector: 'app-notification-tray',
  templateUrl: './notification-tray.component.html',
  styleUrls: ['./notification-tray.component.scss']
})
export class NotificationTrayComponent implements OnInit {

  notifications: InternalNotificationDto[] = [];
  isLoading: boolean = true;

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<CartPreviewComponent>,
    private notificationService: InternalNotificationService
  ) {
  }

  ngOnInit(): void {
    this.loadNotifications()

    this.notificationService.notificationAdded.subscribe((data: any) => {
      const newNotification = convertKeysToLowerCase(data) as InternalNotificationDto;
      this.notifications.unshift(newNotification);
    })
  }

  loadNotifications(): void {
    this.notificationService.getNotifications(10, false, 1).subscribe((result: {
      data: { items: InternalNotificationDto[] }
    }) => {
      this.notifications = result.data.items;
      this.isLoading = false;
    });
  }

  closeTray() {
    this.dialogRef.close();
  }

  navigateToFull() {
    this.router.navigate(['/profile/notifications']);
    this.closeTray();
  }

  removeFromList(notificationId: string) {
    this.notifications = this.notifications.filter(notification => notification.id !== notificationId);
  }

  readonly faTimes = faTimes;

  readAndClose($event: string) {
    this.removeFromList($event);
    this.closeTray()
  }

  markAllRead() {
    this.loading = true
    this.notificationService.markAsRead(this.notifications.map(n => n.id)).subscribe(() => {
      this.notifications = [];
      this.loading = false;
    })
  }

  readonly faSpinner = faSpinner;
  readonly faClose = faClose;
  loading: boolean = false;
}

export function convertKeysToLowerCase(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(convertKeysToLowerCase);
  }

  return Object.keys(obj).reduce((acc, key) => {
    const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
    acc[lowerCaseKey] = convertKeysToLowerCase(obj[key]);
    return acc;
  }, {} as any);
}
