import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faShoppingCart, faClose } from '@fortawesome/free-solid-svg-icons';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Cart, SelectedZone } from 'src/app/shared/models';
import { NewCart } from 'src/app/shared/models/new-cart.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NewCartService } from 'src/app/shared/services/newcart.service';
import { ZonesService } from 'src/app/shared/services/zones.service';

@Component({
  selector: 'app-cart-preview',
  templateUrl: './cart-preview.component.html',
  styleUrls: ['./cart-preview.component.scss']
})
export class CartPreviewComponent implements OnInit, OnDestroy {
  cart_text: string = 'COMPONENTS.CART_PREVIEW.CART_PRODUCTS';
  others_text: string = 'COMPONENTS.CART_PREVIEW.OTHERS';
  go_to_cart_text = 'COMPONENTS.PROFILE.PAYMENTS.GO_TO_CART';
  cart_icon = faShoppingCart;
  close_icon = faClose;
  default = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  languageServiceSubscription!: Subscription;
  isShowSkeletonCart: boolean = true;
  mercado_unico_products: any = [];
  other_stores_products: any = [];
  isDeletingProduct = false;
  total: number | undefined = 0;
  skeletons = [1, 2];
  cart: any;
  stores = <any>[];
  products = <any>[];
  cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart'];
  user: any;

  constructor(
    private dialogRef: MatDialogRef<CartPreviewComponent>,
    private zonesService: ZonesService,
    private cartService: NewCartService,
    private languageService: LanguageService,
    private authService: AuthService
  ) {
    this.authService.currentUserSubject.subscribe((user) => {
      this.user = user;
      if (this.user?.id && this.user?.readyToUse) {
        this.cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart', 'new'];
        // if (this.user?.useNewCart) {
        //   this.cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart', 'new'];
        // } else {
        //   this.cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart'];
        // }
      } else {
        this.cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart'];
      }
    });
  }

  ngOnInit(): void {
    this.isShowSkeletonCart = true;
    this.getCart();
    this.cartService.newCartSubject$
      .pipe(takeUntil(this.destroy$))
      .subscribe((cart: NewCart | undefined) => {
        this.cart = cart;
        this.total = cart?.displayPaymentSummary?.orderTotal;
        this.divideProducts(cart);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
    this.languageServiceSubscription?.unsubscribe();
  }

  getCart() {
    this.languageServiceSubscription = this.languageService.selectedLanguage.subscribe(lang => {
      if (this.cartService.haveCart()) {
        this.cartService.getShoppingCart(this.getSelectedZone.zone || 6, this.getSelectedZone.municipality)
          .pipe(takeUntil(this.destroy$))
          .subscribe((cart: any) => {
            this.mercado_unico_products = [];
            this.other_stores_products = [];
            this.divideProducts(cart);
            this.isDeletingProduct = false;
            this.cartService.setBlockDeleteFromCartEvent(false);
          });
      } else {
        this.isShowSkeletonCart = false;
      }
    });
  }

  selectStore(num: number) {
    return num == 1 ? this.default = true : this.default = false;
  }

  closePreview() {
    this.dialogRef.close();
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  divideProducts(cart: any) {
    this.mercado_unico_products = [];
    this.other_stores_products = [];
    this.stores = cart?.groupedByStoreItems;
    for (let i = 0; i < this.stores?.length; i++) {
      ['mercado único', 'mercado unico'].includes(this.stores[i]?.storeName.toLowerCase()) ?
        this.mercado_unico_products = [...this.mercado_unico_products, ...this.stores[i]?.lineItems] :
        this.other_stores_products = [...this.other_stores_products, ...this.stores[i]?.lineItems]
    }
    // this.cart = cart;
    this.products = [...this.mercado_unico_products, ...this.other_stores_products]
    this.isShowSkeletonCart = false;
    if (this.mercado_unico_products.length > 0 && this.other_stores_products.length == 0) this.default = true;
    if (this.mercado_unico_products.length == 0 && this.other_stores_products.length > 0) this.default = false;
    // if (this.mercado_unico_products.length > 0 && this.other_stores_products.length > 0) this.default = true;
    // })
  }

  calcPrice(arr: any) {
    return arr.reduce((acum: number, item: any) => acum + (+item.totalDisplayPrice!), 0);
  }

  updateCart(e: any) {
    return null;
  }
}
