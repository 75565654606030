import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaymentRoutesEnum } from 'src/app/shared/enum/payment-routes.enum';
import { SelectedZone, User } from 'src/app/shared/models';
import { WalletService } from 'src/app/shared/services/wallet.service';
import { ZonesService } from 'src/app/shared/services/zones.service';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { CartService } from "../../../../shared/services/cart.service";
import { AuthService } from "../../../../shared/services/auth.service";
import { NotificationService } from "../../../../shared/services/notification.service";
import {
  LoadingWithImageModalComponent
} from "../../../shared/components/loading-with-image-modal/loading-with-image-modal.component";
import { CheckoutService } from "../../../../shared/services/checkout.service";
import { FrontendFeatureService } from "../../../../shared/services/frontend-feature.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: 'app-gift-preview',
  templateUrl: './gift-preview.component.html',
  styleUrls: ['./gift-preview.component.scss']
})
export class GiftPreviewComponent implements OnInit, OnDestroy {
  loading_gift_cards = false;
  gift_cards: any = [];
  faClose = faClose;
  giftCardOrderId: any;
  user: User = this.authService.getCurrentUser();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private service: WalletService,
    private router: Router,
    private zonesService: ZonesService,
    private dialog: MatDialog,
    private dialogref: MatDialogRef<GiftPreviewComponent>,
    private cartService: CartService,
    private authService: AuthService,
    private frontendFeatureService: FrontendFeatureService,
    private newCartService: CartService,
    private notificationService: NotificationService,
    private checkoutService: CheckoutService) { }

  ngOnInit(): void {
    this.loadGiftCards();
    this.authService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(user => {
        this.user = user;
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  loadGiftCards() {
    this.loading_gift_cards = true;
    this.service.getGiftCards().subscribe({
      next: (data: any) => {
        this.loading_gift_cards = false;
        this.gift_cards = data?.data?.items?.map((data: any) => {
          return {
            promo_name: data?.name,
            id: data?.id,
            usd_display_amount: data?.displayPaymentAmount,
            image: data?.image,
            usd_amount: data?.paymentAmount,
          };
        });
      },
      error: (err) => {
        this.loading_gift_cards = false;
        console.log(err);
      }
    })
  }

  async selectedGiftCard(e: any) {
    await this.frontendFeatureService.updatePaymentFeature(this.user);
    this.dialog.closeAll();
    if (this.user && this.user?.useNewPayment) {
      this.selectedGiftCardNewPayment(e);
    } else {
      this.selectedGiftCardOldPayment(e);
    }
  }

  async selectedGiftCardOldPayment(e: any) {
    try {
      if (e) {
        console.log({
          ...e
        });
        let params = {
          pa: btoa(e?.paymentAmount),
          id: btoa(e?.giftCardProductId),
          pr: btoa(PaymentRoutesEnum.GIFT_CARDS.toString()),
          du: btoa(e?.display_usd)
        };
        this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/payment`],
          {
            // relativeTo: this.activeRoute,
            queryParams: params,
            queryParamsHandling: 'merge'
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  async selectedGiftCardNewPayment(e: any) {
    this.dialog.open(LoadingWithImageModalComponent, {
      id: 'charging-payments-modal',
      disableClose: true,
      data: {
        message: 'COMPONENTS.PAYMENTS.CREATING_ORDER_MESSAGE',
        title: 'COMPONENTS.PAYMENTS.CREATING_ORDER',
        img: '../../../../../assets/new-cart/place-modal-image.webp'
      }
    }).afterClosed().subscribe({
      next: (data) => { },
      error: (e) => { },
    });
    try {
      if (e) {
        console.log({
          ...e
        });
        this.checkoutService.placeV2GiftCardOrder({ giftCardProductId: e?.giftCardProductId }).subscribe({
          next: (data: any) => {
            if (data?.success) {
              this.giftCardOrderId = data.data.orderId;
              let params = {
                pa: btoa(e?.paymentAmount),
                id: btoa(e?.giftCardProductId),
                pr: btoa(PaymentRoutesEnum.GIFT_CARDS.toString()),
                du: btoa(e?.display_usd),
                orderId: data?.data?.orderId
              };
              const fingerPrint = this.newCartService.getFingerPrintFromSession();
              if (fingerPrint?.requestId && fingerPrint?.visitorId) {
                this.goToPayment(params, e?.paymentAmount, fingerPrint);
              } else {
                this.checkoutService.getFingerPrint().then((fingerPrint: any) => {
                  const data = fingerPrint ? {
                    visitorId: fingerPrint ? fingerPrint.visitorId : null,
                    requestId: fingerPrint ? fingerPrint.requestId : null
                  } : null;
                  this.goToPayment(params, e?.paymentAmount, data);
                });
              }
            }
            else
              this.notificationService.showAndSubscribe(data.message, 'COMPONENTS.COMMON.ACCEPT', 'COMPONENTS.COMMON.CANCEL').afterClosed();
          },
          error: (err) => {
            this.dialog.closeAll();
            if (err?.error?.message)
              this.notificationService.showAndSubscribe(err.error.message, 'COMPONENTS.COMMON.ACCEPT', 'COMPONENTS.COMMON.CANCEL').afterClosed();
            else
              this.notificationService.showAndSubscribe('NOTIFICATION_MESSAGES.GENERIC_ERROR', 'COMPONENTS.COMMON.ACCEPT', 'COMPONENTS.COMMON.CANCEL').afterClosed();
            console.log(err);
          }
        });
      }
    } catch (error) {
      this.dialog.closeAll();
      this.notificationService.showAndSubscribe('NOTIFICATION_MESSAGES.GENERIC_ERROR', 'COMPONENTS.COMMON.ACCEPT', 'COMPONENTS.COMMON.CANCEL').afterClosed();
      console.log(error);
    }
  }

  async goToPayment(params: any, total: number, fingerPrint: any) {
    const redirect = new URL(`${location.origin + this.router.url}`);
    redirect.search = '';
    redirect.searchParams.append('pa', params.pa);
    redirect.searchParams.append('id', params.id);
    redirect.searchParams.append('pr', params.pr);
    redirect.searchParams.append('du', params.du);
    redirect.searchParams.append('vid', fingerPrint?.visitorId);
    redirect.searchParams.append('rid', fingerPrint?.requestId);
    this.authService.getPaymentLink({
      redirectUrl: redirect.toString(),
      totalAmount: total,
      paymentRoute: PaymentRoutesEnum.GIFT_CARDS,
      paymentReference: params.orderId,
    }).subscribe(resp => {
      if (resp.success) {
        setTimeout(() => {
          this.dialog.getDialogById('charging-payments-modal')?.close();
        }, 2000);
        window.location.href = resp.data;
      } else {
        this.dialog.closeAll();
        if (resp.error?.message)
          this.notificationService.showAndSubscribe(resp.error.message, 'COMPONENTS.COMMON.ACCEPT', 'COMPONENTS.COMMON.CLOSE').afterClosed();
        else
          this.notificationService.showAndSubscribe('NOTIFICATION_MESSAGES.GENERIC_ERROR', 'COMPONENTS.COMMON.ACCEPT', 'COMPONENTS.COMMON.CLOSE').afterClosed();
      }
    });
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  close() {
    this.dialogref.close();
  }

  navigateToGiftCards() {
    this.close();
    this.router.navigateByUrl(`/gift-cards`);
  }

}
