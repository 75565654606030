<section class="relative bg-white
us:w-96 us:h-auto us:pl-4 us:pr-5 us:pt-5 us:pb-8
us-landscape:w-568px us-landscape:pb-5
md:w-528px
md-landscape:w-528px
lg-landscape:w-603px lg-landscape:h-auto lg-landscape:p-23px">
  <p class="relative uppercase font-Lato-Regular font-semibold
    us:text-sm us:tracking-1.75px
    lg-landscape:text-sm lg-landscape:tracking-1.75px">{{'COMPONENTS.COMMON.ADDITIONAL_INFORMATION' | translate}}</p>
  <div class="relative w-full border-t border-t-gray-#E0E0E0
    us:mt-3
    lg-landscape:mt-3"></div>
  <form class="relative w-full grid
                us:grid-cols-1 us:gap-y-6 us:mt-6
                us-landscape:grid-cols-1 us-landscape:gap-x-1.5 us-landscape:mt-4
                md:grid-cols-1 md:gap-x-1.5 md:mt-10
                 lg-landscape:grid-cols-1 lg-landscape:gap-x-6px lg-landscape:mt-12 " [formGroup]="phoneForm">

    <div class="relative w-full pb-6">
      <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                  us:text-15px us:ml-3 us:mb-1
                  md:mb-2.5
                  lg-landscape:text-15px lg-landscape:ml-18px lg-landscape:mb-3" for="">
        {{'COMPONENTS.COMMON.PHONE_NUMBER' | translate}}
      </p>
      <div class="relative">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <div class="w-5 h-5 text-gray-500 dark:text-gray-400">+53</div>
        </div>
        <input type="text" class="relative font-Lato-Regular w-full
                  us:text-15px us:h-12 us:rounded-3px
                  us-landscape:h-auto
                  md:h-auto
                  lg-landscape:text-15px px-4 py-3 bg-gray-#f2f2f2
                  active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                  focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1"
          formControlName="phone" maxlength="8" minlength="8" />
      </div>
      <div *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)" class="absolute bottom-0.5 text-sm text-red-500">
        <p *ngIf="phone?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}</p>
        <p *ngIf="phone?.hasError('pattern')">{{ 'COMPONENTS.COMMON.INVALID_PHONE_NUMBER' | translate }}</p>
        <p *ngIf="phone?.hasError('minlength')">{{ 'COMPONENTS.COMMON.INVALID_PHONE_NUMBER' | translate }}</p>
      </div>
    </div>
  </form>

  <div class="relative w-full flex
    us:justify-start us:mt-6
    lg-landscape:mt-7 lg-landscape:justify-end">
    <button class="relative bg-yellow-#FFC35D flex items-center cursor-pointer justify-center
        us:w-111px us:h-41px us:rounded-3px
        lg-landscape:w-111px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px" type="button"
      [disabled]="phoneForm.invalid" (click)="onSubmit()">
      <p class="relative font-Lato-Bold
            us:text-15px us:leading-4
         lg-landscape:text-15px lg-landscape:leading-4">{{'COMPONENTS.COMMON.ACCEPT' | translate}}</p>
    </button>

    <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D
        us:w-111px us:h-41px us:rounded-3px
        lg-landscape:w-111px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px ml-2" type="button"
      mat-dialog-close="close">
      <p class="relative font-Lato-Bold
         text-15px leading-4">{{'COMPONENTS.COMMON.CANCEL' | translate}}</p>
    </button>
  </div>
</section>