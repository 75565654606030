import { Component, OnDestroy, OnInit } from '@angular/core';
import { ZonesService } from "../../../../shared/services/zones.service";
import { Subject } from "rxjs";
import { Country, Municipality, SelectedZone, User, Zone } from "../../../../shared/models";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { MunicipalitiesService } from "../../../../shared/services/municipalities.service";
import { MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "../../../../shared/services/notification.service";
import { CartService } from "../../../../shared/services/cart.service";
import { Router } from "@angular/router";
import { ExcludeInitResourceEnum } from "../../../../shared/enum/exclude-init-resource.enum";
import { NewCartService } from "../../../../shared/services/newcart.service";
import { AuthService } from "../../../../shared/services/auth.service";
import { ChangeLocationResponse, ChangeLocationValidationResponse } from "../../../../shared/models/new-cart.model";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'app-province-selection',
  templateUrl: './province-selection.component.html',
  styleUrls: ['./province-selection.component.scss']
})
export class ProvinceSelectionComponent implements OnInit, OnDestroy {

  zones: Zone[] = [];
  municipalities: Municipality[] = [];
  zoneForm: FormGroup;

  destroy$: Subject<boolean> = new Subject<boolean>();

  selectedZones: SelectedZone;
  isLoadingZones = true;
  isLoadingMunicipalities = false;
  areas: Country[] = [];
  isLoadingAreas = true;
  countriesZones: any = {};

  isFirstLoad = true;
  isChangingZone = false;
  faSpinner = faSpinner;

  areaSelected: string | undefined;

  constructor(
    private zonesService: ZonesService,
    private municipalitiesService: MunicipalitiesService,
    private dialogRef: MatDialogRef<ProvinceSelectionComponent>,
    private notificationService: NotificationService,
    private cartService: CartService,
    private newCartService: NewCartService,
    private router: Router,
    private authService: AuthService
  ) {
    this.selectedZones = this.zonesService.getZonesValuesStorage();
    this.zoneForm = new FormGroup({
      area: new FormControl(this.selectedZones?.area_selected || 'CU', {
        validators: []
      }),
      zone: new FormControl(null, {
        validators: [Validators.required]
      }),
      municipality: new FormControl(null, {
        validators: this.selectedZones?.area_selected === 'CU' ? [Validators.required] : []
      }),
    });

    this.zoneForm.get('zone')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((zone: Zone) => {
        if (zone !== null) {
          if (!this.isFirstLoad) {
            this.zoneForm.get('municipality')?.reset();
          }
          if (zone?.zoneableIds) {
            this.isLoadingMunicipalities = true;
            this.municipalitiesService.getMunicipalitiesFromZoneIds(zone.zoneableIds)
              .pipe(takeUntil(this.destroy$))
              .subscribe((municipalities) => {
                this.municipalities = municipalities;
                this.isLoadingMunicipalities = false;
              });
          }
        }
      });

    this.zoneForm.get('area')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((area: string) => {
        if (!this.isFirstLoad) {
          this.zoneForm.get('zone')?.reset();
          this.zoneForm.get('municipality')?.reset();
        }
        this.areaSelected = this.areas.find((a) => a.iso === area)?.name; // todo chequear en enentorno real si esto se puede cambiar para .iso_name
        if (area !== 'CU') {
          this.zoneForm.get('municipality')?.clearValidators();
          this.zoneForm.get('municipality')?.updateValueAndValidity();
        } else {
          this.zoneForm.get('municipality')?.addValidators(Validators.required);
          this.zoneForm.get('municipality')?.updateValueAndValidity();
        }
        this.zones = this.countriesZones[area];
      });
  }

  ngOnInit(): void {
    this.zonesService.getShippableCountries()
      .pipe(takeUntil(this.destroy$))
      .subscribe((areas) => {
        this.areas = areas;
        this.isLoadingAreas = false;
      });
    this.zonesService.getZonesWithCountries()
      .pipe(takeUntil(this.destroy$))
      .subscribe((zones) => {
        const areaIso = this.zoneForm.get('area')?.value;
        this.countriesZones = zones.reduce((acum: any, item: Zone) => {
          if (!acum[item.countries_iso!]) {
            acum[item.countries_iso!] = [item];
          } else {
            acum[item.countries_iso!].push(item);
          }
          return { ...acum };
        }, this.countriesZones);
        this.zones = this.countriesZones[areaIso];
        this.isLoadingZones = false;
        this.selectedZones = this.getSelectedZones;
        if (this.selectedZones) {
          const selected_zone = this.zones.find((z) => z.id === this.selectedZones.zone);
          this.zoneForm.get('zone')?.patchValue(selected_zone);
          if (selected_zone && selected_zone.zoneableIds) {
            this.isLoadingMunicipalities = true;
            this.municipalitiesService.getMunicipalitiesFromZoneIds(selected_zone!.zoneableIds)
              .pipe(takeUntil(this.destroy$))
              .subscribe((municipalities) => {
                this.municipalities = municipalities;
                this.zoneForm.get('municipality')?.patchValue(this.municipalities.find((m) => m.id === this.selectedZones.municipality));
                this.isLoadingMunicipalities = false;
                this.isFirstLoad = false;
              });
          } else {
            this.isFirstLoad = false;
          }
        } else {
          this.isFirstLoad = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  get zone() {
    return this.zoneForm.get('zone');
  }

  get municipality() {
    return this.zoneForm.get('municipality');
  }

  get area() {
    return this.zoneForm.get('area')?.value;
  }

  onSubmit() {
    const values = this.zoneForm.value;
    const selectedZone = this.getSelectedZones;
    const equals = selectedZone ? (selectedZone.zone === values.zone.id && selectedZone.municipality === values.municipality?.id) : true;
    this.isChangingZone = true;
    const currentUser = this.authService.getCurrentUser();

    if (!equals && currentUser) {
      if (this.newCartService.haveCart() || this.cartService.haveCart()) {
        // const btn_ok_text = currentUser.useNewCart ? 'CHANGE' : 'EMPTY';
        const btn_ok_text = 'COMPONENTS.COMMON.CHANGE';
        this.notificationService.showAndSubscribe('CONFIRMATION_NEW_CART_CHANGE_TEXT', btn_ok_text, 'COMPONENTS.COMMON.CLOSE')
          .afterClosed()
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: ((data: any) => {
              if (data) {
                this.changeCartZoneAux(currentUser, values, equals);
              } else {
                this.isChangingZone = false;
              }
            }),
            error: (err => {
              this.isChangingZone = false;
              throw new Error(err);
            })
          });
      } else {
        this.changeCartZoneAux(currentUser, values, equals);
      }
    } else {
      if (currentUser) {
        this.changeCartZoneWithoutCart(currentUser, values, equals);
      } else {
        this.cartService.cleanStorage();
        this.cartService.removeCartValuesInStorage();
        this.setZoneValues(values);
      }
    }
  }

  private changeCartZoneWithoutCart(currentUser: User, values: any, equals: boolean) {
    if (!equals) {
      this.changeLocationCart(values);
    } else {
      this.setZoneValues(values);
    }
  }

  private changeCartZoneAux(currentUser: User, values: any, equals: boolean) {
    if (this.newCartService.haveCart()) {
      this.newCartService.changeCartLocationValidation({ zoneId: String(values.zone.id), municipalityId: String(values.municipality?.id) })
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: ChangeLocationValidationResponse) => {
          if (response.data && response.data.itemToBeRemoved) {
            this.notificationService.showAndSubscribe(response.data.message, 'COMPONENTS.COMMON.ACCEPT', 'COMPONENTS.COMMON.CANCEL')
              .afterClosed()
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: ((data: any) => {
                  if (data) {
                    this.changeLocationCart(values);
                  } else {
                    this.isChangingZone = false;
                  }
                }),
                error: (err => {
                  throw new Error(err);
                })
              });
          } else {
            this.changeLocationCart(values);
          }
        });
    } else {
      this.changeCartZoneWithoutCart(currentUser, values, equals);
    }
  }

  private changeLocationCart(values: any) {
    this.newCartService.changeCartLocation({ zoneId: String(values.zone.id), municipalityId: String(values.municipality?.id) })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ChangeLocationResponse) => {
        this.setZoneValues(values);
      });
  }

  private setZoneValues(values: any) {
    const excludedResources = ExcludeInitResourceEnum;
    const excluded = excludedResources.some((url) => this.router.url.includes(url));

    const isCheckoutCartUrl = this.router.url.includes('checkout/start');
    // const useNewCart = this.authService.getCurrentUser()?.useNewCart || false;
    const useNewCart = true;
    if (useNewCart && isCheckoutCartUrl) {
      this.router.navigate([`/${values.area.toLowerCase()}/cart/new`]);
    } else if ((this.getSelectedZones?.area_selected !== values.area || this.getSelectedZones?.countries_iso !== values.area) && !excluded && !useNewCart) {
      this.router.navigate(['/', values.area.toLowerCase(), 'home']);
    }

    this.zonesService.saveZoneValuesInStorage({
      area_selected: values.area,
      area_name: this.areaSelected!,
      zone: values.zone.id,
      zone_name: values.zone.name,
      municipality: values.municipality?.id || null,
      municipality_name: values.municipality?.name || null,
      countries_iso: values.zone?.countries_iso || null,
    });

    this.isChangingZone = false;
    this.dialogRef.close({ modified: true });
  }

  get getSelectedZones() {
    return this.zonesService.getZonesValuesStorage();
  }

}
