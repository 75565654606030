<div *ngIf="meta && meta.optionsToSelect" [formGroup]="form"
    class="relative flex flex-col justify-start items-start w-full">
    <label *ngIf="meta.label"
        class="relative font-Lato-Regular font-semibold text-gray-#5d5d5d text-sm leading-21px mb-1" for="">
        {{meta.label}}
    </label>
    <div class="relative w-full box-border pb-6">
        <mat-select [placeholder]="meta.placeHolder" (selectionChange)="selectChange($event)"
            [formControlName]="meta.fieldId" [ngClass]="{
                'border-none': selfControl?.valid || (selfControl?.invalid && !formMarkAsTouched && !(selfControl?.dirty || selfControl?.touched)),
                'border-red-500': (selfControl?.invalid && formMarkAsTouched) || (selfControl?.invalid && (selfControl?.dirty || selfControl?.touched))
            }" class="border relative bg-white text-gray-#252525 placeholder-gray-#9D9D9D font-Lato-Regular
                  w-full active:ring-0 flex items-center active:outline-none focus:ring-0 focus:outline-none outline-none
                  ring-0 border-none px-3 h-47px text-base" name="" id="">
            <mat-option *ngFor="let item of options" class="font-Lato-Regular" [value]="item.item1">
                {{item?.item2}}
            </mat-option>
        </mat-select>
        <div *ngIf="selfControl?.invalid && formMarkAsTouched || (selfControl?.invalid && !formMarkAsTouched && (selfControl?.dirty || selfControl?.touched))"
            class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="selfControl?.hasError('required')">{{ 'COMPONENTS.COMMON.SELECTION_REQUIRED' | translate }}</p>
        </div>
    </div>
    <div class="relative w-full box-border pb-6" *ngIf="meta.nestedField && nestedOptions.length > 0">
        <mat-select [placeholder]="meta.nestedField.placeHolder" [formControlName]="meta.nestedField.fieldId" [ngClass]="{
                        'border-none': selfNestedFieldControl?.valid || (selfNestedFieldControl?.invalid && !formMarkAsTouched && !(selfNestedFieldControl?.dirty || selfNestedFieldControl?.touched)),
                        'border-red-500': (selfNestedFieldControl?.invalid && formMarkAsTouched) || (selfNestedFieldControl?.invalid && (selfNestedFieldControl?.dirty || selfNestedFieldControl?.touched))
                    }" class="border relative bg-white text-gray-#252525 placeholder-gray-#9D9D9D font-Lato-Regular
                    w-full active:ring-0 flex items-center active:outline-none focus:ring-0 focus:outline-none outline-none
                    ring-0 border-none px-3 h-47px text-base " name="" id="">
            <mat-option *ngFor="let item of nestedOptions" class="font-Lato-Regular" [value]="item.item1">
                {{item?.item2}}
            </mat-option>
        </mat-select>
        <div *ngIf="selfNestedFieldControl?.invalid && formMarkAsTouched || (selfNestedFieldControl?.invalid && !formMarkAsTouched && (selfNestedFieldControl?.dirty || selfNestedFieldControl?.touched))"
            class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="selfNestedFieldControl?.hasError('required')">{{ 'COMPONENTS.COMMON.SELECTION_REQUIRED' |
                translate }}</p>
        </div>
    </div>
</div>