export const profile: any = {
    "headers": [
        {
            "text": "WELCOME"
        }
    ],
    "links": [
        {
            "text": "COMPONENTS.PROFILE.YOUR_ACCOUNT",
            "route": "/profile/account",
            "links": []
        },
        {
            "text": "COMPONENTS.PROFILE.ORDERS.ORDERS",
            "route": "/profile/orders",
            "links": []
        },
        {
            "text": "COMPONENTS.COMMON.NOTIFICATIONS",
            "route": "/profile/notifications?all=true",
            "links": []
        },
        // {
        //     "text": "Pagos",
        //     "route": "/profile/payments/123456",
        //     "links": []
        // },
        {
            "text": "COMPONENTS.PROFILE.PROFILE_PASS",
            "route": "/profile/user",
            "links": []
        },
        {
            "text": "COMPONENTS.PROFILE.PROFILE_ADDRESS",
            "route": "/profile/address/123456",
            "links": []
        },
        {
            "text": "COMPONENTS.PROFILE.PROFILE_CARDS",
            "route": "/profile/payments/cards",
            "links": []
        },
        {
            "text": "COMPONENTS.NAVIGATION.PROFILE_USER_PAYMENTS",
            "route": "/profile/user-payments",
            "links": []
        },
        {
            "text": "COMPONENTS.PROFILE.PROFILE_PAYMENT_REQUESTS",
            "route": "/profile/payment-requests",
            "links": []
        },
        {
            "text": "COMPONENTS.PROFILE.PROFILE_SHOP_LISTS",
            "route": "/profile/shop-lists",
            "links": []
        },
        // {
        //     "text": "Tus Recargas",
        //     "route": "/profile/recharges",
        //     "links": []
        // },
        // {
        //     "text": "Favoritos",
        //     "route": "",
        //     "links": []
        // },

        // {
        //     "text": "PROFILE_MESSAGES",
        //     "route": "/profile/messages",
        //     "links": []
        // },
        {
            "text": "COMPONENTS.NAVIGATION.CUSTOMER_SERVICE",
            "route": "/contents/customer-support",
            "links": []
        },
    ]
}
