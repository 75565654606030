import {Injectable, EventEmitter} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {of, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../shared/services/auth.service";
import {InternalNotificationDto} from "../notifications/internal-notifications/internalNotificationDto.interface";

const BASE_URL = `${environment.services_api_url}${environment.v1}/notification/internal`

@Injectable({
  providedIn: 'root'
})
export class InternalNotificationService {

  private GET_NOTIFICATIONS_URL = BASE_URL
  private GET_UNREAD_COUNT = BASE_URL + '/unreads' // This is a get and returns an object with the count in data.count
  private MARK_AS_READ = BASE_URL + '/markread' // This is a post and expects an array of ids in the body
  private DELETE = BASE_URL + '/delete' // This is a delete and expects an array of ids in the body

  notificationsRead: EventEmitter<number> = new EventEmitter<number>();
  notificationAdded: EventEmitter<InternalNotificationDto> = new EventEmitter<InternalNotificationDto>();

  constructor(private http: HttpClient, private authService: AuthService,
  ) {
  }

  getNotifications(count: number, allNotifications: boolean, page: number): Observable<any> {
    //Get headers
    let httpHeaders = this.authService.getHeader();

    const params = {page: page, pageSize: count.toString(), GetRead: allNotifications};

    return this.http.get(this.GET_NOTIFICATIONS_URL, {headers: httpHeaders, params: params});
  }

  getUnreadCount(): Observable<any> {
    let httpHeaders = this.authService.getHeader();

    return this.http.get(this.GET_UNREAD_COUNT, {headers: httpHeaders});
  }

  markAsRead(ids: string[]): Observable<any> {
    const count = ids.length;
    this.notificationsRead.emit(count);
    return this.http.put(this.MARK_AS_READ, {ids});
  }

  deleteNotifications(ids: string[]): Observable<any> {
    const count = ids.length;
    console.log(count)
    this.notificationsRead.emit(count);
    return this.http.request('delete', this.DELETE, {body: {ids}});
  }
}
