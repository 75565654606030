<section class="relative w-full h-auto flex flex-wrap justify-center items-center bg-white">
  <div class="relative overflow-hidden
    us:px-5 us:w-full
    md:px-14
    lg:px-16
    xl:w-1291px">

    <p class="relative font-Lato-Regular font-semibold text-gray-#252525 mt-5 mb-5
                us:text-19px
                lg-landscape:text-19px">{{ 'COMPONENTS.COMMON.NOTIFICATIONS' | translate }}</p>

    <div *ngIf="isLoadingInit" class="flex flex-col rounded bg-white gap-2">
      <div class="w-full h-24 bg-gray-300"></div>
      <div class="w-full h-24 bg-gray-300"></div>
      <div class="w-full h-24 bg-gray-300"></div>
      <div class="w-full h-24 bg-gray-300"></div>
      <div class="w-full h-24 bg-gray-300"></div>
      <div class="w-full h-24 bg-gray-300"></div>
    </div>

    <div *ngIf="!isLoadingInit" class="flex flex-col rounded bg-white">
      <cdk-virtual-scroll-viewport itemSize="50" [ngClass]="{'tray': !allNotifications}"
        class="flex flex-col gap-4 bg-white main " (scrolledIndexChange)="onScroll($event)">
        <div *ngIf="notifications.length === 0" class="text-center text-gray-500 pt-4">
          {{ 'COMPONENTS.COMMON.NO_NOTIFICATIONS' | translate }}
        </div>
        <app-notification-card *cdkVirtualFor="let notification of notifications; let i = index" [index]="i"
          [notification]="notification" [showFullDetails]="true" (delete)="deleteNotification($event)">
        </app-notification-card>
      </cdk-virtual-scroll-viewport>
    </div>

    <div class="h-10"></div>

  </div>
</section>