<div (click)="onPrimaryAction()"
  [ngClass]="{'bg-white': notification.readAt, 'bg-blue-50': !notification.readAt, 'border-t': index == 0, 'bg-gray-#f2f2f2': index % 2 == 0}"
  class="flex justify-between items-start p-4 border-b last:border-b-0 bg-white">
  <div class="flex items-start">
    <div class="flex-shrink-0 mr-4">
      <fa-icon [icon]="faBell"
        [ngClass]="{'text-gray-400': notification.readAt, 'text-yellow-#FFA101': !notification.readAt}"
        class="h-6 w-6"></fa-icon>
    </div>
    <div class="flex-grow">
      <h3 [ngClass]="{'text-gray-900': notification.readAt, 'text-blue-800': !notification.readAt}"
        class="text-sm font-medium">{{ notification.title }}</h3>
      <p [ngClass]="{'line-clamp-2': !showFullDetails}" class="mt-1 text-sm text-gray-500">{{ notification.message }}
      </p>
      <p class="mt-2 text-xs text-gray-400">{{ notification.createdAt | timeAgo }}</p>
    </div>
  </div>
  <div class="flex-shrink-0 ml-4">
    <button (click)="onDelete($event)"
      class="p-1 text-red-500 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 flex items-center">
      <fa-icon [icon]="trash" class="h-5 w-5 mr-1"></fa-icon>
      <span class="text-sm">{{'COMPONENTS.PAYMENTS.DELETE' | translate}}</span>
    </button>
    <div *ngIf="notification.typeName">
      <ng-container *ngFor="let action of notificationActions[notification.typeName]">
        <ng-container *ngIf="action.isLink; else buttonTemplate">
          <a [routerLink]="action.handler(notification)" (click)="$event.stopPropagation()" [ngClass]="action.color"
            class="p-1 text-blue-500 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center mt-2">
            <fa-icon class="h-5 w-5 mr-1" [icon]="action.icon"></fa-icon>
            <span class="text-sm">{{ action.label | translate }}</span>
          </a>
        </ng-container>
        <ng-template #buttonTemplate>
          <button (click)="action.handler(notification); $event.stopPropagation();" [ngClass]="action.color"
            class="p-1 text-blue-500 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center mt-2">
            <fa-icon class="h-5 w-5 mr-1" [icon]="action.icon"></fa-icon>
            <span class="text-sm">{{ action.label | translate }}</span>
          </button>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>