<div *ngIf="form" [formGroup]="form" class="relative flex flex-col justify-start items-start w-full col-span-2 pb-6">
  <div class="relative w-full box-border border px-1" [ngClass]="{
             'border-transparent': selfControl?.valid || (selfControl?.invalid && !formMarkAsTouched && !(selfControl?.dirty || selfControl?.touched)),
             'border-red-500': (selfControl?.invalid && formMarkAsTouched) || (selfControl?.invalid && (selfControl?.dirty || selfControl?.touched))
         }">
    <label *ngIf="meta.label"
      class="relative font-Lato-Regular font-semibold text-gray-#5d5d5d text-sm leading-21px mb-1" for="">
      {{meta.label}}
    </label>
    <!--        (change)="getValue($event)"-->
    <mat-radio-group [formControlName]="meta.fieldId" color="primary" class="relative flex justify-between w-48 mt-2"
      aria-label="Select an option">
      <mat-radio-button value="Yes">{{ 'COMPONENTS.COMMON.YES' | translate }}</mat-radio-button>
      <mat-radio-button value="No">{{ 'COMPONENTS.COMMON.NO' | translate }}</mat-radio-button>
    </mat-radio-group>
    <!--        <mat-radio-group [formControlName]="meta.fieldId" -->
    <!--                           class="relative flex justify-between w-48 mt-2"-->
    <!--                           aria-label="Select an option">-->
    <!--              <p class="relative flex justify-start items-center text-gray-#252525">-->
    <!--                  <input class="focus:ring-offset-0 relative border-gray-#9D9D9D focus:outline-none active:outline-none focus:ring-0 active:ring-0 mr-3"-->
    <!--                         type="radio" value="Yes"-->
    <!--                         [name]="meta.fieldId">-->
    <!--                  {{ 'YES' | translate }}-->
    <!--              </p>-->
    <!--              <p class="relative flex justify-start items-center text-gray-#252525">-->
    <!--                  <input class="focus:ring-offset-0 relative border-gray-#9D9D9D focus:outline-none active:outline-none focus:ring-0 active:ring-0 mr-3"-->
    <!--                         type="radio" value="No"-->
    <!--                         [name]="meta.fieldId">-->
    <!--                {{ 'NO' | translate }}-->
    <!--              </p>-->
    <!--          </mat-radio-group>-->
  </div>

  <div
    *ngIf="selfControl?.invalid && formMarkAsTouched || (selfControl?.invalid && !formMarkAsTouched && (selfControl?.dirty || selfControl?.touched))"
    class="absolute bottom-0.5 text-sm text-red-500">
    <p *ngIf="selfControl?.hasError('required')">{{ 'COMPONENTS.COMMON.SELECTION_REQUIRED' | translate }}</p>
  </div>

  <!--    valid: {{selfControl?.valid}}-->
  <!--    formMarkAsTouched: {{formMarkAsTouched}}-->
</div>