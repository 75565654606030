<div class="flex us:flex-col sm:flex-row xl:gap-6 us:gap-4"><!---->
    <div class="relative flex flex-row w-auto items-center justify-end gap-2" [formGroup]="filterForm">
        <label class="relative text-gray-#727272 font-Lato-Regular font-semibold text-nowrap
                  us:text-sm
                  lg-landscape:text-sm">{{'COMPONENTS.COMMON.ORDER_BY' | translate}}:</label>
        <mat-select class="relative text-gray-#727272 font-Lato-Regular font-semibold border-2 rounded-3px border-solid border-gray-#ABABAB bg-gray-#f2f2f2 w-120px
                                  us:text-sm
                                  lg-landscape:text-sm
                                  px-3 py-2 active:ring-0 focus:ring-0
                                  ring-0 h-41px text-base col-span-1" formControlName="order">
            <mat-option *ngFor="let order of orderByArray" [value]="order">{{order.text | translate}}
            </mat-option>
        </mat-select>
    </div>
    <div class="relative flex flex-row w-auto items-center justify-end gap-2
                us:hidden xl:flex">
        <label class="relative text-gray-#727272 font-Lato-Regular font-semibold text-nowrap
                           us:text-sm
                           lg-landscape:text-sm">{{'COMPONENTS.STORES.SEE_HOW' | translate}}:</label>
        <mat-select (change)="viewMode($event)" [(value)]="selected_view" (selectionChange)="viewMode($event)" class="relative text-gray-#727272 font-Lato-Regular font-semibold border-2 rounded-3px border-solid border-gray-#ABABAB w-120px
                                      us:text-sm
                                      lg-landscape:text-sm
                                      px-3 py-2 bg-gray-#f2f2f2  active:ring-0 focus:ring-0
                                      ring-0 h-41px text-base col-span-1" [disabled]="!getValidSelectedZone">
            <mat-option *ngFor="let view of viewModeArray" [value]="view.filter">{{view.text | translate}}
            </mat-option>
        </mat-select>
    </div>
</div>