<section class="relative bg-white
us:w-full us:h-screen us:overflow-y-auto
md:w-760px md:h-462px flex flex-col md:flex-row gap-12 md:gap-0">
    <div class="relative w-full md:w-2/4 flex justify-center items-center px-18px pt-16 md:px-9 md:py-16">
        <img class="relative w-300px md:w-603px h-300px md:h-603"
            src="../../../../../assets/new-cart/pending-order-image.jpg" alt="">
    </div>
    <div class="relative py-8 hidden md:flex">
        <mat-divider [vertical]="true" class="hidden md:flex"></mat-divider>
    </div>
    <!--  <div class="relative flex justify-start">-->
    <!--  </div>-->

    <div class="relative flex flex-col w-full md:w-2/4 gap-14 md:gap-5 px-18px md:px-9 py-16">
        <div class="relative w-full flex flex-col gap-3 md:gap-0">
            <p class="relative w-full font-Lato-Regular font-semibold md:font-bold text-center text-19px leading-21px">
                {{'PENDING_ORDER_CONFIRMATION_TITLE' | translate}}
            </p>
            <p
                class="relative w-full font-Lato-Regular font-semibold md:font-bold text-center text-15px leading-21px text-gray-#727272">
                {{'PENDING_ORDER_CONFIRMATION_TITLE_QUESTION' | translate}}
            </p>
        </div>

        <div class="relative w-full flex flex-col
        justify-center items-center gap-4">
            <button class="relative bg-yellow-#FF6D03 flex justify-center items-center px-2
        rounded-full w-257px h-50px" type="button" [ngClass]="{'opacity-50 cursor-not-allowed': isSaving,
             'cursor-pointer': !isSaving}" [disabled]="isSaving" (click)="proceedToPayment()">
                <p class="relative font-Lato-Bold
            us:text-15px us:leading-4
         md:text-19px md:leading-21px text-white">{{'PROCEED_TO_PAYMENT' | translate}}</p>
            </button>

            <button class="relative bg-white border border-yellow-#FF6D03 flex justify-center items-center px-2
        rounded-full w-257px h-50px" type="button" [ngClass]="{'opacity-50 cursor-not-allowed': isSaving,
             'cursor-pointer': !isSaving}" [disabled]="isSaving" (click)="convertToCart()">
                <p class="relative font-Lato-Bold
            us:text-15px us:leading-4
         md:text-19px md:leading-21px text-yellow-#FF6D03">{{'KEEP_BUYING' | translate}}</p>
            </button>

            <button class="relative bg-white border border-yellow-#FF6D03 flex justify-center items-center px-2
        rounded-full w-257px h-50px" type="button" [ngClass]="{'opacity-50 cursor-not-allowed': isSaving,
             'cursor-pointer': !isSaving}" [disabled]="isSaving" (click)="cancelOrder()">
                <p class="relative font-Lato-Bold
         us:text-15px us:leading-4
         md:text-19px md:leading-21px text-yellow-#FF6D03">{{'CANCEL_ORDER' | translate}}</p>
            </button>

            <p class="relative font-Lato-Bold text-blue-#18396C underline cursor-pointer md:text-14px md:leading-16px"
                (click)="navigateToOrdersList()">{{'COMPONENTS.COMMON.VIEW_ORDER_DETAILS' | translate}}</p>
        </div>
    </div>


</section>