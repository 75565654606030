<section class="relative bg-gray-#f2f2f2 rounded-md
us:w-full us:h-auto
us-landscape:w-448px us-landscape:max-h-81vh us-landscape:overflow-y-auto
md:w-448px
md-landscape:w-448px
lg-landscape:w-448px">
  <header class="relative w-full h-14 bg-white flex justify-start items-center px-4">
    <p class="relative font-Lato-Bold text-base text-gray-#252525">
      {{ 'COMPONENTS.SHOP_LISTS.ADD_TO_SHOP_LIST' | translate }}
    </p>
  </header>
  <form class="relative w-full flex flex-col p-6 gap-5" [formGroup]="form" (ngSubmit)="submit()">
    <div class="relative w-full grid grid-cols-1 items-center justify-center">
      <div *ngIf="!isLoadingShoppingList && shoppingLists.length"
        class="relative flex flex-1 justify-start items-center pb-6">
        <mat-select placeholder="{{'COMPONENTS.SHOP_LISTS.SHOPPING_LIST' | translate}}*" formControlName="shopping_list"
          class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
          <mat-option [value]="null">{{ 'SELECT' | translate }}</mat-option>
          <mat-option [value]="shoppingList.id" *ngFor="let shoppingList of shoppingLists">{{ shoppingList.name
            }}</mat-option>
        </mat-select>
        <div *ngIf="shopping_list?.invalid && (shopping_list?.dirty || shopping_list?.touched)"
          class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="shopping_list?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}</p>
        </div>
      </div>

      <div *ngIf="isLoadingShoppingList" class="relative flex flex-1 justify-start items-center pb-6">
        <div
          class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
          {{ 'COMPONENTS.SHOP_LISTS.SHOPPING_LIST' | translate }}*
        </div>
        <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
      </div>

      <p *ngIf="!isLoadingShoppingList && !shoppingLists.length">{{'COMPONENTS.SHOP_LISTS.NO_SHOPPING_LIST_FOUND' |
        translate}}</p>
    </div>

  </form>
  <div
    class="sticky bottom-0 left-0 right-0 w-full flex gap-5 items-center justify-center py-3 us:px-4 lg-landscape:px-0">
    <button class="relative bg-yellow-#FFC35D font-Lato-Bold text-15px text-gray-#252525 py-2 rounded-md
      hover:text-white transition-all ease-in-out duration-200 w-56" [disabled]="isSaving || form.invalid" [ngClass]="{
        'cursor-not-allowed opacity-50 hover:text-gray-#252525': isSaving || form.invalid
      }" (click)="submit()" [ngSwitch]="true">
      <span *ngIf="!isLoadingShoppingList && shoppingLists.length || isLoadingShoppingList">{{
        'COMPONENTS.SHOP_LISTS.ADD_TO_SHOP_LIST' |
        translate | titlecase }}</span>
      <span *ngIf="!isLoadingShoppingList && !shoppingLists.length">{{ 'COMPONENTS.SHOP_LISTS.CREATE_LIST' | translate |
        titlecase }}</span>
    </button>
    <button class="relative font-Lato-Regular leading-16px font-normal text-gray-#252525 items-center justify-center"
      mat-dialog-close="close">{{ 'COMPONENTS.COMMON.CANCEL' | translate }}
    </button>
  </div>
</section>