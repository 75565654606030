import {Component, Input, Output, EventEmitter} from '@angular/core';
import {InternalNotificationDto} from "../internal-notifications/internalNotificationDto.interface";
import {notificationActions} from "../internal-notifications/notification-actions";
import {faBell, faBookReader, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {InternalNotificationService} from "../../services/internal-notification.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent {
  @Input() notification!: InternalNotificationDto;
  @Input() showFullDetails: boolean = false;
  @Input() index: number = -1;
  @Output() delete = new EventEmitter<string>();
  @Output() toggleRead = new EventEmitter<string>();

  constructor(private notificationService: InternalNotificationService, private router: Router) {
  }

  onToggleRead(): void {
    this.notification.readAt = new Date();
    this.notificationService.markAsRead([this.notification.id]).subscribe(() => {
      this.toggleRead.emit(this.notification.id);
    });
  }

  onPrimaryAction() {
    this.onToggleRead();
    const actions = notificationActions[this.notification.typeName];
    if (actions && actions.length > 0) {
      if (actions[0].isLink) {
        this.router.navigate([actions[0].handler(this.notification)]).then();
      } else {
        actions[0].handler(this.notification);
      }
    }
  }

  onDelete(event: Event): void {
    event.stopPropagation();
    this.notificationService.deleteNotifications([this.notification.id]).subscribe(() => {
      this.delete.emit(this.notification.id);
    });
  }

  readonly notificationActions = notificationActions;
  readonly trash = faTrashCan;
  readonly faBell = faBell;
  readonly faBookReader = faBookReader;
}
