<section class="relative bg-gray-#f2f2f2 rounded-md
us:w-full us:h-auto
us-landscape:w-448px us-landscape:max-h-81vh us-landscape:overflow-y-auto
md:w-448px
md-landscape:w-448px
lg-landscape:w-448px">
  <header class="relative w-full h-14 bg-white flex justify-start items-center px-4">
    <div [ngSwitch]="true">
      <p class="relative font-Lato-Bold text-base text-gray-#252525"
        *ngSwitchCase="!isModeEdit && !isChangeLocation && !isConvertFromCart && !isConvertFromOrder">
        {{ 'COMPONENTS.SHOP_LISTS.CREATE_LIST' | translate }}
      </p>
      <p class="relative font-Lato-Bold text-base text-gray-#252525" *ngSwitchCase="isModeEdit">
        {{ 'COMPONENTS.SHOP_LISTS.MODIFY_LIST' | translate }}
      </p>
      <p class="relative font-Lato-Bold text-base text-gray-#252525" *ngSwitchCase="isChangeLocation">
        {{ 'COMPONENTS.SHOP_LISTS.SHOP_LIST_CHANGE_LOCATION' | translate }}
      </p>
      <p class="relative font-Lato-Bold text-base text-gray-#252525"
        *ngSwitchCase="isConvertFromCart || isConvertFromOrder">
        {{ 'COMPONENTS.SHOP_LISTS.CONVERT_CART_TO_SHOP_LIST' | translate }}
      </p>
    </div>
  </header>
  <form class="relative w-full flex flex-col p-6 gap-5" [formGroup]="form" (ngSubmit)="submit()">
    <div class="relative w-full grid grid-cols-1">
      <div class="relative flex flex-1 justify-start items-center pb-6">
        <input placeholder="{{'COMPONENTS.SHOP_LISTS.LIST_NAME' | translate}}*" type="text" formControlName="name"
          [ngClass]="{
                          'border-none': name?.valid || (name?.invalid && !(name?.dirty || name?.touched)),
                          'border-red-500': name?.invalid || (name?.invalid && (name?.dirty || name?.touched))
                      }"
          class="relative w-full bg-white text-gray-#727272 placeholder-gray-#727272 placeholder-opacity-50 font-Lato-Regular text-base h-47px ring-0 active:ring-0 hover:ring-0 focus:ring-0 border-none">
        <div *ngIf="name?.invalid && (name?.dirty || name?.touched)" class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="name?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}</p>
        </div>
      </div>
      <div *ngIf="!isLoadingContacts" class="relative flex flex-1 justify-start items-center pb-6">
        <mat-select
          placeholder="{{'COMPONENTS.CART.CONTACT' | translate}} ({{'COMPONENTS.SHOP_LISTS.OPTIONAL' | translate}})"
          formControlName="contact" *ngIf="!isConvertFromOrder" class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
          <mat-option [value]="null">{{ 'SELECT' | translate }}</mat-option>
          <mat-option [value]="contact" *ngFor="let contact of addresses">{{ contact.displayFullName }}</mat-option>
        </mat-select>
        <mat-select placeholder="{{'COMPONENTS.CART.CONTACT' | translate}}*" formControlName="contact"
          *ngIf="isConvertFromOrder" class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
          <mat-option [value]="null">{{ 'SELECT' | translate }}</mat-option>
          <mat-option [value]="contact" *ngFor="let contact of addresses">{{ contact.displayFullName }}</mat-option>
        </mat-select>
        <div *ngIf="contact?.invalid && (contact?.dirty || contact?.touched)"
          class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="contact?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}</p>
        </div>
      </div>

      <div *ngIf="isLoadingContacts" class="relative flex flex-1 justify-start items-center pb-6">
        <div *ngIf="!isConvertFromOrder"
          class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
          {{ 'COMPONENTS.CART.CONTACT' | translate }} ({{ 'COMPONENTS.SHOP_LISTS.OPTIONAL' | translate }})
        </div>
        <div *ngIf="isConvertFromOrder"
          class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
          {{ 'COMPONENTS.CART.CONTACT' | translate }}*
        </div>
        <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
      </div>

      <div *ngIf="!isConvertFromOrder">
        <!--    Country & State    -->

        <div class="us:grid md:flex gap-2
        us:grid-cols-1
        us-landscape:grid-cols-2
        md:grid-cols-2
        md-landscape:grid-cols-2">
          <div *ngIf="!isLoadingCountries" class="relative flex flex-1 justify-start items-center pb-6">
            <mat-select placeholder="{{ 'COMPONENTS.COMMON.COUNTRY' | translate }}*" formControlName="country_iso"
              required (selectionChange)="changeCountry($event)" [ngClass]="{
                          'border-none': country_iso?.valid || (country_iso?.invalid && !(country_iso?.dirty || country_iso?.touched)),
                          'border-red-500': country_iso?.invalid || (country_iso?.invalid && (country_iso?.dirty || country_iso?.touched))
                      }" class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
              <mat-option *ngFor="let country of countries" [value]="country.iso2">{{ country.name }}
              </mat-option>
            </mat-select>
            <div *ngIf="country_iso?.invalid && (country_iso?.dirty || country_iso?.touched)"
              class="absolute bottom-0.5 text-sm text-red-500">
              <p *ngIf="country_iso?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}</p>
            </div>
          </div>

          <div *ngIf="isLoadingCountries" class="relative flex flex-1 justify-start items-center pb-6">
            <div
              class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
              {{ 'COMPONENTS.COMMON.COUNTRY' | translate }}*
            </div>
            <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
          </div>

          <div *ngIf="!isLoadingStates" class="relative flex flex-1 justify-start items-center pb-6">
            <mat-select placeholder="{{ 'COMPONENTS.COMMON.STATE_OR_PROVINCE' | translate }}*"
              formControlName="state_name" required [ngClass]="{
                          'border-none': state_name?.valid || (state_name?.invalid && !(state_name?.dirty || state_name?.touched)),
                          'border-red-500': state_name?.invalid || (state_name?.invalid && (state_name?.dirty || state_name?.touched))
                      }" (selectionChange)="changeState($event)" class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
              <mat-option *ngFor="let state of states" [value]="state.name">{{ state.name }}</mat-option>
            </mat-select>
            <div *ngIf="state_name?.invalid && (state_name?.dirty || state_name?.touched)"
              class="absolute bottom-0.5 text-sm text-red-500">
              <p *ngIf="state_name?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}</p>
            </div>
          </div>

          <div *ngIf="isLoadingStates" class="relative flex flex-1 justify-start items-center pb-6">
            <div
              class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
              {{ 'COMPONENTS.COMMON.STATE_OR_PROVINCE' | translate }}*
            </div>
            <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
          </div>
        </div>

        <!--    Country & State ---- END    -->

        <!--    City    -->
        <div class="grid grid-cols-1 w-full" *ngIf="showSelectInput || isAutoComplete">
          <div *ngIf="!isLoadingCities && showSelectInput" class="relative flex flex-1 justify-start items-center pb-6">
            <mat-select placeholder="{{ 'COMPONENTS.COMMON.CITY' | translate }}*" formControlName="city" required
              [ngClass]="{
                          'border-none': city?.valid || (city?.invalid && !(city?.dirty || city?.touched)),
                          'border-red-500': city?.invalid || (city?.invalid && (city?.dirty || city?.touched))
                      }" class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
              <mat-option *ngFor="let city of cities" [value]="city.name">{{ city.name }}</mat-option>
            </mat-select>
            <div *ngIf="city?.invalid && (city?.dirty || city?.touched)"
              class="absolute bottom-0.5 text-sm text-red-500">
              <p *ngIf="city?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}</p>
            </div>
          </div>

          <div *ngIf="!isLoadingCities && !showSelectInput" class="flex flex-1 flex-col relative pb-6">
            <input placeholder="{{ 'COMPONENTS.COMMON.CITY' | translate }}*" formControlName="city" id="city" [ngClass]="{
                      'border-none': city?.valid || (city?.invalid && !(city?.dirty || city?.touched)),
                      'border-red-500': city?.invalid || (city?.invalid && (city?.dirty || city?.touched))
                  }"
              class="border relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base"
              type="text">
            <div *ngIf="city?.invalid && (city?.dirty || city?.touched)"
              class="absolute bottom-0.5 text-sm text-red-500">
              <p *ngIf="city?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}</p>
              <p *ngIf="city?.hasError('pattern')">{{ 'COMPONENTS.COMMON.WRONG_FORMAT' | translate }}</p>
            </div>
          </div>

          <div *ngIf="isLoadingCities" class="relative flex flex-1 justify-start items-center pb-6">
            <div
              class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                          focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
              {{ 'COMPONENTS.COMMON.CITY' | translate }}*
            </div>
            <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
          </div>
        </div>
        <!--    City ----END    -->
      </div>


      <textarea
        placeholder="{{ 'COMPONENTS.COMMON.DESCRIPTION' | translate }} ({{ 'COMPONENTS.SHOP_LISTS.OPTIONAL' | translate }})"
        rows="3" formControlName="description"
        class="relative w-full bg-white text-gray-#727272 placeholder-gray-#727272 placeholder-opacity-50 font-Lato-Regular text-base min-h-144px  ring-0  active:ring-0 hover:ring-0 focus:ring-0 border-none"></textarea>
    </div>

  </form>
  <div class="sticky bottom-0 left-0 right-0 w-full flex gap-5 items-center justify-center py-3">
    <button class="relative bg-yellow-#FFC35D font-Lato-Bold text-15px text-gray-#252525 py-2 rounded-md
      hover:text-white transition-all ease-in-out duration-200 flex gap-2 items-center justify-center"
      [disabled]="isSaving || form.invalid" [ngClass]="{
        'cursor-not-allowed opacity-50 hover:text-gray-#252525': isSaving || form.invalid,
        'w-40': !isConvertFromCart && !isConvertFromOrder,
        'w-56': isConvertFromCart || isConvertFromOrder,
        'w-64': isSaving
      }" (click)="submit()" [ngSwitch]="true">
      <span *ngSwitchCase="!isModeEdit && !isChangeLocation && !isConvertFromCart && !isConvertFromOrder">{{
        'COMPONENTS.SHOP_LISTS.CREATE_LIST' | translate | titlecase }}</span>
      <span *ngSwitchCase="isModeEdit">{{ 'COMPONENTS.SHOP_LISTS.MODIFY_LIST' | translate | titlecase }}</span>
      <span *ngSwitchCase="isChangeLocation">{{ 'COMPONENTS.SHOP_LISTS.SHOP_LIST_CHANGE_LOCATION' | translate |
        titlecase }}</span>
      <span *ngSwitchCase="isConvertFromCart || isConvertFromOrder">{{ 'COMPONENTS.SHOP_LISTS.CONVERT_CART_TO_SHOP_LIST'
        | translate |
        titlecase }}</span>
      <fa-icon *ngIf="isSaving"
        class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-4 h-4 text-xl text-gray-#252525"
        [icon]="faSpinner">
      </fa-icon>
    </button>
    <button class="relative font-Lato-Regular leading-16px font-normal text-gray-#252525 items-center justify-center"
      mat-dialog-close="close">{{ 'COMPONENTS.COMMON.CANCEL' | translate }}
    </button>
  </div>
</section>