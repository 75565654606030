<section class="relative w-96 max-w-sm min-h-screen max-h-screen px-4 py-4 overflow-y-auto">
    <p class="relative font-Lato-Regular flex justify-between font-semibold text-gray-#5d5d5d w-full
                  text-19px">
        <span
            [ngClass]="mercado_unico_products.length > 0 || other_stores_products.length > 0 ? 'opacity-1' : 'opacity-0'">{{cart_text
            |
            translate}}</span>
        <fa-icon (click)="closePreview()" class="relative flex justify-center items-center rounded-full text-gray-#9D9D9D cursor-pointer
                 w-4 h-4" [icon]="close_icon">
        </fa-icon>
    </p>

    <div *ngIf="mercado_unico_products.length > 0 || other_stores_products.length > 0"
        [ngClass]="mercado_unico_products.length == 0 || other_stores_products.length == 0 ? 'grid-cols-1' : 'grid-cols-2'"
        class="relative w-full grid mt-5">
        <div *ngIf="mercado_unico_products.length > 0" (click)="selectStore(1)"
            class="relative cursor-pointer border-b-2 border-gray-#9D9D9D box-border flex flex-col justify-center items-center px-1 py-4">
            <p class="relative font-Lato-Regular font-semibold text-yellow-#ff4103 text-15px">Mercado
                Único
            </p>
            <p class="relative font-Lato-Regular text-xs text-gray-#5d5d5d">{{mercado_unico_products.length}}</p>

            <span *ngIf="default" class="absolute bottom-0 h-1 w-full transition-all ease-in-out bg-yellow-#ff4103">
            </span>
        </div>
        <div *ngIf="other_stores_products.length > 0" (click)="selectStore(2)"
            class="relative cursor-pointer border-b-2 border-gray-#9D9D9D box-border flex flex-col justify-center items-center px-1 py-4">
            <p class="relative font-Lato-Regular font-semibold text-green-#0D842D text-15px">
                {{others_text | translate}}
            </p>
            <p class="relative font-Lato-Regular text-xs text-gray-#5d5d5d">{{other_stores_products.length}}</p>

            <span *ngIf="!default" class="absolute bottom-0 h-1 w-full transition-all ease-in-out bg-green-#0D842D">
            </span>
        </div>
    </div>

    <div *ngIf="mercado_unico_products.length > 0 || other_stores_products.length > 0"
        class="relative w-full flex gap-x-2 justify-between mt-6">
        <div class="relative">
            <p class="relative uppercase text-gray-#9D9D9D font-Lato-Bold text-15px">sub total</p>
            <p *ngIf="default" class="relative font-Lato-Regular font-semibold text-yellow-#ff4103 text-sm">
                <!-- Mercado Único -->
            </p>
            <p *ngIf="!default" class="relative font-Lato-Regular font-semibold text-green-#0D842D text-sm">
                <!-- {{others_text | translate}} -->
            </p>
            <p class="relative w-full font-Lato-Regular text-gray-#212121 font-bold text-xl">{{total | currency: 'USD'}}
            </p>
        </div>
        <div class="relative flex justify-center items-center">
            <a (click)="closePreview()" [routerLink]="cartRoute" class="relative font-Lato-Regular font-medium bg-yellow-#FF6D03 rounded-full flex justify-center items-center text-white
            h-41px w-auto text-19px ml-3 px-4 border-none outline-none">
                <fa-icon class="relative flex justify-center items-center rounded-full text-white
                us:w-9 us:h-9 us:mr-1
                xl:w-9 xl:h-9 xl:mr-1" [icon]="cart_icon">
                </fa-icon>
                {{go_to_cart_text | translate}}
            </a>
        </div>
    </div>

    <div *ngIf="mercado_unico_products.length > 0 || other_stores_products.length > 0" class="relative w-full">
        <div class="sep relative w-full h-1px bg-gray-#D6D6D6 mt-6px"></div>

        <div class="w-full flex flex-col lg:flex-row justify-between items-start mt-5"
            *ngIf="mercado_unico_products.length > 0 && default == true">
            <div class="relative w-full">
                <!-- <app-cart-card *ngFor="let item of mercado_unico_products" [currency]="cart.currency" [id]="item.id"
                    [slug]="item && item.slug ? item.slug : ''" [itemCount]="item.count" [productName]="item.name"
                    [productPrice]="item.totalDisplayPrice" [promotionCode]="''" [variant]="item.optionsText || ''"
                    [imageUrl]="item.image || ''"
                    [canEmptyCart]="(other_stores_products.length + mercado_unico_products.lengh) == 1"
                    [data_extra_types]="item.extraData!" (updateCart)="updateCart()"
                    [isDeletingProduct]="isDeletingProduct">
                </app-cart-card> -->
                <app-new-cart-card *ngFor="let item of mercado_unico_products" [currency]="cart.currency" [id]="item.id"
                    [slug]="item && item.slug ? item.slug : ''" [itemCount]="item.count" [productName]="item.name"
                    [productPrice]="+item.totalDisplayPrice" [promotionCode]="''" [variant]="item.optionText"
                    [imageUrl]="item.image || ''" [canEmptyCart]="stores.length === 1 && products.length === 1"
                    [data_extra_types]="item.extraData!" (updateCart)="updateCart($event)"
                    [isDeletingProduct]="isDeletingProduct" [message]="item.message">
                </app-new-cart-card>
            </div>
        </div>

        <div class="w-full flex flex-col lg:flex-row justify-between items-start mt-5"
            *ngIf="other_stores_products.length > 0 && default == false">
            <div class="relative w-full">
                <!-- <app-cart-card *ngFor="let item of other_stores_products" [currency]="cart.currency" [id]="item.id"
                    [slug]="item && item.slug ? item.slug : ''" [itemCount]="item.count" [productName]="item.name"
                    [productPrice]="item.totalDisplayPrice" [promotionCode]="''" [variant]="item.optionsText || ''"
                    [imageUrl]="item.image || ''"
                    [canEmptyCart]="(other_stores_products.length + mercado_unico_products.lengh) == 1"
                    [data_extra_types]="item.extraData!" (updateCart)="updateCart()"
                    [isDeletingProduct]="isDeletingProduct">
                </app-cart-card> -->
                <app-new-cart-card *ngFor="let item of other_stores_products" [currency]="cart.currency" [id]="item.id"
                    [slug]="item && item.slug ? item.slug : ''" [itemCount]="item.count" [productName]="item.name"
                    [productPrice]="+item.totalDisplayPrice" [promotionCode]="''" [variant]="item.optionText"
                    [imageUrl]="item.image || ''" [canEmptyCart]="stores.length === 1 && products.length === 1"
                    [data_extra_types]="item.extraData!" (updateCart)="updateCart($event)"
                    [isDeletingProduct]="isDeletingProduct" [message]="item.message">
                </app-new-cart-card>
            </div>
        </div>
    </div>

    <div *ngIf="isShowSkeletonCart">
        <div class="relative bg-gray-300 rounded w-3/4 h-3"></div>
        <div class="relative bg-gray-300 rounded w-3/4 h-3 mt-2"></div>
        <div class="relative bg-gray-300 rounded w-3/4 h-5 mt-2"></div>
        <div class="relative w-full animate-pulse" *ngFor="let skeleton of skeletons">
            <div class="w-full flex flex-col lg:flex-row justify-between items-start pb-5 gap-4 xl:gap-12">
                <div class="relative w-auto lg:w-full">
                    <div class="flex flex-col flex-1">
                        <div class="mt-5 bg-gray-#EEEEEE flex flex-col flex-1">
                            <div class="flex flex-col m-5">
                                <div class="flex flex-row flex-1 gap-4">
                                    <div class="flex flex-row flex-1">
                                        <div class="relative w-24 h-24 bg-gray-#E0E0E0 mb-4">
                                            <div
                                                class="absolute z-10 rounded-full flex justify-center items-center bg-gray-#D6D6D6 -right-1 -top-1 w-5 h-5">
                                                <p class="relative font-Lato-Regular font-semibold text-white text-sm">1
                                                </p>
                                            </div>
                                        </div>
                                        <div class="flex flex-col flex-1 gap-2 ml-4 mt-1">
                                            <div class="relative w-auto h-4 bg-gray-300 rounded"></div>
                                            <div class="relative w-100px h-3 bg-gray-300 rounded"></div>
                                            <div class="relative w-16 h-5 bg-gray-300 rounded"></div>
                                            <div class="flex flex-row gap-5 items-center">
                                                <div
                                                    class="relative flex justify-center items-center border border-gray-300 overflow-hidden box-border rounded-l-20px rounded-r-20px h-27px">
                                                    <div
                                                        class="relative h-full bg-gray-300 flex justify-center items-center text-gray-#9D9D9D font-semibold text-lg w-7">
                                                        -</div>
                                                    <p
                                                        class="relative h-full flex justify-center items-center font-Lato-Regular text-gray-#9D9D9D w-31px text-sm">
                                                        1</p>
                                                    <div
                                                        class="relative h-full bg-gray-300 flex justify-center items-center text-gray-#9D9D9D text-lg w-7">
                                                        +</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button *ngIf="mercado_unico_products.length > 0 || other_stores_products.length > 0" class="relative font-bold flex justify-center items-center text-yellow-#FF6D03 bg-white border-2 border-yellow-#FF6D03 rounded-full hover:bg-orange-100
    font-Lato-Regular text-sm w-full h-10 mt-7" (click)="closePreview()">
        <img class="relative w-7 fill-yellow-#FF6D03" src="../../../../../assets/imgs/icon-arrowleft.svg" alt="">
        {{'COMPONENTS.PAYMENTS.KEEP_BUYING' | translate}}
    </button>

    <section *ngIf="mercado_unico_products.length == 0 && other_stores_products.length == 0 && !isShowSkeletonCart"
        class="relative w-full h-auto flex flex-wrap justify-center items-start mt-5">

        <div class="col relative h-full flex flex-col justify-start items-start w-full">
            <p class="relative font-Lato-Regular font-semibold text-19px text-gray-#252525 leading-21px">
                {{'COMPONENTS.PAYMENTS.YOUR_CART_IS_EMPTY' | translate}}
            </p>
            <p class="realtive font-Lato-Regular font-semibold text-base mt-4">
                {{'COMPONENTS.PAYMENTS.ADD_SOME_PRODUCTS' | translate}}
            </p>
            <div class="realtive flex flex-col font-Lato-Regular text-base items-center md:items-start">
                {{'COMPONENTS.COMMON.TRY_USING_SEARCH' | translate}} {{'COMPONENTS.COMMON.OR_GO_TO' | translate}} <a
                    (click)="closePreview()" class="relative underline text-blue-#18396C outline-none"
                    [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'home']">{{'COMPONENTS.COMMON.HOME_PAGE'
                    |
                    translate}}.</a>
            </div>

            <button (click)="closePreview()"
                [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'mercado-unico']" class="relative bg-white border-yellow-#FF6D03 px-4 border-2 px-35px font-Lato-Regular font-semibold text-yellow-#FF6D03 flex justify-center items-center
                us:w-auto us:h-38px us:rounded-3xl us:mt-6 us:text-base
                lg:w-auto lg:h-38px lg:rounded-3xl lg:mt-6 lg:text-base">
                {{'COMPONENTS.NAVIGATION.GO_BACK_MU' | translate}}
            </button>
        </div>
    </section>

</section>