import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { CartService } from "../../../../shared/services/cart.service";
import { takeUntil } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { SelectedZone, User } from "../../../../shared/models";
import { ZonesService } from "../../../../shared/services/zones.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../../../shared/services/auth.service";
import { ErrorsEnum } from "../../../../shared/enum/errors.enum";
import { NewCartService } from "../../../../shared/services/newcart.service";
import { PendingOrdersConfirmationComponent } from "../pending-orders-confirmation/pending-orders-confirmation.component";
import { NewCheckoutEnum } from "../../../../shared/enum/checkout.enum";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { LoginToAddCartDialogComponent } from "../login-to-add-cart-dialog/login-to-add-cart-dialog.component";
import * as moment from "moment/moment";
import { ExtraData } from "../../../../shared/models/new-cart.model";
import { FRONTEND_FEATURE, FrontendFeatureService } from "../../../../shared/services/frontend-feature.service";
import { StorageService } from "../../../../shared/services/storage.service";
import { NotificationService } from "../../../../shared/services/notification.service";
import { ApiService } from "../../../../shared/services/api.service";
import { environment } from "../../../../../environments/environment";
import { CommonKeysEnum } from "../../../../shared/enum/common.keys.enum";
import { PhoneRechargeSelectionComponent } from "../phone-recharge-selection/phone-recharge-selection.component";
import { AgeModalComponent } from "../age-modal/age-modal.component";
import { CookieService } from "ngx-cookie-service";
import { ShoppingListQueryParams } from "../../../../shared/models/shopping-list-query-params";


@Component({
    selector: 'app-thin-product-card',
    templateUrl: './thin-product-card.component.html',
    styleUrls: ['./thin-product-card.component.scss']
})
export class ThinProductCardComponent implements OnInit {
    @Input() productId: number = 0;
    @Input() imageUrl: string = '../../../../../assets/imgs/product-card/no-product-image.png';
    @Input() name: string = 'Recarga Promo - $31.70 (reciben 1,750 CUP + 5GB + 60Min)';
    @Input() price: number = 0;
    @Input() currency: string = 'USD';
    @Input() isLowStock: boolean = true;
    @Input() quantity: number = 0;
    @Input() discount: number = 0;
    @Input() variantId: any = 0;
    @Input() slug: string = '';
    @Input() data_extra_types: { id: number, value: string }[] = [];
    @Input() age_valid: boolean = false;
    @Input() queryId: string | undefined;
    @Input() queryPosition: number | undefined;



    cart_icon = faShoppingCart;

    destroy$: Subject<boolean> = new Subject<boolean>();

    currentUser: User | undefined;

    spin = false;
    isBlockAddToCard: boolean = false;

    queryParams: ShoppingListQueryParams = {};

    constructor(
        private cartService: CartService,
        private zonesService: ZonesService,
        private translateService: TranslateService,
        private authService: AuthService,
        private newCartService: NewCartService,
        private dialog: MatDialog,
        private router: Router,
        private apiService: ApiService,
        private cookieService: CookieService,
    ) { }

    ngOnInit(): void {
        this.currentUser = this.authService.getCurrentUser();
        // this.queryParams = this.queryId && this.currentUser?.useNewCart ? {queryId: this.queryId, queryPosition: this.queryPosition} : {};
        this.queryParams = this.queryId ? { queryId: this.queryId, queryPosition: this.queryPosition } : {};
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
    }

    increaseQuantity() {

        if (this.getValidSelectedZone) {
            this.quantity += 1;
        }
    }

    decreaseQuantity() {
        if (this.quantity > 1 && this.getValidSelectedZone) this.quantity -= 1;
    }


    addToCart() {
        if (this.authService.isAuthenticated()) {
            if (this.quantity !== 0 && this.getValidSelectedZone) {
                if (this.data_extra_types?.length !== 0) {
                    this.showPhoneNumberModal();
                }
                else if (this.age_valid && !this.cookieService.get('valid_age')) {
                    this.showAgeModal();
                } else {
                    this.generalAddToCart();
                }
            }
        } else {
            this.dialog.open(LoginToAddCartDialogComponent, {
                width: '400px',
                disableClose: true,
                data: {
                    messageUppercase: true,
                    cancelAction: 'COMPONENTS.COMMON.CANCEL',
                    confirmAction: 'COMPONENTS.COMMON.GO_TO_LOGIN',
                    message: "COMPONENTS.COMMON.TO_PERFORM_THIS_ACTION_YOU_MUST_LOG_IN",
                }
            });
        }
    }

    private getVerificationDate(timeAdd: number): Date {
        return moment(Date.now()).add(timeAdd, 'hours').toDate()
    }

    private generalAddToCart(data_extras?: ExtraData) {
        if (!this.isBlockAddToCard) {
            this.spin = true;
            if (this.currentUser?.id) {
                this.addItemToCart(data_extras);
            }
        }
    }

    private addToOldCartLogic(data_extras?: ExtraData, isCartUrl: boolean = false) {
        if (this.cartService.haveCart()) {
            this.addItemToCart(data_extras);
        } else {
            this.cartService.blockAddToCardEvent(true);
            this.cartService.createCart(this.translateService.currentLang, this.getSelectedZone.municipality)
                .then(() => {
                    this.cartService.blockAddToCardEvent(false);
                    this.addItemToCart(data_extras, isCartUrl);
                })
                .catch(() => {
                    this.cartService.blockAddToCardEvent(false);
                    this.spin = false;
                });
        }
    }

    private addItemToCart(data_extras?: ExtraData, isCartUrl: boolean = false) {
        if (this.currentUser?.id) {
            this.newCartService.addItemToCart({
                variantId: String(this.variantId),
                count: this.quantity,
                zoneId: `${this.getSelectedZone.zone}`,
                municipalityId: `${this.getSelectedZone.municipality}`,
                extraData: data_extras ? [data_extras] : [],
                queryId: this.queryId,
                queryPosition: this.queryPosition,
            })
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (response) => {
                        this.spin = false;
                        this.quantity = 1
                        this.newCartService.blockAddToCardEvent(false);
                        if (!response.data && response.responseCode === ErrorsEnum.PENDING_STORE_ORDER) {
                            this.showPendingOrderModal();
                        } else {
                            if (isCartUrl) {
                                this.navigateToNewCart();
                            }
                        }
                    },
                    error: (error) => {
                        this.spin = false;
                        this.newCartService.blockAddToCardEvent(false);
                        if (error?.error?.responseCode === ErrorsEnum.PENDING_STORE_ORDER) {
                            return this.showPendingOrderModal();
                        }
                        return this.apiService.handleErrors(error, true);
                    },
                    complete: () => {
                        this.spin = false;
                        this.newCartService.blockAddToCardEvent(false);
                    }
                });
            // if (this.currentUser?.useNewCart) {
            //     this.newCartService.addItemToCart({
            //         variantId: String(this.variantId),
            //         count: this.quantity,
            //         zoneId: `${this.getSelectedZone.zone}`,
            //         municipalityId: `${this.getSelectedZone.municipality}`,
            //         extraData: data_extras? [data_extras] : [],
            //         queryId: this.queryId,
            //         queryPosition: this.queryPosition,
            //     })
            //         .pipe(takeUntil(this.destroy$))
            //         .subscribe({
            //             next: (response) => {
            //                 this.spin = false;
            //                 this.quantity = 1
            //                 this.newCartService.blockAddToCardEvent(false);
            //                 if (!response.data && response.responseCode === ErrorsEnum.PENDING_STORE_ORDER) {
            //                     this.showPendingOrderModal();
            //                 } else {
            //                     if (isCartUrl) {
            //                         this.navigateToNewCart();
            //                     }
            //                 }
            //             },
            //             error: (error) => {
            //                 this.spin = false;
            //                 this.newCartService.blockAddToCardEvent(false);
            //                 if (error?.error?.responseCode === ErrorsEnum.PENDING_STORE_ORDER) {
            //                     return this.showPendingOrderModal();
            //                 }
            //                 return this.apiService.handleErrors(error, true);
            //             },
            //             complete: () => {
            //                 this.spin = false;
            //                 this.newCartService.blockAddToCardEvent(false);
            //             }
            //         });
            // } else {
            //     this.cartService.addItemToCart({
            //         variant_id: this.variantId,
            //         quantity: this.quantity,
            //         zone_id: this.getSelectedZone.zone || 6,
            //         data_extras
            //     }, this.translateService.currentLang)
            //         .pipe(takeUntil(this.destroy$))
            //         .subscribe({
            //             next: () => {
            //                 this.spin = false;
            //                 this.quantity = 1;
            //                 if (isCartUrl) {
            //                     this.navigateToOldCart();
            //                 }
            //             },
            //             complete: () => {
            //                 this.spin = false;
            //             }
            //         });
            // }
        }
    }

    showPendingOrderModal() {
        let dialoRef = this.dialog.open(PendingOrdersConfirmationComponent, {
            disableClose: false,
            // position: { top: '60px' }
        });
        dialoRef.afterClosed()
            // .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: ((data: any) => {
                    if (data?.success && data?.type) {
                        switch (data.type) {
                            case 'payment':
                                this.newCartService.removeCartValuesInStorage(() => {
                                    localStorage.setItem(NewCheckoutEnum.PAYMENT, JSON.stringify({ data: false }));
                                    this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/cart/checkout/payment`]);
                                });
                                break;
                            case 'cart':
                                this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/cart/new`]);
                                break;
                            case 'home':
                                // this.navigateToHome();
                                break;
                        }
                    }
                }),
                error: (err => {
                    throw new Error(err);
                })
            });
    }

    get getSelectedZone(): SelectedZone {
        return this.zonesService.getZonesValuesStorage();
    }

    get getValidSelectedZone(): boolean {
        const selectedZone = this.getSelectedZone;
        if (selectedZone?.area_selected) {
            if (selectedZone.area_selected === 'CU') {
                return !!selectedZone.zone && !!selectedZone.municipality;
            }
            return !!selectedZone.zone;
        }
        return false;
    }

    showPhoneNumberModal() {
        let dialoRef = this.dialog.open(PhoneRechargeSelectionComponent, {
            disableClose: false,
            position: { top: '60px' }
        });
        dialoRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: ((data: any) => {
                    if (data.modified) {
                        this.generalAddToCart({ id: this.data_extra_types[0].id, value: data.data });
                    }
                }),
                error: (err => {
                    throw new Error(err);
                })
            });
    }

    get getFirstAction() {
        return localStorage.getItem('first_action');
    }

    showAgeModal() {
        if (this.getFirstAction === 'done') {
            let dialoRef = this.dialog.open(AgeModalComponent, {
                disableClose: false,
                // position: { top: '60px' }
            });
            dialoRef.afterClosed()
                // .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: ((data: any) => {
                        if (data) this.cookieService.set('valid_age', 'true', { path: '/' });
                    }),
                    error: (err => {
                        throw new Error(err);
                    })
                });
        }
    }

    private navigateToHome() {
        this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/`]);
    }

    private navigateToNewCart() {
        this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/cart/new`]);
    }

    private navigateToOldCart() {
        this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/cart`]);
    }
}
