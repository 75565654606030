<div class="relative flex flex-col overflow-hidden box-border
            lg-landscape:w-933px lg-landscape:px-0">
    <div class="relative w-auto flex justify-end items-center
        mb-5 mt-5 us:hidden
        xl:mt-0 xl:flex">
        <app-stores-filters-sort-and-view [order]="order" [showGrid]="showGrid"></app-stores-filters-sort-and-view>
    </div>
    <div [ngClass]="{'hidden': !show_grid}" class="relative grid us:grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 items-start gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-4 lg-landscape:gap-x-4
                lg-landscape:mt-2 lg-landscape:px-0">
        <app-product-card *ngFor="let product of products" [productId]="product.id"
            [imageUrl]="product?.imagesDto?.image600" [name]="product.name" [price]="product.defaultPrice"
            [currency]="product.currency" [compare_at_price]="product.previous_price" [isLowStock]="product.isLowStock"
            [quantity]="product.quantity" [discount]="product.discount" [variantId]="product.defaultVariantId"
            [slug]="product.slug" [shipping_category]="product?.deliveryTime" [data_extra_types]="product.extraData"
            [resize]="true" [turn]="true" [top]="product?.top" [age_valid]="product.ageValid" [store]="product?.store"
            [product_type]="product.productType" [navigation]="product.navigation" [queryId]="product.queryId"
            [queryPosition]="product.queryPosition"></app-product-card>
        <app-product-card-skeleton *ngFor="let skeleton of skeletons" [resize]="true" [turn]="true">
        </app-product-card-skeleton>

        <div class="relative items-center justify-self-center h-auto"
            *ngIf="skeletons?.length === 0 && products?.length == 0">
            <img src="./assets/imgs/coming-soon.png" alt="">
            <p class="h-2 text-gray-#727272 font-Lato-Regular font-semibold">{{'COMPONENTS.STORES.COMING_SOON_PRODUCTS'
                | translate}}</p>
        </div>
    </div>

    <div [ngClass]="{'hidden': show_grid}" class="grid us:grid-cols-1 us-landscape:grid-cols-1 gap-y-2 mt-3">
        <app-list-product-card *ngFor="let product of products" [productId]="product.id"
            [imageUrl]="product.imagesDto?.image600" [name]="product.name" [price]="product.defaultPrice"
            [currency]="product.currency" [compare_at_price]="product.previous_price" [isLowStock]="product.isLowStock"
            [quantity]="product.quantity" [discount]="product.discount" [variantId]="product.defaultVariantId"
            [slug]="product.slug" [shipping_category]="product?.deliveryTime" [data_extra_types]="product.extraData"
            [resize]="true" [turn]="true" [top]="product?.top" [age_valid]="product?.ageValid" [store]="product?.store"
            [product_type]="product.productType" [navigation]="product.navigation" [queryId]="product.queryId"
            [queryPosition]="product.queryPosition"></app-list-product-card>

        <app-list-product-card-skeleton *ngFor="let skeleton of skeletons">
        </app-list-product-card-skeleton>
    </div>

    <!-- <div class="grid us:grid-cols-1 us-landscape:grid-cols-1 md:grid-cols-2 md-landscape:grid-cols-2 lg-landscape:grid-cols-1 gap-2 mt-3">
        <app-list-product-card *ngFor="let product of products" [productId]="product.id" [imageUrl]="product.imageUrl"
            [name]="product.name" [price]="product.price" [currency]="product.currency"
            [isLowStock]="product.isLowStock" [quantity]="product.quantity" [discount]="product.discount"
            [variantId]="product.defaultVariant" [slug]="product.slug!" [shipping_category]="product?.shipping_category!"
            [data_extra_types]="product.data_extra_types!" [resize]="true" [turn]="true"></app-list-product-card>
    </div> -->

    <div class="realtive w-full flex justify-center" *ngIf="haveMore">
        <button
            class="relative font-Lato-Regular font-medium bg-yellow-#FF6D03 rounded-full flex justify-center items-center text-white
        us:h-41px us:w-auto us:text-17px us:ml-3 us:px-6 us:mt-5 us:mb-5
        lg-landscape:h-41px lg-landscape:w-250px lg-landscape:text-19px lg-landscape:mt-10 lg-landscape:ml-0 lg-landscape:px-4"
            (click)="emitLoadMore()">
            {{'COMPONENTS.COMMON.LOAD_MORE' | translate}}
        </button>
    </div>
</div>