<section *ngIf="showFooter && footer_data" id="" class="relative w-full flex flex-col justify-center items-center">
    <div class="relative bg-blue-#18396C flex justify-center
    us:w-full us:h-auto
    lg-landscape:w-full lg-landscape:h-auto">
        <div
            class="relative
            us:px-5.3vw us:py-10 us:w-full
            lg-landscape:w-1291px lg-landscape:min-h-462px lg-landscape:pl-60px lg-landscape:pr-60px lg-landscape:pt-11 lg-landscape:pb-11">
            <div class="relative w-full h-auto">
                <img class="relative
                us:w-172px
                lg-landscape:w-172px" src="../../../assets/menu/logo.svg" alt="">
            </div>
            <div class="relative flex
            us:mt-5 us:flex-wrap us:gap-x-12 us:gap-y-10
            md-landscape:justify-around
            lg:justify-around
            lg-landscape:mt-7 lg-landscape:justify-start">
                <div *ngFor="let item of footer_data?.text_cmp_columns.data" class="relative
                    us:w-auto
                    lg-landscape:w-205px">
                    <p class="relative text-white font-Lato-Regular
                    us:text-17px us:leading-loose
                    lg-landscape:text-19px lg-landscape:leading-loose">{{item.attributes.Title}}</p>
                    <div *ngFor="let link of item.attributes.text_components.data" class="relative w-full">
                        <a *ngIf="link?.attributes?.Action == 'OpenTab'"
                            routerLink="/{{link?.attributes?.Route?.data?.attributes?.Route}}" target="_blank" class="relative text-white font-Lato-Light w-full
                         us:text-xs
                         lg-landscape:text-sm lg-landscape:leading-loose">{{link.attributes.Label}}</a>
                        <a *ngIf="link?.attributes?.Action == 'OpenExternalTab'"
                            [href]="link?.attributes?.Route?.data?.attributes?.Route" target="_blank" class="relative text-white font-Lato-Light w-full
                         us:text-xs
                         lg-landscape:text-sm lg-landscape:leading-loose">{{link.attributes.Label}}</a>
                        <a *ngIf="link?.attributes?.Action == 'Navigate'"
                            routerLink="/{{link?.attributes?.Route?.data?.attributes?.Route}}" class="relative text-white font-Lato-Light w-full
                         us:text-xs
                         lg-landscape:text-sm lg-landscape:leading-loose">{{link.attributes.Label}}</a>
                        <a *ngIf="link?.attributes?.Action == 'SendEmail'" href="mailto:{{link?.attributes?.Extra}}"
                            class="relative text-white font-Lato-Light w-full
                         us:text-xs
                         lg-landscape:text-sm lg-landscape:leading-loose">{{link.attributes.Label}}</a>
                    </div>
                </div>
                <!-- <div class="relative
                lg-landscape:w-205px lg-landscape:ml-45px">
                    <p class="relative text-white font-Lato-Regular capitalize
                    us:text-17px us:leading-loose
                     lg-landscape:text-19px lg-landscape:leading-loose">{{'SOURCEKATA' | translate}}</p>
                    <a class="relative text-white font-Lato-Light capitalize
                    us:text-sm us:leading-loose
                     lg-landscape:text-sm lg-landscape:leading-loose hover:cursor-pointer"
                        [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'mercado-unico']">Mercado
                        Único</a><br>
                    <a [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'recargas-a-cuba']"
                        class="relative text-white font-Lato-Light cursor-pointer capitalize
                    us:text-sm us:leading-loose
                     lg-landscape:text-sm lg-landscape:leading-loose">{{'RECHARGES' | translate}}</a><br>
                    <a [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'la-criolla']" class="relative text-white font-Lato-Light cursor-pointer capitalize
                    us:text-sm us:leading-loose
                     lg-landscape:text-sm lg-landscape:leading-loose">La Criolla</a><br>
                    <a [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'la-motorina']" class="relative text-white font-Lato-Light cursor-pointer capitalize
                    us:text-sm us:leading-loose
                     lg-landscape:text-sm lg-landscape:leading-loose">La Motorina</a><br>
                    <a [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'reforma']" class="relative text-white font-Lato-Light cursor-pointer capitalize
                    us:text-sm us:leading-loose
                     lg-landscape:text-sm lg-landscape:leading-loose">La Reforma</a><br>
                    <a routerLink="/travel-services/main" class="relative text-white font-Lato-Light cursor-pointer
                    us:text-sm us:leading-loose
                     lg-landscape:text-sm lg-landscape:leading-loose">{{'CONSULAR_TRAVEL_SERVICES' |
                        translate}}</a><br>
                    <a [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'stores', 'k-wholesaler']"
                        class="relative cursor-pointer text-white font-Lato-Light capitalize
                    us:text-sm us:leading-loose
                     lg-landscape:text-sm lg-landscape:leading-loose">{{'WHOLESALERS' | translate}}</a>
                </div> -->
                <!-- <div class="relative
                us:w-auto us:-ml-1.5
                lg-landscape:w-205px lg-landscape:ml-62px">
                    <p class="relative cursor-pointer text-white font-Lato-Regular
                    us:text-17px us:leading-loose
                     lg-landscape:text-19px lg-landscape:leading-loose">{{'FOOTER_MORE_STORES' | translate}}</p>
                    <a class="relative text-white font-Lato-Light
                    us:text-sm us:leading-loose
                     lg-landscape:text-sm lg-landscape:leading-loose hover:cursor-pointer"
                        routerLink="/stores/directory">{{'STORES_DIRECTORY' | translate}}</a><br>

                    <a [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'stores', 'k-seller']"
                        class="relative text-white font-Lato-Light capitalize
                      us:text-sm us:leading-loose
                      lg-landscape:text-sm lg-landscape:leading-loose">{{'IWANT_TO_SELL_IN_KATAPULK' |
                        translate}}</a><br>
                </div> -->
                <div class="relative
                lg-landscape:w-auto lg-landscape:ml-70px">

                </div>
            </div>

            <div class="relative w-full box-border overflow-hidden
            us:mt-10
            lg-landscape:mt-5">
                <p class="relative text-white font-Lato-Regular
            us:text-17px us:leading-loose
             lg-landscape:text-19px lg-landscape:leading-loose">{{'COMPONENTS.FOOTER.DOWNLOAD_APP' | translate}}</p>
                <!-- <button class="relative bg-yellow-#FFC35D rounded-3px flex justify-start items-center
                us:h-10 us:w-194px us:px-2
                lg-landscape:h-41px lg-landscape:w-194px lg-landscape:pl-2 lg-landscape:pr-2">
                    <img class="relative
                    us:w-13%
                    lg-landscape:w-13%" src="../../../assets/footer/icon-app.svg" alt="">
                    <p class="relative font-Lato-Regular font-semibold text-gray-#252525
                    us:text-13px
                    lg-landscape:text-13px">{{'DOWNLOAD_APP' | translate}}</p>
                </button> -->
            </div>

            <div class="relative w-full flex justify-between items-center flex-wrap gap-y-6
            us:mt-3
            lg-landscape:mt-5">
                <div class="relative flex flex-wrap gap-x-3 gap-y-3
            us:w-full
            lg-landscape:w-auto">
                    <a *ngFor="let item of footer_data?.DownloadAppButtons" target="_blank"
                        class="relative w-auto h-auto" [href]="item?.NavLink">
                        <img class="relatie w-194px h-16" [src]="item?.Image?.data?.attributes?.url" alt="">
                    </a>
                </div>
                <div class="relative flex justify-start items-center">
                    <p class="relative text-white font-Lato-Regular
                    us:hidden
                    lg-landscape:text-base lg-landscape:ml-2 lg-landscape:font-medium lg-landscape:inline-flex
                    ">Follow Us:</p>
                    <a class="relative
                    us:ml-9
                    lg-landscape:ml-9" href="https://www.facebook.com/katapulk.marketplace" target="_blank">
                        <fa-icon class="relative cursor-pointer flex justify-center items-center  text-yellow-#FFA101 rounded-full
                        us:text-base
                        lg-landscape:text-base" [icon]="facebook"></fa-icon>
                    </a>
                    <a class="relative
                    us:ml-11
                    lg-landscape:ml-9" href="https://www.instagram.com/katapulk.marketplace" target="_blank">
                        <fa-icon class="relative cursor-pointer flex justify-center items-center  text-yellow-#FFA101 rounded-full
                        us:text-17px
                        lg-landscape:text-lg" [icon]="instagram"></fa-icon>
                    </a>
                </div>
            </div>

            <div class="relative w-full border-t border-t-yellow-#515151
            us:mt-8
            lg-landscape:mt-4"></div>

            <p class="relative font-Lato-Light text-white
            us:text-xs us:mt-4
            lg-landscape:text-xs lg-landscape:mt-3">{{footer_data?.RightsLabel}}</p>
        </div>
    </div>
</section>