import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ApiService} from "./api.service";
import {AuthService} from "./auth.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {
  GenericShopListResponse,
  GetShopListDetailsResponse,
  GetShopListsResponse,
  ShopList,
  ChangeLocationRequest,
  ValidateChangeLocationResponse,
  ConvertFromCartRequest,
  ConvertFromOrderRequest,
  AddToShoppingListRequest,
  RemoveItemRequest,
  AdjustRequest,
  CreateCartRequest,
  CreateCartResponse,
  CreateShopListResponse
} from "../models/shop-list.model";
import {Observable, tap} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ShopListService {

    private readonly resource = `${environment.services_api_url}${environment.v1}/Orders/ShoppingList`;  // api/v1

    constructor(
        private readonly apiService: ApiService,
        private readonly authService: AuthService,
        private readonly http: HttpClient,
        private readonly translateService: TranslateService,
    ) {
    }

  getShopList(data: { filters?: string, sorts?: string, page: number, pageSize: number }): Observable<GetShopListsResponse> {
      const request = this.http.get<GetShopListsResponse>(this.resource, {
        headers: this.getHeaders(),
        params: new HttpParams({fromObject: data})
      });
      return this.manageResponse(request);
    }

    addShopList(data: ShopList): Observable<CreateShopListResponse> {
      const request = this.http.post<CreateShopListResponse>(this.resource, data, {
        headers: this.getHeaders()
      });
      return this.manageResponse(request);
    }

    validateChangeLocation(data: ChangeLocationRequest): Observable<ValidateChangeLocationResponse> {
      const request = this.http.post<ValidateChangeLocationResponse>(`${this.resource}/ValidateChangeLocation`, data, {
        headers: this.getHeaders()
      });
      return this.manageResponse(request);
    }

    changeLocation(data: ChangeLocationRequest): Observable<GenericShopListResponse> {
      const changeLocationEndpoint = this.http.post<GenericShopListResponse>(`${this.resource}/ChangeLocation`, data, {
        headers: this.getHeaders()
      });
      return this.manageResponse(changeLocationEndpoint);
    }

    updateShopList(data: ShopList): Observable<GenericShopListResponse> {
      const request = this.http.put<GenericShopListResponse>(this.resource, data, {
        headers: this.getHeaders()
      });
      return this.manageResponse(request);
    }

    removeShopList(data: {id: string}): Observable<GenericShopListResponse> {
      const request = this.http.delete<GenericShopListResponse>(this.resource, {
        headers: this.getHeaders(),
        body: data
      });
      return this.manageResponse(request);
    }

    shopListDetails(data: {Id: string}): Observable<GetShopListDetailsResponse> {
      const request = this.http.get<GetShopListDetailsResponse>(`${this.resource}/Detail`, {
        headers: this.getHeaders(),
        params: new HttpParams({fromObject: data})
      });
      return this.manageResponse(request);
    }

    createFromCart(data: ShopList): Observable<GenericShopListResponse> {
      const request = this.http.post<GenericShopListResponse>(`${this.resource}/CreateFromCart`, data, { headers: this.getHeaders() });
      return this.manageResponse(request);
    }

    createFromOrder(data: ConvertFromOrderRequest): Observable<GenericShopListResponse> {
      const request = this.http.post<GenericShopListResponse>(`${this.resource}/CreateFromOrder`, data, { headers: this.getHeaders() });
      return this.manageResponse(request);
    }

    addToShopList(data: AddToShoppingListRequest): Observable<GenericShopListResponse> {
      const request = this.http.post<GenericShopListResponse>(`${this.resource}/Add`, data, {
        headers: this.getHeaders()
      });
      return this.manageResponse(request);
    }

    removeItem(data: RemoveItemRequest): Observable<GenericShopListResponse> {
      const request = this.http.post<GenericShopListResponse>(`${this.resource}/RemoveItem`, data, {
        headers: this.getHeaders()
      });
      return this.manageResponse(request);
    }

    adjust(data: AdjustRequest): Observable<GenericShopListResponse> {
      const request = this.http.post<GenericShopListResponse>(`${this.resource}/Adjust`, data, {
        headers: this.getHeaders()
      });
      return this.manageResponse(request);
    }

    createCart(data: CreateCartRequest): Observable<CreateCartResponse> {
      const request = this.http.post<CreateCartResponse>(`${this.resource}/createCart`, data, {
        headers: this.getHeaders()
      });
      return this.manageResponse(request);
    }

    private manageResponse<T>(response: Observable<T>): Observable<T> {
      return response.pipe(
        tap((genericShopListResponse: T) => {
          // @ts-ignore
          if (genericShopListResponse.fails) {
            // @ts-ignore
            return this.apiService.handleErrors(new Error(genericShopListResponse.message), true);
          }
        }),
        catchError((error: any): Observable<T> => {
          console.log('entro al console log del error => ', error);
          return this.apiService.handleErrors(error, true);
        })
      );
    }

    private getHeaders(): HttpHeaders {
        let headers = this.authService.getHeader();
        headers = headers
            .set('X-Payment-Client', environment.xApiClient)
            .set('Accept-Language', this.translateService.currentLang);
        return headers;
    }
}
