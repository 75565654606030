import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ShopListService } from "../../../../../shared/services/shop-list.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { finalize, takeUntil } from "rxjs/operators";
import { of, Subject } from "rxjs";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  ShopListItem, GetShopListsResponse, AddToShoppingListRequest
} from "../../../../../shared/models/shop-list.model";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CreateShopListComponent } from "../create-shop-list/create-shop-list.component";
import { Router } from "@angular/router";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-add-to-shop-list',
  templateUrl: './add-to-shop-list.component.html',
  styleUrls: ['./add-to-shop-list.component.scss']
})
export class AddToShopListComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  isSaving: boolean = false;
  Page: number = 1;
  PageSize: number = 50;
  TotalPages: number = 0;

  shoppingLists: ShopListItem[] = [];

  isLoadingShoppingList: boolean = false;
  faSpinner = faSpinner;

  form: FormGroup = new FormGroup({
    shopping_list: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly dialogref: MatDialogRef<AddToShopListComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly shopListService: ShopListService,
    private readonly dialog: MatDialog,
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
    this.loadShoppingLists();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  close() {
    this.dialogref.close(null);
  }

  submit() {
    this.isSaving = true;

    if (!this.shoppingLists.length) {
      this.close();
      const { count, variantId } = this.data;
      let dialoRef = this.dialog.open(CreateShopListComponent, {
        disableClose: false,
        data: {
          variantId,
          count,
        }
        // position: { top: '60px' }
      });
      dialoRef.afterClosed()
        .subscribe({
          next: ((data: any) => {
            const { success, shoppingList } = data;
            console.log(data)
            if (success) {
              this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                  messageUppercase: true,
                  action: of({ success: true }),
                  confirmAction: 'COMPONENTS.COMMON.ACCEPT',
                  cancelAction: 'COMPONENTS.SHOP_LISTS.SHOPPING_LIST_GO_TO_SHOPPING_LIST',
                  message: "COMPONENTS.SHOP_LISTS.ADDED_TO_SHOP_LIST_SUCCESS",
                }
              })
                .afterClosed()
                .subscribe((response) => {
                  if (!response.success) {
                    this.router.navigate(['/profile/shop-lists', shoppingList.id]);
                  }
                });
            }
          }),
          error: (err => {
            throw new Error(err);
          })
        });
      return;
    }

    const data: AddToShoppingListRequest = {
      id: this.shopping_list?.value,
      variantId: this.data.variantId,
      count: this.data.count,
    }

    this.shopListService.addToShopList(data)
      .pipe(takeUntil(this.destroy$))
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.isSaving = false)
      )
      .subscribe({
        next: (res) => {
          const { success } = res;
          this.isSaving = false;
          this.dialogref.close({ success, shoppingListId: this.shopping_list?.value });
        },
        error: () => this.isSaving = false
      });
  }

  loadShoppingLists() {
    this.toggleLoading();
    this.shopListService.getShopList({ page: this.Page, pageSize: this.PageSize })
      .pipe(takeUntil(this.destroy$), finalize(() => this.toggleLoading()))
      .subscribe({
        next: (getShopListsResponse: GetShopListsResponse) => {
          this.shoppingLists = [...this.shoppingLists, ...getShopListsResponse.data.items];
          this.TotalPages = getShopListsResponse.data.totalPages;
          if (!this.shoppingLists.length) {
            this.shopping_list?.setValidators(null);
            this.shopping_list?.updateValueAndValidity();
          }
        },
        error: () => {
          this.toggleLoading();
        },
      });
  }

  toggleLoading() {
    this.isLoadingShoppingList = !this.isLoadingShoppingList;
  }

  get shopping_list() { return this.form.get('shopping_list'); }

}
