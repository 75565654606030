<div *ngIf="form" [formGroup]="form" class="relative flex flex-col justify-start items-start w-full">
    <label *ngIf="meta.label"
        class="relative font-Lato-Regular font-semibold text-gray-#5d5d5d text-sm leading-21px mb-1" for="">
        {{meta.label}}
    </label>
    <div class="relative w-full box-border pb-6">
        <input [matDatepicker]="picker" (click)="picker.open()" [placeholder]="meta.placeHolder" [id]="meta.fieldId"
            [formControlName]="meta.fieldId" [ngClass]="{
                   'border-none': selfControl?.valid || (selfControl?.invalid && !formMarkAsTouched && !(selfControl?.dirty || selfControl?.touched)),
                   'border-red-500': (selfControl?.invalid && formMarkAsTouched) || (selfControl?.invalid && (selfControl?.dirty || selfControl?.touched))
               }" class="relative px-3 bg-white text-gray-#252525 font-Lato-Regular w-full active:ring-0
                      placeholder-gray-#9D9D9D active:outline-none focus:ring-0 focus:outline-none
                      outline-none ring-0 border-none h-47px text-base" type="text">
        <div *ngIf="selfControl?.invalid && formMarkAsTouched || (selfControl?.invalid && !formMarkAsTouched && (selfControl?.dirty || selfControl?.touched))"
            class="absolute bottom-0.5 text-sm text-red-500">
            <p>{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}</p>
            <!--          <p *ngIf="selfControl?.hasError('pattern')">{{ 'WRONG_FORMAT' | translate }}</p>-->
        </div>
        <mat-datepicker #picker color="primary"></mat-datepicker>

    </div>

    <!--          valid: {{selfControl?.valid}}-->
    <!--          formMarkAsTouched: {{formMarkAsTouched}}-->
</div>