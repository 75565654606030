<div class="flex bg-white box-border cursor-pointer p-17px gap-x-4 overflow-hidden h-full
us:w-auto"
    [ngClass]="turn ? 'flex-row w-full h-40 justify-between shadow-md' : 'flex-col lg-landscape:w-220px justify-start'">
    <a class="relative flex justify-center items-center
    us:min-h-88px
    lg-landscape:min-h-113px" [ngClass]="{'h-auto w-auto sm:w-full sm:h-auto': turn, 'w-full h-auto': !turn}">
        <!-- <img class="absolute w-6 top-4 right-4 z-10" src="../../../../../assets/imgs/product-card/icon-wish.svg" alt=""> -->
        <div class="grid-img absolute w-full h-full z-10 opacity-70 transition-all ease-in-out duration-700">
        </div>
        <img (load)="loadedImg($event)"
            class="relative object-contain transition-all ease-in-out duration-700 opacity-0"
            [ngClass]="turn ? 'h-auto w-full' : 'w-64% h-auto'" [src]="imageUrl" alt="">
    </a>
    <div class="relative flex flex-col h-full"
        [ngClass]="turn ? 'lg-landscape:w-full justify-between text-base' : 'w-full justify-between text-lg'">
        <a class="relative w-full font-Lato-Regular text-blue-#18396C line-clamp-2 uppercase">
            {{name}}
        </a>

        <div class="relative w-full" [ngClass]="turn ? 'mt-1' : 'mt-3'">
            <a class="relative w-full font-Lato-Regular text-gray-#212121 font-bold"
                [ngClass]="turn ? 'text-lg':'text-xl'">
                $ {{display_usd_amount}}
                <!--      *ngIf="compare_at_price && compare_at_price != 0"-->
                <!-- <span class="relative text-sm ml-2 text-red-400 line-through font-normal" *ngIf="discount?.hasDiscount">
                {{discount?.labelLineThrough | currency: currency}}
            </span> -->
            </a>

            <div (click)="buyGiftCard()" class="relative w-full bg-yellow-#FF6D03 font-Lato-Regular text-white h-9 rounded-full
            flex justify-center items-center leading-none" [ngClass]="turn ? 'mt-1' : 'mt-3'">
                <p class="relative text-17px leading-none">{{'COMPONENTS.COMMON.BUY' | translate}}</p>
            </div>
        </div>

    </div>
</div>