import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { GroupedStore, SelectedZone, User } from "../../shared/models";
import { Router, Event, NavigationEnd, NavigationError, ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogRef, MatDialogState } from "@angular/material/dialog";
import {
  ProvinceSelectionComponent
} from '../../modules/shared/components/province-selection/province-selection.component';
import { categories } from '../../shared/navigation/categories';
import { stores } from '../../shared/navigation/stores';
import { travels } from '../../shared/navigation/travels';
import { profile } from '../../shared/navigation/profile';
import { trendings } from '../../shared/navigation/trending';
import { faBell, faBellSlash, faClose, faGift, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { StoreService } from "../../shared/services/store.service";
import { CartService } from "../../shared/services/cart.service";
import { Cart } from "../../shared/models";
import * as moment from "moment";
import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';
// @ts-ignore
import * as countdown from "countdown";
import { fromEvent, interval, of, Subject, Subscription, takeWhile, tap } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from "../../shared/services/language.service";
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ZonesService } from "../../shared/services/zones.service";
import { SafeResourceUrl } from '@angular/platform-browser';
import { MenuService } from 'src/app/shared/services/menu.service';
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { CartPreviewComponent } from 'src/app/modules/payment/components/cart-preview/cart-preview.component';
import { faShoppingCart, faCreditCard, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { DynamicCOnfigService } from 'src/app/shared/services/dynamic-config.service';
import { ContinueBuyingDialogComponent } from "../continue-buying-dialog/continue-buying-dialog.component";
import { LoginToAddCartDialogComponent } from "../../modules/shared/components/login-to-add-cart-dialog/login-to-add-cart-dialog.component";
import { RemittanceService } from "../../shared/services/remittance.service";
import { CheckUserVerificationDialogComponent } from "../../modules/remittance/components/check-user-verification-dialog/check-user-verification-dialog.component";
import { ConfirmDialogComponent } from "../../modules/shared/components/confirm-dialog/confirm-dialog.component";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { GiftPreviewComponent } from 'src/app/modules/gift/components/gift-preview/gift-preview.component';
import { NewCart } from "../../shared/models/new-cart.model";
import { NewCartService } from "../../shared/services/newcart.service";
import { CubacelService } from 'src/app/modules/cubacel/services/cubacel.service';
import { AlgoliaService } from 'src/app/shared/services/algolia.service';
import { FrontendFeatureService } from "../../shared/services/frontend-feature.service";
import { InternalNotificationService } from "../../modules/shared/services/internal-notification.service";
import {
  NotificationTrayComponent
} from "../../modules/shared/notifications/notification-tray/notification-tray.component";
import { PusherService } from "../../shared/services/pusher.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  NotificationSnackComponent
} from "../../modules/shared/notifications/notification-snack/notification-snack.component";

const DEFAULT_DURATION = 300;

const RemittanceUrlCont = 'remittance/main';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ]),
    // trigger('collapse2', [
    //   state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
    //   state('true', style({ height: '0', visibility: 'hidden' })),
    //   transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
    //   transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    // ])
  ]
})
export class MenuComponent implements OnInit, OnDestroy {
  close = faClose;
  menu_categories = categories;
  menu_stores = stores;
  menu_travels = travels;
  menu_profile = profile;
  menu_trendings = trendings;
  selected_link: any = '';
  selected_link_travel: any = '';
  showcat = false;
  showstores = false;
  showwestern = false;
  showtravels = false;
  showprofile = false;
  showsubcat = false;
  showtsubcat = false;
  showsubcattiendas = false;
  showcatTravels = false;
  @ViewChild('searchInput', { static: true }) search_input: any;
  @ViewChild('searchInputRes', { static: true }) search_input_res: any;
  extra_items: any;
  trailing_items: any;
  others_label: any = null;
  others: any = null;

  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;


  haveCart: boolean = false;
  cartTime: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
  timerId: any;
  cartFinishDate: Date | null;
  counterTimer$: Subscription | undefined;

  languages = [
    { id: "es", title: "LANGUAGES.ES", flag: "es" },
    { id: "en", title: "LANGUAGES.EN", flag: "us" }
  ];

  selectedLanguage!: { id: string; title: string; flag: string };

  destroy$: Subject<boolean> = new Subject<boolean>();
  finishCartSubs$: Subject<boolean> = new Subject<boolean>();
  cart: any | undefined = undefined;
  iFrameUrl: SafeResourceUrl = "https://oncubatravel.com/landing_visas";
  displayFrame = true;

  hideDefaultStore = false;
  cart_icon = faShoppingCart;
  bag_icon = faShoppingBag;
  faCartShopping = faCreditCard;
  cartEnum = 'cart';

  matDialogRefCB: MatDialogRef<any> | undefined;
  show_side_bar = false;
  faArrowRight = faArrowRight;
  hide_stores = false;
  fagift = faGift;
  showcatnave = false;

  user: User = this.authService.getCurrentUser();
  emailNoDomain: any;
  cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart'];
  actual_route: string = '';
  cubacel_route = false;
  valid_bag = false;
  suggestions: string[] = [];
  private searchSubject = new Subject<string>();
  selected_sugestion = null;
  userId: string | undefined = undefined;
  notificationAddedSubscription!: Subscription;
  userChangeSubscription!: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private storeService: StoreService,
    private cartService: CartService,
    private newCartService: NewCartService,
    private pusherService: PusherService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private menuService: MenuService,
    private notification: NotificationService,
    private cdr: ChangeDetectorRef,
    private zonesService: ZonesService,
    public cookieService: CookieService,
    private dynamicService: DynamicCOnfigService,
    private remittanceService: RemittanceService,
    private activeRoute: ActivatedRoute,
    private cubacelService: CubacelService,
    private frontendFeatureService: FrontendFeatureService,
    private internalNotificationService: InternalNotificationService,
    private algoliaService: AlgoliaService,
    private snackBar: MatSnackBar
  ) {
    // this.storesObs =
    this.zonesService.subjectSelectedZones$
      .pipe(takeUntil(this.destroy$))
      .subscribe((zone) => {
        if (zone) {
          this.storeService.getStoresByZoneId(zone.zone)
            .pipe(takeUntil(this.destroy$))
            .subscribe((groupedStore: GroupedStore | undefined) => {
              const stores = groupedStore?.stores;
              // console.log('mapped array of stores => ', stores);
              this.hideDefaultStore = !stores?.some((store) => store.id === this.defaultStore) || false;
              this.menu_stores.links = stores?.slice(0, stores.length / 2).map((store) => {
                return {
                  id: store.id,
                  text: store.name,
                  route: `/${store.code}`,
                  links: []
                };
              });
              this.menu_stores.links2 = stores?.slice(stores.length / 2).map((store) => {
                return {
                  text: store.name,
                  route: `/${store.code}`,
                  links: []
                };
              });
            });
        }
      });

    this.cartTime = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    this.cartFinishDate = null;
    this.dynamicService.dynamic_settings_subject.subscribe((data: any) => {
      this.getGLobalConfig();
    });
    this.authService.currentUserSubject.subscribe((user) => {
      this.user = user;
      this.emailNoDomain = this.user?.email?.split('@')[0];
      if (this.user?.id && this.user?.readyToUse) {
        this.getCartValues();
        this.initCartTime();
        this.cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart', 'new'];
        // if (this.user?.useNewCart) {
        //   this.cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart', 'new'];
        // } else {
        //   this.cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart'];
        // }
      } else {
        this.cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart'];
      }
    });

    this.internalNotificationService.notificationsRead.subscribe({next: (count: number) => {
        this.unreadCount -= count;
    }})
  }

  ngOnInit(): void {
    this.initLanguage();
    this.getGLobalConfig();
    // if (this.user?.id && this.user?.readyToUse) {
    //     this.getCartValues();
    //     this.initCartTime();
    //     if (this.user?.useNewCart) {
    //         this.cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart', 'new'];
    //     }
    // }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (!this.router.url.includes('/global-search')) {
          this.search_input.nativeElement.value = '';
          this.search_input_res.nativeElement.value = '';
        } else {
          this.search_input.nativeElement.value = this.getQueryParams() ?? null;
          this.search_input_res.nativeElement.value = this.getQueryParams() ?? null;
        }
        this.closeAll();
        this.actual_route = this.router.url;
        if (this.router.url.includes('/cubacel')) {
          this.cubacel_route = true;
          this.valid_bag = localStorage['cubacel_shopping_bag'] ? JSON.parse(localStorage['cubacel_shopping_bag'])?.length : false;
          this.cubacelService.update_cubacel_bag.subscribe({
            next: (data) => {
              this.valid_bag = localStorage['cubacel_shopping_bag'] ? JSON.parse(localStorage['cubacel_shopping_bag'])?.length : false;
            }
          })
        } else {
          this.cubacel_route = false;
        }
      }

      if (event instanceof NavigationError) {
        this.closeAll();
      }
    });

    this.menu_categories = this.menuService.getFinalCategories(this.selectedLanguage?.id);
    // localStorage.setItem('global_categories')
    this.searchSubject.pipe(debounceTime(400)).subscribe((searchValue) => {
      this.algoliaService.searchSuggestions(searchValue, this.selectedLanguage?.id).subscribe((data: any) => {
        this.suggestions = data?.data;
      });
    });

    document.documentElement.addEventListener('click', (event: any) => {
      const box: any = document.querySelectorAll('.search-suggestion-box');
      box?.forEach((element: any) => {
        if (!element?.contains(event?.target)) this.suggestions = [];
      });
    },
    );

    this.moveDownAndUpThrowSuggestionsWithArrows();

    this.userChangeSubscription = this.authService.currentUserSubject.subscribe((user) => {
      this.userId = user?.id?.toString();
      if (this.userId) {
        this.pusherService.bindEvent(this.userId, (data: any) => {
          this.internalNotificationService.notificationAdded.emit(data);
        });
        this.internalNotificationService.getUnreadCount().subscribe({next: result => {
            this.unreadCount = result?.data?.unreadCount ?? 0
          }
        })
      } else {
        this.unreadCount = 0
      }
    })

    this.notificationAddedSubscription = this.internalNotificationService.notificationAdded.subscribe((data: any) => {
      if (!this.newNotifications.some((notification: any) => notification.Id === data.Id)) {
        this.unreadCount++;
        this.newNotifications.push(data)
        this.notificationSnack(data.Title)
      }
    })
  }

  notificationSnack(title: string): void {
    this.snackBar.openFromComponent(NotificationSnackComponent, {
      data: { title },
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'bg-white'
    });
  }

  mercadoUnicoNavigate() {
    // let params = { store: 'mercado-unico' };
    this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/mercado-unico`],
      {
        // relativeTo: this.activeRoute,
        // queryParams: params,
        // queryParamsHandling: 'merge'
      }
    );
  }

  navigate(id: string, link: string) {
    this.cookieService.delete('STORAGE_FILTER_BY_STORE_ID_' + 1);
    this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}${link}`]);
  }

  private getQueryParams() {
    let queryparams: any = null;
    this.activeRoute.queryParams.subscribe((data: any) => {
      queryparams = data?.global_search;
    })
    return queryparams;
  }

  showSubLink(link: any) {
    if (link.isShowSubLink) {
      link.isShowSubLink = false;
    } else {
      link.isShowSubLink = true;
    }
  }

  getGLobalConfig() {
    if (localStorage.getItem('GLOBAL_CONFIGS')) {
      let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
      this.extra_items = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.TopBar?.data?.attributes?.ExtraItems?.data;
      this.trailing_items = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.TopBar?.data?.attributes?.TrailingItems?.data;
      this.hide_stores = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.TopBar?.data?.attributes?.HideStores ?? false;
      this.others_label = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.TopBar?.data?.attributes?.OtherLabel;
      this.others = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.TopBar?.data?.attributes?.Others?.data;
      // console.log(this.others);

    }
  }

  redirect(requireLogin: boolean = false, url: string) {
    if (requireLogin) {
      if (this.authService.isAuthenticated()) {
        // if (url.includes(RemittanceUrlCont)) {
        //   this.remittanceService.checkUserVerification({quickFaceVerificationId: undefined}).subscribe((response: any) => {
        //     if (response.success) {
        //       this.dialog.open(CheckUserVerificationDialogComponent, {
        //         disableClose: true,
        //         data: {
        //           title: 'CHECK_USER_VERIFICATION',
        //           fraudWarning: response.data.fraudWarning
        //         }
        //       }).afterClosed()
        //         .pipe(filter((name) => name))
        //         .subscribe((response) => {
        //           if (response && response.success) {
        //             this.router.navigateByUrl(`/${url}`);
        //           }
        //         });
        //     } else {
        //       this.dialog.open(ConfirmDialogComponent, {
        //         disableClose: true,
        //         data: {
        //           messageUppercase: true,
        //           hiddenCancelAction: true,
        //           cancelAction: 'CANCEL',
        //           confirmAction: 'ACCEPT',
        //           message: response.message,
        //           // notificationSuccess: "",
        //         }
        //       }).afterClosed()
        //         .pipe(filter((name) => name))
        //         .subscribe();
        //     }
        //   });
        // } else {
        //   this.router.navigateByUrl(`/${url}`);
        // }
        this.router.navigateByUrl(`/${url}`);
      } else {
        // if (url.includes(RemittanceUrlCont)) {
        //   this.dialog.open(LoginToAddCartDialogComponent, {
        //     width: '400px',
        //     disableClose: true,
        //     data: {
        //       messageUppercase: true,
        //       cancelAction: 'CANCEL',
        //       confirmAction: 'GO_TO_LOGIN',
        //       message: "TO_GO_TO_REMITTANCES_YOU_MUST_REGISTER",
        //       // redirectToUrl: `/${url}`
        //     }
        //   });
        // } else {
        //   this.dialog.open(LoginToAddCartDialogComponent, {
        //     width: '400px',
        //     disableClose: true,
        //     data: {
        //       messageUppercase: true,
        //       cancelAction: 'CANCEL',
        //       confirmAction: 'GO_TO_LOGIN',
        //       message: "TO_GO_TO_REMITTANCES_YOU_MUST_REGISTER",
        //       redirectToUrl: `/${url}`
        //     }
        //   });
        // }
        this.dialog.open(LoginToAddCartDialogComponent, {
          width: '400px',
          disableClose: true,
          data: {
            messageUppercase: true,
            cancelAction: 'COMPONENTS.COMMON.CANCEL',
            confirmAction: 'COMPONENTS.COMMON.GO_TO_LOGIN',
            message: "TO_GO_TO_REMITTANCES_YOU_MUST_REGISTER",
            redirectToUrl: `/${url}`
          }
        });
      }
    } else {
      this.router.navigateByUrl(`/${url}`);
    }
  }

  get getSelectedZones(): string {
    return this.zonesService.getZonesValuesStorage()?.municipality_name || this.zonesService.getZonesValuesStorage()?.zone_name || 'DELIVERY_ZONE';
  }

  get getFirstAction() {
    return localStorage.getItem('first_action');
  }

  setShowCat() {
    this.showcat = (!this.showcat && !!this.getSelectedZone);
    this.showstores = false;
    this.showtravels = false;
    this.showsubcat = false;
    this.showtsubcat = false;
    this.showprofile = false;
    this.showcatnave = false;
  }

  onClickOutside(event: MouseEvent): void {
    // Lógica para cerrar el menú o realizar otra acción
    this.showcat = false;
  }

  initLanguage() {
    const languageAux = localStorage.getItem("language");
    let language = languageAux ? JSON.parse(languageAux) : null;
    if (!language) {
      language = this.translateService.getBrowserLang();
      localStorage.setItem("language", JSON.stringify(language));
      this.translateService.use(language);
    }
    const findLanguage = this.languages.find(it => it.id === language);
    this.selectedLanguage = findLanguage ? findLanguage : this.languages[0];
  }

  // get user(): User {
  //    return this.authService.getCurrentUser();
  // }

  setLanguage(lang: { id: string; title: string; flag: string }) {
    if (this.selectedLanguage.id !== lang.id) {
      this.selectedLanguage = lang;
      this.translateService.use(lang.id);
      localStorage.setItem("language", JSON.stringify(lang.id));
      this.languageService.selectedLanguage.next(lang.id);
      // this.searchProduct();
      // this.searchProductRes();
      this.menu_categories = this.menuService.getFinalCategories(this.selectedLanguage?.id);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well

    this.finishCartSubs$.next(true);
    this.finishCartSubs$.unsubscribe();
    this.cubacelService.update_cubacel_bag.unsubscribe();

    if (this.userId) {
      this.pusherService.unbindEvent(this.userId);
    }

    this.notificationAddedSubscription.unsubscribe();
    this.userChangeSubscription.unsubscribe()
  }

  logout(): void {
    this.showprofile = false;
    if (this.user?.id && this.user?.readyToUse) {
      if (this.newCartService.haveCart()) {
        this.newCartService.removeCartValuesInStorage();
      }
      // if (this.user?.useNewCart) {
      //   if (this.newCartService.haveCart()) {
      //     this.newCartService.removeCartValuesInStorage();
      //   }
      // } else {
      //   if (this.cartService.haveCart()) {
      //     this.cartService.removeCartValuesInStorage();
      //   }
      // }
    }
    this.authService.logout();
    this.router.navigate(['']);
  }

  showCountryProvinceModal() {
    if (this.getFirstAction === 'done') {
      let dialoRef = this.dialog.open(ProvinceSelectionComponent, {
        disableClose: false,
        // position: { top: '60px' }
      });
      dialoRef.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: ((data: any) => {

          }),
          error: (err => {
            throw new Error(err);
          })
        });
    }
  }

  detectando(link: any) {
    if (link?.links?.length > 0) {
      this.selected_link = link;
      this.showsubcat = true;
    } else {
      this.showsubcat = false;
    }
  }

  detectando1(link: any) {
    if (link?.links?.length > 0) {
      this.selected_link_travel = link;
      this.showtsubcat = true;
    } else {
      this.showtsubcat = false;
    }
  }

  detectandoLinkResponsive() {
    this.showsubcattiendas = true;
  }

  closesub() {
    this.selected_link = '';
    this.showsubcat = false;
  }

  detectandoTravels() {
    this.showcatTravels = true;
  }

  updateTime() {
    if (this.cartFinishDate) {
      const now = new Date();
      const expirationCartDate = this.cartFinishDate;
      const diff = expirationCartDate.getTime() - now.getTime();
      // console.log(diff);
      if (diff <= 0) {
        this.stopCartTime(true, true);
        return;
      }
      // Cálculos para sacar lo que resta hasta ese tiempo objetivo / final
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const mins = Math.floor(diff / (1000 * 60));
      const secs = Math.floor(diff / 1000);

      // La diferencia que se asignará para mostrarlo en la pantalla
      this.cartTime.days = days;
      this.cartTime.hours = hours - days * 24;
      this.cartTime.minutes = mins - hours * 60;
      this.cartTime.seconds = secs - mins * 60;
    } else {
      // console.log('NO HAY CARRITO PARA ACTUALIZAR TIEMPO');
      this.stopCartTime(false, true);
    }
  }

  // Ejecutamos la acción cada segundo, para obtener la diferencia entre el momento actual y el objetivo
  start() {
    return interval(1000).pipe(
      takeWhile(() => this.cartFinishDate !== null && this.cartTime.minutes >= 0 && this.cartTime.seconds >= 0),
      tap(() => {
        this.updateTime();
      }),
    );
  }

  closeAll() {
    this.showcat = false;
    this.showstores = false;
    this.showtravels = false;
    this.showprofile = false;
    this.showsubcat = false;
    this.showtsubcat = false;
    this.showsubcattiendas = false;
    this.showcatTravels = false;
  }

  getCartValues() {
    if (this.user?.id && this.user?.readyToUse) {
      this.newCartService.getShoppingCart(this.getSelectedZone?.zone || 6, this.getSelectedZone?.municipality || 37)
        .pipe(takeUntil(this.destroy$))
        .subscribe(cart => {
          // this.newCartService.mapDataAndSaveCartValues(cart, false);
          this.cart = <NewCart>cart;
        });
      // if (this.user.useNewCart) {
      //   this.newCartService.getShoppingCart(this.getSelectedZone?.zone || 6, this.getSelectedZone?.municipality || 37)
      //     .pipe(takeUntil(this.destroy$))
      //     .subscribe(cart => {
      //       // this.newCartService.mapDataAndSaveCartValues(cart, false);
      //       this.cart = <NewCart>cart;
      //     });
      // } else {
      //   this.cartService.getCart(this.getSelectedZone?.zone || 6, this.translateService.currentLang)
      //     .pipe(takeUntil(this.destroy$))
      //     .subscribe(cart => {
      //       // this.newCartService.mapDataAndSaveCartValues(cart, false);
      //       this.cart = <Cart>cart;
      //     });
      // }
    }
  }

  initCartTime() {
    if (this.user?.id && this.user?.readyToUse) {
      this.newCartService.newCartSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((cart) => {
          this.cartTimesInit(cart, this.newCartService.getCartExpirationDate())
        });
      // if (this.user?.useNewCart) {
      //   this.newCartService.newCartSubject$
      //     .pipe(takeUntil(this.destroy$))
      //     .subscribe((cart) => {
      //       this.cartTimesInit(cart, this.newCartService.getCartExpirationDate())
      //     });
      // } else {
      //   this.cartService.cartSubject$
      //     .pipe(takeUntil(this.destroy$))
      //     .subscribe((cart) => {
      //       this.cartTimesInit(cart, this.cartService.getCartExpirationDate())
      //     });
      // }
    }

  }

  cartTimesInit(cart: Cart | NewCart | undefined | null, expirationDate: string) {
    setTimeout(() => {
      this.cart = cart;
      if (cart !== null && cart !== undefined) {
        this.finishCartSubs$ = new Subject<boolean>();
        // console.log('existe carrito');
        // Inicializando fecha de expiracion del carrito a partir de los datos de session storage
        const cartExpirationDate = new Date(expirationDate);
        const cartUpdateDate = new Date(this.cart?.updated_at! || this.cart?.lastFetch!);
        const timeLeft = moment(new Date()).diff(cartExpirationDate);
        const totalTime = moment(cartUpdateDate).diff(cartExpirationDate);
        if (timeLeft < 0) {
          // console.log('existe carrito y no ha expirado');
          const third = Math.abs(Math.floor(totalTime / 3));
          this.cartFinishDate = moment(cartExpirationDate).subtract(third, 'millisecond').toDate();
          this.counterTimer$ = this.start()
            .pipe(takeUntil(this.finishCartSubs$))
            .subscribe((_) => {
              if (this.cartTime.minutes === 0 && this.cartTime.seconds === 0) {
                // console.log('tiempo expirado: munitos  => ' + this.cartTime.minutes + '---- segundos => ' + this.cartTime.seconds)
                this.stopCartTime(true, true);
              }
            });
        } else {
          // console.log('diferencia de timepo >= 0')
          this.stopCartTime(false, true);
        }
      } else {
        // console.log('no existe cart'); (click)="seeCartPreview()"
        this.stopCartTime(false, true);
      }
    }, 0);
  }

  initCartVariables() {
    this.cartTime = {
      hours: 0,
      minutes: 0,
      seconds: 0,
      days: 0
    };
    this.haveCart = false;
    // this.cartService.removeCartValuesInStorage();
  }

  stopCartTime(withMessage: boolean = false, withSet = false) {
    if (withSet) {
      this.initCartVariables();
    }

    this.cartFinishDate = null;
    this.counterTimer$?.unsubscribe();
    if (!this.finishCartSubs$.closed) {
      this.finishCartSubs$.next(true);
      this.finishCartSubs$.complete();
      this.finishCartSubs$.unsubscribe();
      if (withMessage) {
        if (this.matDialogRefCB?.getState() !== MatDialogState.OPEN) {
          this.matDialogRefCB = this.dialog.open(ContinueBuyingDialogComponent, {
            disableClose: true,
            data: {
              messageUppercase: true,
              // action: this.newCartService.updateCartExpirationDate(this.translateService.currentLang),
              action: of({ success: true }),
              cancelAction: 'COMPONENTS.CART.NO_EMPTY_CART',
              confirmAction: 'NOTIFICATION_MESSAGES.CONTINUE_BUY',
              message: "NOTIFICATION_MESSAGES.CONTINUE_BUYING_QUESTION",
              // notificationSuccess: "",
              // cancelActionFunction: this.newCartService.emptyCart(),
              cancelActionFunction: of({ success: true }),
            }
          });

          this.matDialogRefCB.afterClosed()
            .pipe(filter((name) => name))
            .subscribe((response) => {
              // if (response.success) {}
              if (response.closed) {
                this.stopCartTime(false, true);
              }
              this.matDialogRefCB = undefined;
            });
        }
      }
    }
  }

  searchProduct() {
    this.router.navigateByUrl(`/${this.getSelectedZone?.area_selected?.toLowerCase()}/global-search/${this.search_input?.nativeElement?.value}`);
    // return this.productService.globalSearchProduct(this.search_input?.nativeElement?.value);
  }

  searchProductRes() {
    this.router.navigateByUrl(`/${this.getSelectedZone?.area_selected?.toLowerCase()}/global-search/${this.search_input?.nativeElement?.value}`);
    // return this.productService.globalSearchProduct(this.search_input?.nativeElement?.value);
  }

  async navigateToWallet() {
    await this.frontendFeatureService.updatePaymentFeature(this.user);
    if (this.user?.id && this.user?.readyToUse) {
      if (this.user?.useNewPayment) {
        this.goToPaymentsProfile("");
      } else {
        this.router.navigateByUrl(`/${this.getSelectedZone?.area_selected?.toLowerCase()}/profile/payments/1`);
      }
    } else {
      this.dialog.open(LoginToAddCartDialogComponent, {
        width: '400px',
        disableClose: true,
        data: {
          messageUppercase: true,
          isRedirectToWallet: true,
          cancelAction: 'COMPONENTS.COMMON.CANCEL',
          confirmAction: 'LOG_IN',
          message: "NOTIFICATION_MESSAGES.TO_GO_TO_YOUR_K_WALLET_YOU_MUST_LOGIN",
        }
      });
    }
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  async gotoRoute(link: string) {
    await this.frontendFeatureService.updatePaymentFeature(this.user);
    if (link === '/profile/payments/cards' && this.user?.useNewPayment) {
      this.goToPaymentsProfile(link, '2');
    } else if (link === '/profile/payment-requests' && this.user?.useNewPayment) {
      this.goToPaymentsProfile(link);
    } else if (link === '/profile/user-payments') {
      this.goToPaymentsProfile(link);
    } else {
      link == '/contents/customer-support' || link.includes('/profile') ?
        this.router.navigateByUrl(`${link}`) :
        this.router.navigateByUrl(`/${this.getSelectedZone?.area_selected?.toLowerCase()}${link}`);
      setTimeout(() => {
        this.showprofile = false;
      }, 50);
    }
  }

  get defaultStore() {
    return environment.default_store;
  }

  goToCategory(route: string, id: number) {
    // console.log(`/${this.getSelectedZone?.area_selected?.toLowerCase()}${route}`);
    // route != "" ? this.router.navigateByUrl(`/${this.getSelectedZone?.area_selected?.toLowerCase()}${route}`) : null;
    route != "" ? this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}${route}`], {
      queryParams: { taxon: id }
    }) : null;
  }

  showSubElement(e: any) {
    let self_element = e?.target as HTMLElement;
    let element = e?.target?.parentElement?.nextSibling as HTMLElement;
    self_element?.classList?.contains('rotate-90') ?
      self_element?.classList?.remove('rotate-90') :
      self_element?.classList?.add('rotate-90');
    element?.classList?.contains('h-0') ?
      (element?.classList?.remove('h-0'),
        element?.classList?.add('h-auto')) :
      (element?.classList?.remove('h-auto'),
        element?.classList?.add('h-0'));
  }

  searchGlobalProduct(text: string) {
    let params: any = {};
    if (text && text.length >= 2 && text.trim() !== '' && text.trim() !== 'undefined') {
      params.global_search = text;
      params.exclude = false;
      this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/global-search`],
        {
          queryParams: params,
          queryParamsHandling: 'merge'
        }
      );
    }
  }

  enterPressed(event: any) {
    let text = null;
    if (this.selected_sugestion) {
      text = this.selected_sugestion;
      this.selected_sugestion = null;
      if (event?.keyCode == 13) this.searchGlobalProduct(text);
    } else {
      this.selected_sugestion = null;
      if (event?.keyCode == 13) this.searchGlobalProduct(event?.target?.value);
    }
  }

  navigateToGiftCards() {
    this.router.navigateByUrl(`/gift-cards`);
  }

  seeGiftPreview() {
    if (this.authService.isAuthenticated()) {
      let dialoRef = this.dialog.open(GiftPreviewComponent, {
        disableClose: false,
        position: { right: '0px' },
        width: '384px',
        closeOnNavigation: true
      });
      dialoRef.afterClosed().subscribe({
        next: (data) => {
          // console.log(data);
        },
        error: (error: any) => {
          // console.log(error);
        }
      })
    } else {
      this.router.navigate([`/auth/signIn`],
        {
          // relativeTo: this.activeRoute,
          queryParams: {
            redirectURL: '/gift-cards'
          },
        }
      );
    }
  }

  seeCartPreview() {
    if (!this.actual_route.includes('cart')) {
      let dialoRef = this.dialog.open(CartPreviewComponent, {
        disableClose: false,
        position: { right: '0px' },
        width: '384px'
      });
      dialoRef.afterClosed().subscribe({
        next: (data) => {
          console.log(data);
        },
        error: (error: any) => {
          console.log(error);
        }
      })
    } else {
      this.router.navigate(this.cartRoute);
    }
  }
  async goToPaymentsProfile(link: string, tab?: string) {
    this.authService.goToPaymentsProfile(link, tab);
  }

  getSuggestions(e: any) {
    if (e?.target?.value?.trim() == '' || e?.target?.value?.trim().length < 2) return this.suggestions = [];
    if (e?.inputType == 'deleteContentBackward') return null;
    return this.searchSubject.next(e?.target?.value);
  }

  moveDownAndUpThrowSuggestionsWithArrows() {
    let inp: any = document.getElementById("inp");
    let liSelected: any;
    let index = -1;
    let next: any;

    inp.addEventListener('keydown', (event: any) => {
      let ul: any = document.getElementById('sug-box');
      if (!ul) return;
      let len = ul?.getElementsByTagName('li').length - 1;
      if (event.which === 40) {
        index++;
        //down
        if (liSelected) {
          this.removeClass(liSelected, 'bg-gray-#dfdfdf');
          next = ul.getElementsByTagName('li')[index];
          if (typeof next !== undefined && index <= len) {

            liSelected = next;
            this.selected_sugestion = liSelected?.innerText;
          } else {
            index = 0;
            liSelected = ul.getElementsByTagName('li')[0];
            this.selected_sugestion = liSelected?.innerText;
          }
          this.addClass(liSelected, 'bg-gray-#dfdfdf');
        } else {
          index = 0;
          liSelected = ul.getElementsByTagName('li')[0];
          this.selected_sugestion = liSelected?.innerText;
          this.addClass(liSelected, 'bg-gray-#dfdfdf');
        }
      } else if (event.which === 38) {

        //up
        if (liSelected) {
          this.removeClass(liSelected, 'bg-gray-#dfdfdf');
          index--;
          next = ul.getElementsByTagName('li')[index];
          if (typeof next !== undefined && index >= 0) {
            liSelected = next;
            this.selected_sugestion = liSelected?.innerText;
          } else {
            index = len;
            liSelected = ul.getElementsByTagName('li')[len];
            this.selected_sugestion = liSelected?.innerText;
          }
          this.addClass(liSelected, 'bg-gray-#dfdfdf');
        } else {
          index = 0;
          liSelected = ul.getElementsByTagName('li')[len];
          this.selected_sugestion = liSelected?.innerText;
          this.addClass(liSelected, 'bg-gray-#dfdfdf');
        }
      } else if (event.which === 13) {
        if (liSelected) {
          // inp.value = liSelected?.innerText;
          this.enterPressed({ keyCode: 13 });
          this.suggestions = [];
        }
      }
    }, false);
  }

  removeClass(el: any, className: any) {
    if (el.classList) {
      el.classList.remove(className);
    } else {
      el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  };

  addClass(el: any, className: any) {
    if (el.classList) {
      el.classList.add(className);
    } else {
      el.className += ' ' + className;
    }
  };

  // Notifications
  public faBell = faBell;
  public unreadCount: number = 0;

  private newNotifications: string[] = [];

  openNotificationTray() {
    this.dialog.open(NotificationTrayComponent, {
      disableClose: false,
      position: { right: '0px' },
      width: '384px',
    });
  }
}
