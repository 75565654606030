<div class="relative w-full flex flex-col items-center justify-center">
    <form class="relative bg-gray-#f2f2f2 rounded-3px overflow-y-auto
  us:w-screen us:h-screen us:w-90vw max-h-81vh us:pb-14
  us-landscape:w-auto landscape:h-screen
  md:w-673px md:pb-5
  md-landscape:w-auto md-landscape:pb-5
  lg-landscape:w-673px lg-landscape:pb-5
  xl-landscape:w-673px" [formGroup]="addressForm">
        <!-- h-37.86vw -->
        <div class="relative bg-white w-full flex items-center
    h-55px pl-23px pr-23px">
            <p class="relative font-Lato-Regular font-bold text-gray-#252525
        text-17px">
                {{ modalHeader | translate }}:
            </p>
        </div>

        <div class="relative w-full pl-23px pr-23px pt-2">
            <div class="relative w-full flex flex-col">
                <!--      <div class="flex flex-1 flex-col relative pb-6">-->
                <!--        <input placeholder="{{ 'LABEL' | translate }}* {{'LABEL_HINT' | translate}}" formControlName="label" id="label" class="relative text-gray-#727272 bg-white font-Lato-Regular border-none focus:outline-none active:outline-none focus:ring-0 active:ring-0-->
                <!--                h-47px rounded-3px pl-4 text-base" type="text">-->
                <!--        <div *ngIf="label?.invalid && (label?.dirty || label?.touched)"-->
                <!--          class="absolute bottom-0.5 text-sm text-red-500">-->
                <!--          <p *ngIf="label?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>-->
                <!--        </div>-->
                <!--      </div>-->

                <div class="relative w-full flex items-center justify-start gap-3 pb-2"
                    *ngIf="isCheckout && !isShippingAddress && !isModeEdit">
                    <input type="checkbox" name="import_billing_address" id="import_billing_address" class="relative border border-gray-#5d5d5d w-4 h-4 text-gray-#5d5d5d
focus:ring-offset-0 cursor-pointer focus:outline-none
                        active:outline-none focus:ring-0 active:ring-0" (change)="importShippingAddressData($event)">
                    <p class="relative font-Lato-Regular text-14px leading-21px font-normal text-gray-#727272">{{
                        'IMPORT_CHECKOUT_BILLING_ADDRESS' | translate }}</p>
                </div>

                <div class="grid gap-2
      us:grid-cols-1
      us-landscape:grid-cols-2
      md:grid-cols-2
      md-landscape:grid-cols-2">
                    <div class="flex flex-1 flex-col relative pb-6">
                        <input placeholder="{{ 'COMPONENTS.COMMON.NAME' | translate }}*" formControlName="firstname"
                            id="firstname" [ngClass]="{
                  'border-none': firstname?.valid || (firstname?.invalid && !(firstname?.dirty || firstname?.touched)),
                  'border-red-500': (firstname?.invalid && formMarkAsTouched) || (firstname?.invalid && (firstname?.dirty || firstname?.touched))
                  }" class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base"
                            type="text">
                        <div *ngIf="firstname?.invalid && (firstname?.dirty || firstname?.touched)"
                            class="absolute bottom-0.5 text-sm text-red-500">
                            <p *ngIf="firstname?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate
                                }}</p>
                            <p *ngIf="firstname?.hasError('maxlength')">{{ 'COMPONENTS.COMMON.MAX_LENGTH' | translate:
                                {maxLength: 35} }}
                            </p>
                            <p *ngIf="firstname?.hasError('pattern') && !firstname?.hasError('maxlength')">
                                {{ 'COMPONENTS.PROFILE.CHECK_WORK_SPACING' | translate }}
                            </p>
                        </div>
                    </div>

                    <div class="flex flex-1 flex-col relative pb-6">
                        <input placeholder="{{ 'COMPONENTS.COMMON.SURNAMES' | translate }}*" formControlName="lastname"
                            id="lastname" [ngClass]="{
                  'border-none': lastname?.valid || (lastname?.invalid && !(lastname?.dirty || lastname?.touched)),
                  'border-red-500': (lastname?.invalid && formMarkAsTouched) || (lastname?.invalid && (lastname?.dirty || lastname?.touched))
                  }" class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                        focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
                        <div *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)"
                            class="absolute bottom-0.5 text-sm text-red-500">
                            <p *ngIf="lastname?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate
                                }}</p>
                            <p *ngIf="lastname?.hasError('maxlength')">{{ 'COMPONENTS.COMMON.MAX_LENGTH' | translate:
                                {maxLength: 35} }}
                            </p>
                            <p *ngIf="lastname?.hasError('pattern') && !lastname?.hasError('maxlength')">{{
                                'COMPONENTS.PROFILE.CHECK_WORK_SPACING' | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="flex flex-1 flex-col relative pb-6">
                    <input placeholder="{{ 'COMPONENTS.COMMON.EMAIL_ADDRESS' | translate }}*"
                        formControlName="email_receiver" id="email_receiver" [ngClass]="{
                  'border-none': email_receiver?.valid || (email_receiver?.invalid && !(email_receiver?.dirty || email_receiver?.touched)),
                  'border-red-500': (email_receiver?.invalid && formMarkAsTouched) || (email_receiver?.invalid && (email_receiver?.dirty || email_receiver?.touched))
               }" class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                      focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
                    <div *ngIf="email_receiver?.invalid && (email_receiver?.dirty || email_receiver?.touched)"
                        class="absolute bottom-0.5 text-sm text-red-500">
                        <p *ngIf="email_receiver?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' |
                            translate }}</p>
                        <p *ngIf="email_receiver?.hasError('pattern')">{{ 'COMPONENTS.COMMON.WRONG_FORMAT' | translate
                            }}</p>
                    </div>
                </div>

                <div class="flex flex-1 flex-col relative pb-6">
                    <input placeholder="{{ 'COMPONENTS.PROFILE.ADDRESS_LINE_1' | translate }}*"
                        formControlName="address1" id="address1" [ngClass]="{
                  'border-none': address1?.valid || (address1?.invalid && !(address1?.dirty || address1?.touched)),
                  'border-red-500': (address1?.invalid && formMarkAsTouched) || (address1?.invalid && (address1?.dirty || address1?.touched))
               }" class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                      focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
                    <div *ngIf="address1?.invalid && (address1?.dirty || address1?.touched)"
                        class="absolute bottom-0.5 text-sm text-red-500">
                        <p *ngIf="address1?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}
                        </p>
                        <p *ngIf="address1?.hasError('maxlength')">{{ 'COMPONENTS.COMMON.MAX_LENGTH' | translate:
                            {maxLength: 100} }}</p>
                        <p *ngIf="address1?.hasError('pattern') && !address1?.hasError('maxlength')">{{
                            'COMPONENTS.PROFILE.CHECK_WORK_SPACING' | translate }}</p>
                    </div>
                </div>

                <div class="flex flex-1 flex-col relative pb-6">
                    <input placeholder="{{ 'COMPONENTS.PROFILE.ADDRESS_LINE_2' | translate }}"
                        formControlName="address2" id="address2" [ngClass]="{
                  'border-none': address2?.valid || (address2?.invalid && !(address2?.dirty || address2?.touched)),
                  'border-red-500': (address2?.invalid && formMarkAsTouched) || (address2?.invalid && (address2?.dirty || address2?.touched))
               }" class="relative text-gray-#727272 bg-white font-Lato-Regular border-none focus:outline-none active:outline-none
                      focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
                    <div *ngIf="address2?.invalid && (address2?.dirty || address2?.touched)"
                        class="absolute bottom-0.5 text-sm text-red-500">
                        <p *ngIf="address2?.hasError('maxlength')">{{ 'COMPONENTS.COMMON.MAX_LENGTH' | translate:
                            {maxLength: 100} }}</p>
                        <p *ngIf="address2?.hasError('pattern') && !address2?.hasError('maxlength')">{{
                            'COMPONENTS.PROFILE.CHECK_WORK_SPACING' | translate }}</p>
                    </div>
                </div>

                <div id="notificationMessageCheckout" class="relative w-full flex flex-1 flex-col pb-6 gap-1"
                    *ngIf="isShippingAddress && isCheckout">
                    <div [ngClass]="{'border-2 animate-bounce border-yellow-#FFC35D': isSetColorToOrange}"
                        class="bg-blue-300 flex flex-row p-17px font-Lato-Regular justify-between rounded-3px">
                        <div>{{
                            'COMPONENTS.PROFILE.INFORMATION_CREATE_UPDATE_ORDER_CHECKOUT' | translate: {
                            province,
                            municipality
                            }
                            }}
                        </div>
                        <div class="items-center flex pl-2">
                            <button
                                class="relative flex gap-0.5 bg-yellow-#FFC35D rounded-3px w-20 h-8 items-center justify-center"
                                (click)="openChangeProvinceModal()">
                                <img class="relative flex h-6 w-6" src="../../../../../assets/menu/icon-location.svg"
                                    alt="">
                                <span class="relative">{{ 'COMPONENTS.PROFILE.HERE' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="us:grid md:flex gap-2
        us:grid-cols-1
        us-landscape:grid-cols-2
        md:grid-cols-2
        md-landscape:grid-cols-2">
                    <div *ngIf="!isLoadingCountries" class="relative flex flex-1 justify-start items-center pb-6">
                        <mat-select placeholder="{{ 'COMPONENTS.COMMON.COUNTRY' | translate }}*"
                            formControlName="country_iso" required (selectionChange)="changeCountry($event)" [ngClass]="{
                          'border-none': country_iso?.valid || (country_iso?.invalid && !(country_iso?.dirty || country_iso?.touched)),
                          'border-red-500': (country_iso?.invalid && formMarkAsTouched) || (country_iso?.invalid && (country_iso?.dirty || country_iso?.touched))
                      }" (click)="handleClickEvent($event)" class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
                            <mat-option *ngFor="let country of countries" [value]="country.iso2">{{ country.name }}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="country_iso?.invalid && (country_iso?.dirty || country_iso?.touched)"
                            class="absolute bottom-0.5 text-sm text-red-500">
                            <p *ngIf="country_iso?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' |
                                translate }}</p>
                        </div>
                    </div>

                    <div *ngIf="isLoadingCountries" class="relative flex flex-1 justify-start items-center pb-6">
                        <div
                            class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
                            {{ 'COMPONENTS.COMMON.COUNTRY' | translate }}*
                        </div>
                        <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
                    </div>

                    <div *ngIf="!isLoadingStates" class="relative flex flex-1 justify-start items-center pb-6">
                        <mat-select placeholder="{{ 'COMPONENTS.COMMON.STATE_OR_PROVINCE' | translate }}*"
                            formControlName="state_name" required [ngClass]="{
                          'border-none': state_name?.valid || (state_name?.invalid && !(state_name?.dirty || state_name?.touched)),
                          'border-red-500': (state_name?.invalid && formMarkAsTouched) || (state_name?.invalid && (state_name?.dirty || state_name?.touched))
                      }" (selectionChange)="changeState($event)" (click)="handleClickEvent($event)" class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
                            <mat-option *ngFor="let state of states" [value]="state.name">{{ state.name }}</mat-option>
                        </mat-select>
                        <div *ngIf="state_name?.invalid && (state_name?.dirty || state_name?.touched)"
                            class="absolute bottom-0.5 text-sm text-red-500">
                            <p *ngIf="state_name?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' |
                                translate }}</p>
                        </div>
                    </div>

                    <div *ngIf="isLoadingStates" class="relative flex flex-1 justify-start items-center pb-6">
                        <div
                            class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
                            {{ 'COMPONENTS.COMMON.STATE_OR_PROVINCE' | translate }}*
                        </div>
                        <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
                    </div>
                </div>

                <div class="grid gap-2
        us:grid-cols-1
        us-landscape:grid-cols-2
        md:grid-cols-2
        md-landscape:grid-cols-2">
                    <div *ngIf="!isLoadingCities && showSelectInput"
                        class="relative flex flex-1 justify-start items-center pb-6">
                        <mat-select placeholder="{{ 'COMPONENTS.COMMON.CITY' | translate }}*" formControlName="city"
                            required [ngClass]="{
                          'border-none': city?.valid || (city?.invalid && !(city?.dirty || city?.touched)),
                          'border-red-500': (city?.invalid && formMarkAsTouched) || (city?.invalid && (city?.dirty || city?.touched))
                      }" (selectionChange)="changeCity($event)" (click)="handleClickEvent($event)" class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
                            <mat-option *ngFor="let city of cities" [value]="city.name">{{ city.name }}</mat-option>
                        </mat-select>
                        <div *ngIf="city?.invalid && (city?.dirty || city?.touched)"
                            class="absolute bottom-0.5 text-sm text-red-500">
                            <p *ngIf="city?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}
                            </p>
                        </div>
                    </div>

                    <div *ngIf="!isLoadingCities && !showSelectInput" class="flex flex-1 flex-col relative pb-6">
                        <input placeholder="{{ 'COMPONENTS.COMMON.CITY' | translate }}*" formControlName="city"
                            id="city" [ngClass]="{
                      'border-none': city?.valid || (city?.invalid && !(city?.dirty || city?.touched)),
                      'border-red-500': (city?.invalid && formMarkAsTouched) || (city?.invalid && (city?.dirty || city?.touched))
                  }" class="border relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base"
                            type="text">
                        <div *ngIf="city?.invalid && (city?.dirty || city?.touched)"
                            class="absolute bottom-0.5 text-sm text-red-500">
                            <p *ngIf="city?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}
                            </p>
                            <p *ngIf="city?.hasError('pattern')">{{ 'COMPONENTS.COMMON.WRONG_FORMAT' | translate }}</p>
                        </div>
                    </div>

                    <div *ngIf="isLoadingCities" class="relative flex flex-1 justify-start items-center pb-6">
                        <div
                            class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                          focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
                            {{ 'COMPONENTS.COMMON.CITY' | translate }}*
                        </div>
                        <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
                    </div>

                    <div *ngIf="!isLoadingCities && showSelectInput" class="flex flex-1 flex-col relative pb-6">
                        <mat-select placeholder="{{ 'COMPONENTS.PROFILE.ZIP_CODE' | translate }}*"
                            formControlName="zipcode" required [ngClass]="{
                          'border-none': zipcode?.valid || (zipcode?.invalid && !(zipcode?.dirty || zipcode?.touched)),
                          'border-red-500': (zipcode?.invalid && formMarkAsTouched) || (zipcode?.invalid && (zipcode?.dirty || zipcode?.touched))
                      }" class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
                            <mat-option *ngFor="let postalCode of postalCodes" [value]="postalCode">{{ postalCode }}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="zipcode?.invalid && (zipcode?.dirty || zipcode?.touched)"
                            class="absolute bottom-0.5 text-sm text-red-500">
                            <p *ngIf="zipcode?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate
                                }}</p>
                        </div>
                    </div>

                    <div *ngIf="!isLoadingCities && !showSelectInput" class="flex flex-1 flex-col relative pb-6">
                        <input placeholder="{{ 'COMPONENTS.PROFILE.POSTAL_CODE' | translate }}*"
                            formControlName="zipcode" id="zipcode" [ngClass]="{
                      'border-none': zipcode?.valid || (zipcode?.invalid && !(zipcode?.dirty || zipcode?.touched)),
                      'border-red-500': (zipcode?.invalid && formMarkAsTouched) || (zipcode?.invalid && (zipcode?.dirty || zipcode?.touched))
                  }" class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base"
                            type="text">
                        <div *ngIf="zipcode?.invalid && (zipcode?.dirty || zipcode?.touched)"
                            class="absolute bottom-0.5 text-sm text-red-500">
                            <p *ngIf="zipcode?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate
                                }}</p>
                            <p *ngIf="zipcode?.hasError('pattern')">{{ 'COMPONENTS.COMMON.WRONG_FORMAT' | translate }}
                            </p>
                        </div>
                    </div>

                    <div *ngIf="isLoadingCities" class="relative flex flex-1 justify-start items-center pb-6">
                        <div
                            class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
                            {{ 'COMPONENTS.PROFILE.ZIP_CODE' | translate }}*
                        </div>
                        <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
                    </div>

                    <!--        <div *ngIf="isCheckout" class="flex flex-1 flex-col relative pb-6">-->
                    <!--          <input placeholder="{{ 'PHONE_NUMBER' | translate }}*" formControlName="phone" id="phone"-->
                    <!--                 [ngClass]="{-->
                    <!--                      'border-none': phone?.valid || (phone?.invalid && !(phone?.dirty || phone?.touched)),-->
                    <!--                      'border-red-500': (phone?.invalid && formMarkAsTouched) || (phone?.invalid && (phone?.dirty || phone?.touched))-->
                    <!--                  }"-->
                    <!--                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">-->
                    <!--          <div *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)"-->
                    <!--               class="absolute bottom-0.5 text-sm text-red-500">-->
                    <!--            <p *ngIf="phone?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>-->
                    <!--          </div>-->
                    <!--        </div>-->
                </div>

                <div class="flex flex-1 flex-col relative pb-6">
                    <!--        <input placeholder="{{ 'PHONE_NUMBER' | translate }}*" formControlName="phone" id="phone" class="relative text-gray-#727272 bg-white font-Lato-Regular border-none focus:outline-none active:outline-none focus:ring-0 active:ring-0-->
                    <!--                h-47px rounded-3px pl-4 text-base" type="text">-->
                    <!--        <div *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)"-->
                    <!--          class="absolute bottom-0.5 text-sm text-red-500">-->
                    <!--          <p *ngIf="phone?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>-->
                    <!--        </div>-->

                    <div class="flex" *ngIf="!isLoadingPhones">
                        <ngx-mat-intl-tel-input [ngClass]="{
                      'border-none': phone?.valid || (phone?.invalid && !(phone?.dirty || phone?.touched)),
                      'border-red-500': (phone?.invalid && formMarkAsTouched) || (phone?.invalid && (phone?.dirty || phone?.touched))
                  }" class="border mat-intl-tel-input-class h-47px relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 rounded-3px pl-4 text-base flex flex-1 items-center"
                            [onlyCountries]="[countryIsoSelected]"
                            inputPlaceholder="{{'COMPONENTS.COMMON.PHONE_NUMBER' | translate}}*"
                            [enablePlaceholder]="true" name="phone" formControlName="phone"
                            (countryChanged)="onChangeCountryPhone($event)">
                        </ngx-mat-intl-tel-input>
                        <div *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)"
                            class="absolute bottom-0.5 text-sm text-red-500">
                            <p *ngIf="phone?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}
                            </p>
                            <p *ngIf="phone?.hasError('validatePhoneNumber')">{{
                                'COMPONENTS.COMMON.INVALID_PHONE_NUMBER' | translate }}
                            </p>
                        </div>
                    </div>

                    <div *ngIf="isLoadingPhones" class="relative flex flex-1 justify-start items-center">
                        <div
                            class="flex flex-1 pr-4 pl-100px py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
                            {{ 'COMPONENTS.COMMON.PHONE_NUMBER' | translate }}*
                        </div>
                        <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
                    </div>

                </div>
                <div class="flex flex-1 flex-col relative pb-6" *ngIf="isShippingAddress">

                    <div class="flex" *ngIf="!isLoadingPhones">
                        <ngx-mat-intl-tel-input [ngClass]="{
                        'border-none': secondaryPhone?.valid || (secondaryPhone?.invalid && !(secondaryPhone?.dirty || secondaryPhone?.touched)),
                        'border-red-500': (secondaryPhone?.invalid && formMarkAsTouched) || (secondaryPhone?.invalid && (secondaryPhone?.dirty || secondaryPhone?.touched))
                    }" class="border mat-intl-tel-input-class h-47px relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 rounded-3px pl-4 text-base flex flex-1 items-center"
                            [onlyCountries]="[countryIsoSelected]"
                            inputPlaceholder="{{'COMPONENTS.PROFILE.SECONDARY_PHONE' | translate}}"
                            [enablePlaceholder]="true" name="secondaryPhone" formControlName="secondaryPhone"
                            (countryChanged)="onChangeCountrySecondaryPhone($event)">
                        </ngx-mat-intl-tel-input>
                        <div *ngIf="secondaryPhone?.invalid && (secondaryPhone?.dirty || secondaryPhone?.touched)"
                            class="absolute bottom-0.5 text-sm text-red-500">
                            <p *ngIf="secondaryPhone?.hasError('validatePhoneNumber')">{{
                                'COMPONENTS.COMMON.INVALID_PHONE_NUMBER' |
                                translate }}</p>
                        </div>
                    </div>

                    <div *ngIf="isLoadingPhones" class="relative flex flex-1 justify-start items-center">
                        <div
                            class="flex flex-1 pr-4 pl-100px py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                          focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
                            {{ 'COMPONENTS.PROFILE.SECONDARY_PHONE' | translate }}
                        </div>
                        <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
                    </div>
                </div>

                <!--      <div class="flex flex-1 flex-col relative pb-2">-->
                <!--        <textarea placeholder="{{ 'DELIVERY_INSTRUCTION_OPT_ADDRESS' | translate }}" class="relative text-gray-#727272 bg-white font-Lato-Regular border-none focus:outline-none active:outline-none focus:ring-0 active:ring-0-->
                <!--                        h-auto rounded-3px pl-4 text-base" id="w3review" name="w3review" rows="3"></textarea>-->
                <!--      </div>-->
                <div class="flex flex-1 flex-col relative pb-6" *ngIf="isShippingAddress">
                    <textarea [ngClass]="{
                      'border-none': userInstructions?.valid || (userInstructions?.invalid && !(userInstructions?.dirty || userInstructions?.touched)),
                      'border-red-500': (userInstructions?.invalid && formMarkAsTouched) || (userInstructions?.invalid && (userInstructions?.dirty || userInstructions?.touched))
                    }" placeholder="{{ 'COMPONENTS.PROFILE.DELIVERY_INSTRUCTION_OPT_ADDRESS' | translate }}" class="relative text-gray-#727272 bg-white font-Lato-Regular border-none focus:outline-none active:outline-none focus:ring-0 active:ring-0
                                h-auto rounded-3px pl-4 pb-4 text-base" id="w3review" name="w3review" rows="3"
                        formControlName="userInstructions"></textarea>
                    <div *ngIf="userInstructions?.invalid && (userInstructions?.dirty || userInstructions?.touched)"
                        class="absolute bottom-0.5 text-sm text-red-500">
                        <p *ngIf="userInstructions?.hasError('maxlength')">{{ 'COMPONENTS.COMMON.MAX_LENGTH' |
                            translate: {maxLength: 100}
                            }}</p>
                        <p *ngIf="userInstructions?.hasError('pattern') && !userInstructions?.hasError('maxlength')">{{
                            'COMPONENTS.PROFILE.CHECK_WORK_SPACING' | translate }}</p>
                    </div>
                </div>
            </div>

            <!--    <div class="realtive flex justify-start items-center">-->
            <!--      <input-->
            <!--        class="focus:ring-offset-0 relative cursor-pointer border border-gray-#9D9D9D focus:outline-none active:outline-none focus:ring-0 active:ring-0"-->
            <!--        type="checkbox">-->
            <!--      <label class="relative text-gray-#5d5d5d font-Lato-Regular text-base-->
            <!--            ml-3" for="">{{'MARK_AS_PREFERRED_ADDRESS' | translate}}</label>-->
            <!--    </div>-->
        </div>
        <div class="relative w-full py-4" *ngIf="isShippingAddress && isCheckout"></div>
    </form>
    <div class="sticky bottom-0 left-0 right-0 py-2 w-full bg-gray-#f2f2f2">
        <div class="relative w-full flex justify-start items-center px-23px">
            <button
                class="relative bg-yellow-#FFC35D flex justify-center items-center cursor-pointer w-auto h-41px rounded-3px px-4"
                type="button" (click)="onSubmit()" [disabled]="isSaving"
                [ngClass]="{'opacity-50': isSaving, 'cursor-not-allowed ': isSaving}">
                <p *ngIf="!isModeEdit" class="relative font-Lato-Bold text-15px leading-4">
                    {{ 'COMPONENTS.PROFILE.ADD_YOUR_ADDRESS' | translate }}
                </p>
                <p *ngIf="isModeEdit" class="relative font-Lato-Bold text-15px leading-4">
                    {{ 'COMPONENTS.PROFILE.EDIT_YOUR_ADDRESS' | translate }}
                </p>
                <fa-icon *ngIf="isSaving"
                    class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 h-9 text-xl text-white"
                    [icon]="faSpinner">
                </fa-icon>
            </button>
            <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D hover:bg-gray-#EEEEEE hover:border-gray-500
    w-40 h-41px rounded-3px ml-2" type="button" mat-dialog-close="close">
                <p class="relative font-Lato-Bold
     text-15px leading-4">{{ 'COMPONENTS.COMMON.CANCEL' | translate }}</p>
            </button>
        </div>
    </div>
</div>