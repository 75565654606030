import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Router } from "@angular/router";
import { UserService } from 'src/app/shared/services/user.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  faSpinner = faSpinner;
  reset_pass: FormGroup;
  spin = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.reset_pass = new FormGroup({
      email: new FormControl(null, {
        validators: [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
      }),
    })
  }

  ngOnInit(): void {

  }

  onSubmit() {
    if (this.reset_pass.valid) {
      this.spin = true;
      this.userService.recoverPass(this.reset_pass?.value).subscribe((data: any) => {
        this.spin = false;
        this.notificationService.showAndSubscribe('EMAIL_SENT', 'COMPONENTS.COMMON.ACCEPT').afterClosed().subscribe({
          next: () => {
            this.router.navigateByUrl('/');
          }
        })
      })
    } else {
      this.reset_pass.markAsUntouched();
    }
    // https://api.katapulk.com/api/v2/storefront/passwords
  }

}
