import {Component, Inject, OnInit} from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {NotificationTrayComponent} from "../notification-tray/notification-tray.component";
import {faBell} from "@fortawesome/free-solid-svg-icons";


@Component({
  selector: 'app-notification-snack',
  templateUrl: './notification-snack.component.html',
  styleUrls: ['./notification-snack.component.scss']
})
export class NotificationSnackComponent  {

  constructor(
      @Inject(MAT_SNACK_BAR_DATA) public data: any,
      private snackBarRef: MatSnackBarRef<NotificationSnackComponent>,
      private dialog: MatDialog
  ) {}

  openNotificationTray(): void {
    this.dialog.open(NotificationTrayComponent, {
      disableClose: false,
      position: { right: '0px' },
      width: '384px',
    });
    this.snackBarRef.dismiss();
  }

  readonly faBell = faBell;
}
