<section class="relative bg-white
us:w-80 us:min-h-144px us:h-auto us:p-4
lg-landscape:w-80 lg-landscape:min-h-144px lg-landscape:h-auto lg-landscape:p-4">
    <fa-icon (click)="close()"
        class="absolute top-2 right-2 cursor-pointer flex justify-center items-center  text-yellow-#FFA101 lg-landscape:text-base"
        [icon]="faClose"></fa-icon>
    <p class="relative font-Lato-Regular text-sm font-semibold px-2">{{'COMPONENTS.PRODUCTS.WRITE_CUSTOM' | translate}}
    </p>
    <div class="relative w-full h-px bg-gray-300 my-4"></div>
    <form class="relative w-full" [formGroup]="form">
        <input class="relative fk-input bg-white border-2 border-solid rounded-3px border-gray-#727272 text-gray-#727272 w-full font-Lato-Regular
        focus:outline-none active:outline-none focus:ring-0 active:ring-0
        h-47px text-base" type="number" formControlName="quantity" [max]="data">
        <p *ngIf="quantityControl?.errors && !quantityControl?.hasError('required') && !quantityControl?.hasError('pattern')"
            class="relative w-auto text-red-300 mt-1 font-Lato-Regular text-sm">
            {{'COMPONENTS.PRODUCTS.MAX_ALLOWED_QUANTITY' | translate}}
            {{data}} <span *ngIf="data > 1">{{'COMPONENTS.PRODUCTS.UNITS' | translate}}</span><span
                *ngIf="data == 1">{{'COMPONENTS.PRODUCTS.UNIT' |
                translate}}</span></p>
    </form>
    <div class="relative flex justify-start items-center mt-6">
        <button
            [ngClass]="form?.invalid || quantityValue <= 0 ? 'cursor-not-allowed opacity-50' : 'cursor-pointer opacity-100'"
            [disabled]="form?.invalid" (click)="closeWithData()" class="relative bg-yellow-#FFC35D font-Lato-Regular font-semibold text-sm border-none active:ring-0 active:border-none focus:ring-0 focus:border-none
        px-6 py-2 rounded-3px">{{'COMPONENTS.COMMON.ACCEPT' | translate}}</button>
        <button (click)="close()" class="relative bg-white font-Lato-Regular font-semibold text-sm ml-5 border-none active:ring-0 active:border-none focus:ring-0 focus:border-none
        px-6 py-2 rounded-3px">{{'COMPONENTS.COMMON.CANCEL' | translate}}</button>
    </div>
</section>