import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(value: any): string {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'JUST NOW'
        return this.translate.instant('JUST_NOW');
      const intervals: { [key: string]: number } = {
        'COMPONENTS.NOTIFICATION_CARD.YEAR': 31536000,
        'COMPONENTS.NOTIFICATION_CARD.MONTH': 2592000,
        'COMPONENTS.NOTIFICATION_CARD.WEEK': 604800,
        'COMPONENTS.NOTIFICATION_CARD.DAY': 86400,
        'COMPONENTS.NOTIFICATION_CARD.HOUR': 3600,
        'COMPONENTS.NOTIFICATION_CARD.MINUTE': 60,
        'COMPONENTS.NOTIFICATION_CARD.SECOND': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          if (counter === 1) {
            return `${counter} ${this.translate.instant(i)}`; // singular (1 DAY AGO)
          } else if (i === 'MONTH') {
            return `${counter} ${this.translate.instant('COMPONENTS.NOTIFICATION_CARD.MONTHS')}`; // plural (2 MONTHS AGO)
          } else {
            return `${counter} ${this.translate.instant(i)}s`; // plural (2 DAYS AGO)
          }
        }
      }
    }
    return value;
  }
}
