<div class="p-4 flex justify-between rounded-lg mb-48 border border-yellow-#FF6D03">
  <span class="text-yellow-#FF6D03 font-Lato-Bold">{{ data.title }}</span>
  <a class="relative flex justify-end items-center cursor-pointer" (click)="openNotificationTray()">
    <div class="relative w-8 lg-landscape:h-27px">
      <fa-icon
        class="relative flex justify-center items-center bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
        [icon]="faBell">
      </fa-icon>
    </div>
  </a>
</div>
