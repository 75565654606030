import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ShopListService} from "../../../../../shared/services/shop-list.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  AddressItem, GetDeliveryCountriesResponse,
  GetDeliveryStatesResponse,
  NewAddressResponse,
  NewCountry, NewState, RestrictedCity
} from "../../../../../shared/models/new-address.model";
import {NewAddressService} from "../../../../../shared/services/new-address.service";
import {finalize, takeUntil} from "rxjs/operators";
import {Observable, Subject, switchMap} from "rxjs";
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {SelectedZone, Zone} from "../../../../../shared/models";
import {ZonesService} from "../../../../../shared/services/zones.service";
import {
  ShopList,
  ShopListItem,
  ChangeLocationRequest,
  GenericShopListResponse, AddToShoppingListRequest, CreateShopListResponse, ConvertFromOrderRequest
} from "../../../../../shared/models/shop-list.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

type ShopListForm = {
  name: string
  description: string
  city: string
  state_name: string
  country_iso: string
  contact: AddressItem
}

@Component({
  selector: 'app-create-shop-list',
  templateUrl: './create-shop-list.component.html',
  styleUrls: ['./create-shop-list.component.scss']
})
export class CreateShopListComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  isSaving: boolean = false;
  Page: number = 1;
  PageSize: number = 50;
  TotalPages: number = 0;

  addresses: AddressItem[] = [];

  isLoadingContacts: boolean = false;

  isLoadingCountries: boolean = true;
  isLoadingStates: boolean = true;
  isLoadingCities: boolean = true;
  faSpinner = faSpinner;

  showSelectInput: boolean = true;
  countryCuIso: string = 'CU';
  countryIsoSelected: string = 'cu';

  countries: NewCountry[] = [];
  states: (NewState | Zone)[]  = [];
  cities: RestrictedCity[] = [];

  isAutoComplete: boolean = false;

  isModeEdit: boolean = false;
  isChangeLocation: boolean = false;
  isConvertFromCart: boolean = false;
  isConvertFromOrder: boolean = false;

  shopList: ShopListItem | undefined;

  variantId: string | undefined;
  count: string | undefined;

  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', []),
    city: new FormControl('', [Validators.required]),
    state_name: new FormControl('', [Validators.required]),
    country_iso: new FormControl('', [Validators.required]),
    contact: new FormControl('', []),
  });

  countriesZones: any = {};

  shoppingListAutoAdding: ShopList | undefined;

  constructor(
    private readonly dialogref: MatDialogRef<CreateShopListComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly shopListService: ShopListService,
    private readonly newAddressService: NewAddressService,
    private readonly zonesService: ZonesService,
  ) {
  }

  ngOnInit(): void {
    this.contact?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((contact) => {
        this.isAutoComplete = !!contact;
        this.loadCountryStatesData();
      });

    const { isModeEdit, isChangeLocation, isConvertFromCart, isConvertFromOrder, variantId, count } = this.data ?? {};

    this.isModeEdit = isModeEdit;
    this.isChangeLocation = isChangeLocation;
    this.isConvertFromCart = isConvertFromCart;
    this.isConvertFromOrder = isConvertFromOrder;

    this.variantId = variantId;
    this.count = count;

    if (this.isModeEdit || this.isChangeLocation) {
      this.shopList = this.data.shopList;

      if (this.isModeEdit) {
        // Disabling fields.
        this.setDisableAddress();
        this.contact?.disable();
      }

      if (this.isChangeLocation) {
        // Disabling fields when changing zone.
        this.setDisableNameDescription();
      }
    }

    if (this.isConvertFromOrder) {
      this.contact?.setValidators([Validators.required]);
      this.setDisableAddress();
    }

    this.loadAddress();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  private isNewState(obj: any): obj is NewState {
    return 'zoneId' in obj;
  }

  submit() {
    this.isSaving = true;

    const formData: ShopListForm = this.form.getRawValue();
    const countrySelected: NewCountry = this.countries.find(country => country.iso2 === formData.country_iso)!;
    const provinceSelected: NewState | Zone = this.states.find(state => state.name === formData.state_name.trim())!;
    const citySelected: RestrictedCity | string = this.cities.find(city => city.name?.trim() === formData.city.trim())! || formData.city;

    const data: ShopList = {
      name: formData.name,
      description: formData.description,
      contactId: formData.contact?.id,
      countryId: countrySelected.id,
      countryName: countrySelected.name,
      zoneId: this.isNewState(provinceSelected) ? provinceSelected.zoneId : String(provinceSelected.id),
      zoneName: provinceSelected.name,
      municipalityId: citySelected?.id || '',
      municipalityName: String(citySelected?.name || citySelected),
    }

    let request: Observable<GenericShopListResponse> = this.shopListService.addShopList(data);
    if (this.variantId && this.count) {
      request = this.shopListService.addShopList(data)
        .pipe(
          switchMap(shopList => {
            const {data} = shopList;
            const addToShoppingListData: AddToShoppingListRequest = {
              id: data.id!,
              variantId: this.data.variantId,
              count: this.data.count,
            };
            this.shoppingListAutoAdding = data;

            return this.shopListService.addToShopList(addToShoppingListData)
          })
        );
    }

    if (this.isModeEdit) {
      data['id'] = this.data.shopList.id;
      request = this.shopListService.updateShopList(data);
    }

    if (this.isChangeLocation) {
      const changeLocationData: ChangeLocationRequest = {
        id: this.data.shopList.id,
        contactId: formData.contact?.id,
        countryId: countrySelected.id,
        zoneId: this.isNewState(provinceSelected) ? provinceSelected.zoneId : String(provinceSelected.id),
        municipalityId: citySelected?.id || '',
      }
      request = this.shopListService.changeLocation(changeLocationData);
    }

    if (this.isConvertFromOrder) {
      const convertFromOrderData: ConvertFromOrderRequest = {
        contactId: formData.contact?.id,
        name: formData.name,
        description: formData.description,
        orderNumber: this.data.orderNumber,
      }
      request = this.shopListService.createFromOrder(convertFromOrderData);
    }

    if (this.isConvertFromCart) {
      request = this.shopListService.createFromCart(data);
    }

    this.executeRequest(request);
  }

  private isCreateShopListResponse(res: any): res is CreateShopListResponse {
    return 'data' in res;
  }

  private executeRequest(request: Observable<GenericShopListResponse>) {
    request.pipe(
      takeUntil(this.destroy$),
      finalize(() => this.isSaving = false)
    )
    .subscribe({
      next: (res) => {
        const {success} = res;
        const modalData: { success: boolean, shoppingList?: ShopList } = {success};
        this.isSaving = false;
        if (this.isCreateShopListResponse(res)) {
          const {data} = res;
          modalData.shoppingList = data;
        }
        if (this.shoppingListAutoAdding) {
          modalData.shoppingList = this.shoppingListAutoAdding;
        }
        this.dialogref.close(modalData);
      },
      error: () => this.isSaving = false
    });
  }

  loadAddress() {
    this.toggleLoading();
    let dataToPatch: Partial<ShopListForm> = {
      name: this.shopList?.name ?? '',
      description: this.shopList?.description ?? '',
    };
    this.newAddressService.getAddresses({
      OnlyValidForMyCart: false,
      Page: this.Page,
      PageSize: this.PageSize
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (addressesResponse: NewAddressResponse) => {
          this.addresses = addressesResponse.data.items;
          this.TotalPages = addressesResponse.data.totalPages;
          this.toggleLoading();
          if (this.isModeEdit || this.isChangeLocation || this.isConvertFromCart || this.isConvertFromOrder) {
            let contact = undefined;
            if (this.isConvertFromCart || this.isConvertFromOrder) {
              const { cartContact } = this.data;
              if (cartContact) {
                contact = this.addresses.find(address => address.id === cartContact.id)
              }
            } else {
              contact = this.addresses.find(address => address.id === this.shopList?.contact?.id);
            }

            if (!contact) {
              dataToPatch = {
                ...dataToPatch,
                // country_iso: shopList.zoneId,
                city: this.shopList?.municipalityName,
                state_name: this.shopList?.zoneName
              }
            } else {
              dataToPatch = {
                ...dataToPatch,
                contact,
              }
            }

            this.form.patchValue(dataToPatch);
            this.loadCountryStatesData();
          } else {
            this.loadCountryStatesData();
          }
        },
        error: () => {
          this.toggleLoading();
        },
      });
  }

  toggleLoading() {
    this.isLoadingContacts = !this.isLoadingContacts;
  }

  loadCountryStatesData() {
    this.isLoadingCountries = true;
    this.isLoadingStates = true;
    this.isLoadingCities = true;
    this.newAddressService.getDeliveryCountries()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: GetDeliveryCountriesResponse) => {
        this.countries = response.data.sort((a, b) => a.name.localeCompare(b.name));
        this.isLoadingCountries = false;
        if (this.isAutoComplete) {
          this.setDisableAddress();
          this.getContactDataAndPatchForm(this.contact?.value)
        } else {
          if (!this.isModeEdit) this.setEnableAddress();
          this.getZoneDataAndPatchForm(this.countries);
        }
      });
  }

  getContactDataAndPatchForm(contact: AddressItem) {
    const countryAux = contact.phoneCountryIso2;
    if (countryAux) {
      const patchData: { country_iso: string, state_name?: string, city?: string } = {country_iso: countryAux};
      this.newAddressService.getDeliveryStates(countryAux).pipe(takeUntil(this.destroy$)).subscribe( {
        next: (statesResponse: GetDeliveryStatesResponse) => {
          patchData['state_name'] = contact.stateName.trim();
          patchData['city'] = contact.city.trim();
          this.form.patchValue(patchData);

          this.showSelectInput = this.countryCuIso == countryAux;

          this.countryIsoSelected = countryAux.toLowerCase();

          this.states = [...statesResponse.data.sort((a, b) => a.name.localeCompare(b.name))] as NewState[];
          this.isLoadingStates = false;
          const stateAbbr = this.form.get('state_name')?.value;
          const stateAux = (this.states as NewState[]).find(it => it.name === stateAbbr);
          if (stateAux && this.showSelectInput) {
            this.cities = [...stateAux.restrictedCities.sort((a, b) => a.name.localeCompare(b.name))];
            this.isLoadingCities = false;
          } else {
            this.isLoadingCities = false;
          }
        }
      });
    }

  }

  getZoneDataAndPatchForm(countries: NewCountry[]) {
    const selectedZone = this.getSelectedZone;
    let countryAux: NewCountry | undefined = undefined;

    if ((this.isModeEdit || this.isChangeLocation) && this.shopList?.countryId) {
      countryAux = countries.find(it => it.id === this.shopList?.countryId);
    } else {
      countryAux = countries.find(it => it.iso2 === selectedZone.area_selected);
    }

    if (countryAux) {
      const patchData: { country_iso: string, state_name?: string, city?: string } = {
        country_iso: countryAux.iso2,
        state_name: selectedZone.zone_name.trim(),
        city: selectedZone.municipality_name?.trim()
      };

      this.countryIsoSelected = countryAux.iso2.toLowerCase();

      this.showSelectInput = this.countryCuIso == countryAux.iso2;

      this.setValidatorsForCity();

      if (this.showSelectInput) {
        this.newAddressService.getDeliveryStates(countryAux.iso2).pipe(takeUntil(this.destroy$)).subscribe( {
          next: (statesResponse: GetDeliveryStatesResponse) => {
            if (this.isModeEdit || this.isChangeLocation) {
              const shopList: ShopListItem = this.data.shopList;
              const { zoneName, municipalityName } = shopList;
              if (zoneName && municipalityName) {
                patchData['state_name'] = shopList.zoneName?.trim();
                patchData['city'] = shopList.municipalityName?.trim();
              }
            }
            this.form.patchValue(patchData);

            this.states = [...statesResponse.data.sort((a, b) => a.name.localeCompare(b.name))];
            this.isLoadingStates = false;
            const stateAbbr = this.state_name?.value;
            const stateAux = (this.states as NewState[]).find(it => it.name === stateAbbr);
            if (stateAux && this.showSelectInput) {
              this.cities = [...stateAux.restrictedCities.sort((a, b) => a.name.localeCompare(b.name))];
            }
            this.isLoadingCities = false;
          }
        });
      } else {
        this.getZonesIfUSSelected(countryAux, patchData);
      }
    }

  }

  private getZonesIfUSSelected(countryAux: NewCountry, patchData?: { country_iso: string, state_name?: string, city?: string }): void {
    if (!Object.keys(this.countriesZones).length) {
      this.zonesService.getZonesWithCountries()
        .pipe(takeUntil(this.destroy$))
        .subscribe((zones) => {
          this.countriesZones = zones.reduce((acum: any, item: Zone) => {
            if (!acum[item.countries_iso!]) {
              acum[item.countries_iso!] = [item];
            } else {
              acum[item.countries_iso!].push(item);
            }
            return {...acum};
          }, []);

          this.setStatesWhenSelectedZonesIsUS(countryAux, patchData);
        });
    } else {
      this.setStatesWhenSelectedZonesIsUS(countryAux, patchData);
    }
  }

  setStatesWhenSelectedZonesIsUS(countryAux: NewCountry, patchData?: { country_iso: string, state_name?: string, city?: string }): void {
    this.states = this.countriesZones[countryAux.iso2] as Zone[];
    if (patchData) {
      const clonedData = {...patchData, state_name: (this.states as Zone[]).find(state => String(state.id) === this.shopList?.zoneId)?.name.trim()};
      this.form.patchValue(clonedData);
    }
    this.isLoadingStates = false;
    this.isLoadingCities = false;
  }

  setDisableNameDescription() {
    this.name?.disable();
    this.description?.disable();
  }

  setDisableAddress() {
    this.form.get('city')?.disable();
    this.form.get('state_name')?.disable();
    this.form.get('country_iso')?.disable();
  }

  setEnableAddress() {
    this.form.get('city')?.enable();
    this.form.get('state_name')?.enable();
    this.form.get('country_iso')?.enable();
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  changeCountry(event: any) {
    this.isLoadingStates = true;
    this.showSelectInput = this.countryCuIso == event.value;

    this.setValidatorsForCity();

    this.form.patchValue({
      city: null,
      state_name: null,
    });
    const usCountry = this.countries.find(it => it.iso2 === event.value);
    if (usCountry) {
      this.countryIsoSelected = event.value.toLowerCase();
      if (usCountry.iso2 === this.countryCuIso) {
        this.newAddressService.getDeliveryStates(usCountry.iso2).pipe(takeUntil(this.destroy$)).subscribe((statesResponse: GetDeliveryStatesResponse) => {
          this.states = [...statesResponse.data.sort((a, b) => a.name.localeCompare(b.name))];
          this.cities = [];
          this.isLoadingStates = false;
        });
      } else {
        this.getZonesIfUSSelected(usCountry);
        this.cities = [];
      }
    }
  }

  changeState(event: any) {
    this.isLoadingCities = true;
    this.form.patchValue({
      city: null,
    });
    const countryIso = this.form.get('country_iso')?.value;
    const usCountry = this.countries.find(it => it.iso2 === countryIso);
    if (usCountry && this.countryCuIso == countryIso) {
      this.cities = [...(this.states as NewState[]).find((state) => state.name === event.value)!.restrictedCities.sort((a: RestrictedCity, b: RestrictedCity) => a.name.localeCompare(b.name))];
      this.isLoadingCities = false;
    } else {
      this.cities = [];
      this.isLoadingCities = false;
    }
  }

  get name() { return this.form.get('name'); }
  get description() { return this.form.get('description'); }
  get city() { return this.form.get('city'); }
  get state_name() { return this.form.get('state_name'); }
  get country_iso() { return this.form.get('country_iso'); }
  get contact() { return this.form.get('contact'); }

  setValidatorsForCity(): void {
    if (!this.showSelectInput) {
      this.city?.clearValidators();
      this.city?.updateValueAndValidity();
    } else {
      this.city?.addValidators(Validators.required);
      this.city?.updateValueAndValidity();
    }
  }
}
