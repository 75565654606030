<section *ngIf="true"
    class="relative w-full us:mt-163px us-landscape:mt-117px md:mt-117px lg-landscape:mt-114px h-auto flex justify-center items-start bg-gray-#f2f2f2 pb-5 us:pt-5">
    <div class="relative overflow-hidden
    us:w-full us:px-5.3vw
    lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px lg-landscape:pt-7 lg-landscape:min-h-462px">
        <div class="relative w-full flex justify-between items-center
        us:px-3
        lg-landscape:px-0">
            <p class="relative font-Lato-Regular font-semibold text-gray-#252525
                    lg-landscape:text-19px flex flex-row">{{'COMPONENTS.COMMON.RESULTS' | translate}}:
                <span class="relative text-yellow-#ff4103 pl-2" *ngIf="!isLoadingTaxonomy">{{category_name |
                    translate}}</span>
                <span class="relative flex flex-row animate-pulse h-6 w-168px pt-1 pl-2" *ngIf="isLoadingTaxonomy">
                    <span class="relative w-full my-2 bg-gray-#D4E1EE h-2"></span>
                </span>
            </p>
            <!-- <p class="relative text-yellow-#FF6D03 font-Lato-Regular font-semibold
            lg-landscape:text-sm">{{'SEE_MORE' | translate}} ></p> -->
        </div>
        <div class="relative w-auto flex justify-end items-center
    us:mb-5 us:mt-5 us:hidden
    lg-landscape:mb-5 lg-landscape:mt-0 lg-landscape:flex">
            <div class="relative w-auto" [formGroup]="filterForm">
                <label class="relative text-gray-#727272 font-Lato-Regular font-semibold
            us:text-sm
            lg-landscape:text-sm" for="">{{'COMPONENTS.COMMON.ORDER_BY' | translate}}:</label>
                <mat-select class="relative text-gray-#727272 font-Lato-Regular font-semibold border-2 rounded-3px border-solid border-gray-#ABABAB bg-gray-#f2f2f2
            us:text-sm us:w-auto us:ml-1
            lg-landscape:text-sm lg-landscape:w-auto lg-landscape:ml-2
            px-3 py-2  active:ring-0 focus:ring-0
                        ring-0 h-41px text-base col-span-1
            " formControlName="order">
                    <mat-option *ngFor="let order of orderByArray" [value]="order">{{order.text |
                        translate}}</mat-option>
                </mat-select>
            </div>
            <!--        <div class="relative w-auto us:ml-2 lg-landscape:ml-8">-->
            <!--            <label class="relative text-gray-#727272 font-Lato-Regular font-semibold-->
            <!--            us:text-sm-->
            <!--            lg-landscape:text-sm" for="">Ver como:</label>-->
            <!--            <select disabled class="relative cursor-not-allowed text-gray-#727272 font-Lato-Regular font-semibold border-2 rounded-3px border-solid border-gray-#ABABAB bg-gray-#f2f2f2-->
            <!--            us:text-sm us:w-auto us:ml-1-->
            <!--            lg-landscape:text-sm lg-landscape:w-auto lg-landscape:ml-2" name="" id="">-->
            <!--                <option value="">Grid</option>-->
            <!--            </select>-->
            <!--        </div>-->
        </div>
        <div class="relative flex justify-between">
            <div
                class="relative flex flex-col overflow-hidden box-border z-50
                       us:hidden
                       lg-landscape:flex lg-landscape:w-220px lg-landscape:px-0 lg-landscape:min-h-screen lg-landscape:relative lg-landscape:bg-transparent">
                <app-price-range (change)="findProductsByFilter($event)"></app-price-range>
            </div>
            <div class="relative flex flex-col overflow-hidden box-border
            us:w-screen
            us-landscape:w-screen
            lg-landscape:w-933px lg-landscape:px-0">
                <div class="relative w-full">
                    <div *ngIf="products?.length !== 0"
                        class="grid us:grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 items-start flex-wrap gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-2 lg-landscape:gap-x-4 mt-4">
                        <app-product-card *ngFor="let product of products" [productId]="product.id"
                            [slug]="product.slug" [imageUrl]="product?.imagesDto?.image600" [name]="product.name"
                            [compare_at_price]="product.previous_price" [price]="product.defaultPrice"
                            [currency]="product.currency" [isLowStock]="product.isLowStock"
                            [quantity]="product.quantity" [discount]="product.discount"
                            [variantId]="product?.defaultVariantId" [data_extra_types]="product.extraData"
                            [shipping_category]="product?.deliveryTime" [resize]="true" [turn]="true"
                            [store]="product.store" [top]="product?.top" [age_valid]="product?.ageValid"
                            [queryId]="product.queryId" [queryPosition]="product.queryPosition">
                        </app-product-card>
                    </div>
                    <div class="relative items-center justify-self-center h-auto w-full us:col-span-1 us-landscape:col-span-3 md:col-span-3 lg-landscape:col-span-3"
                        *ngIf="searchSkeletons.length === 0 && products?.length == 0">
                        <p
                            class="us:px-3 us:text-lg lg-landscape:text-2xl lg-landscape:px-0 text-gray-#727272 font-Lato-Regular font-semibold">
                            {{'COMPONENTS.COMMON.NO_RESULTS_FOUND' |
                            translate}}</p>
                    </div>
                    <div
                        class="grid us:grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 items-start flex-wrap gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-2 lg-landscape:gap-x-4 mt-4">
                        <app-product-card-skeleton *ngFor="let skeleton of searchSkeletons" [resize]="true"
                            [turn]="true">
                        </app-product-card-skeleton>
                    </div>
                </div>
                <div class="realtive w-full flex justify-center" *ngIf="this.next_page && products?.length !== 0">
                    <button
                        class="relative font-Lato-Regular font-medium bg-yellow-#FF6D03 rounded-full flex justify-center items-center text-white
                us:h-41px us:w-auto us:text-17px us:ml-3 us:px-6 us:mt-5
                lg-landscape:h-41px lg-landscape:w-250px lg-landscape:text-19px lg-landscape:mt-10 lg-landscape:ml-0 lg-landscape:px-4"
                        (click)="fetch_more()" [disabled]="searchSkeletons.length !== 0">
                        {{'COMPONENTS.COMMON.LOAD_MORE' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>