import { Component, Inject, OnInit } from '@angular/core';
import { Address, City, Country, SelectedZone, State } from "../../../../shared/models";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { REGULAR_EXPRESSION } from "../../../../shared/enum/regular-expression.enum";
import { Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AddressService } from "../../../../shared/services/address.service";
import { ZonesService } from "../../../../shared/services/zones.service";
import {
  AddressItem,
  GetDeliveryCountriesResponse,
  GetDeliveryStatesResponse, NewAddAddressRequest, NewAddressResponse,
  NewCountry, NewState, RestrictedCity
} from "../../../../shared/models/new-address.model";
import { takeUntil } from "rxjs/operators";

import { faClose, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NewAddressService } from "../../../../shared/services/new-address.service";
import { AuthService } from "../../../../shared/services/auth.service";
import { ProvinceSelectionComponent } from "../../../shared/components/province-selection/province-selection.component";
import { HttpEvent, HttpUserEvent } from "@angular/common/http";
import { NewCartService } from "../../../../shared/services/newcart.service";
import { Order } from "../../../../shared/models/store-order.model";

@Component({
  selector: 'app-new-add-address',
  templateUrl: './new-add-address.component.html',
  styleUrls: ['./new-add-address.component.scss']
})
export class NewAddAddressComponent implements OnInit {

  isModeEdit = false;
  isAutoComplete = false;
  isCheckout = false;
  isShippingAddress = false;
  isProfileAddress = false;
  modalHeader: string = 'ADD_ADDRESS';
  address: AddressItem | null = null;
  userNewCartConfig: boolean = false;

  addressForm: FormGroup = new FormGroup({
    id: new FormControl(this.address?.id || null),
    firstname: new FormControl(null, [
      Validators.required,
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
      Validators.maxLength(35)
    ]),
    lastname: new FormControl(null, [
      Validators.required,
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
      Validators.maxLength(35)
    ]),
    email_receiver: new FormControl(null, [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._-]+@[a-z]+\.[a-z.]{2,5}$/)]),
    address1: new FormControl(null, [
      Validators.required,
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
      Validators.maxLength(100)
    ]),
    address2: new FormControl(null, [
      Validators.maxLength(100),
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING)
    ]),
    city: new FormControl(null, [Validators.required, Validators.pattern(/^[\w\sñÑáéíóúÁÉÍÓÚ.-]+$/)]),
    state_name: new FormControl(null, Validators.required),
    country_iso: new FormControl(null, Validators.required),
    phone: new FormControl(null, Validators.required),
    dialCode: new FormControl(null, Validators.required),
    countryIso2: new FormControl(null),
    secondaryPhone: new FormControl(null),
    dialCode2: new FormControl(null),
    zipcode: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9-]+$/)]),
    // label: new FormControl(null, Validators.required),
    userInstructions: new FormControl(null, [
      Validators.maxLength(255),
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING)
    ])
  });

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedZipCodes: string[] = [];
  isLoading: boolean = true;
  isLoadingCountries: boolean = true;
  isLoadingStates: boolean = true;
  isLoadingCities: boolean = true;
  isSaving = false;
  faSpinner = faSpinner;

  countries: NewCountry[] = [];
  states: NewState[] = [];
  cities: RestrictedCity[] = [];
  postalCodes: string[] = [];
  showSelectInput: boolean = true;
  countryCuIso: string = 'CU';
  countryIsoSelected: string = 'cu';
  isLoadingPhones = true;

  formMarkAsTouched: boolean = false;

  province: string = this.getSelectedZone.area_selected === 'CU' ? this.getSelectedZone.zone_name : this.getSelectedZone.area_name;
  municipality: string = this.getSelectedZone?.municipality_name || this.getSelectedZone?.zone_name;

  isSetColorToOrange: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<NewAddAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private newAddressService: NewAddressService,
    private zonesService: ZonesService,
    private dialog: MatDialog,
    private newCartService: NewCartService
  ) {
    this.isModeEdit = data.isModeEdit;
    this.isCheckout = data.isCheckout;
    this.isShippingAddress = data.isShippingAddress;
    this.isProfileAddress = data.isProfileAddress;
    if (this.isModeEdit) {
      this.address = data.data;
      this.userNewCartConfig = data.userNewCartConfig;
      this.modalHeader = this.isShippingAddress ? 'COMPONENTS.CHECKOUT.EDIT_SHIPPING_ADDRESS' : 'COMPONENTS.CHECKOUT.EDIT_BILLING_ADDRESS';
    } else {
      this.modalHeader = this.isShippingAddress ? 'COMPONENTS.CHECKOUT.ADD_SHIPPING_ADDRESS' : 'COMPONENTS.CHECKOUT.ADD_BILLING_ADDRESS';
    }
  }

  get firstname() { return this.addressForm.get('firstname'); }
  get lastname() { return this.addressForm.get('lastname'); }
  get email_receiver() { return this.addressForm.get('email_receiver'); }
  get address1() { return this.addressForm.get('address1'); }
  get address2() { return this.addressForm.get('address2'); }
  get country_iso() { return this.addressForm.get('country_iso'); }
  get state_name() { return this.addressForm.get('state_name'); }
  get city() { return this.addressForm.get('city'); }
  get zipcode() { return this.addressForm.get('zipcode'); }
  get phone() { return this.addressForm.get('phone'); }
  get countryIso2() { return this.addressForm.get('countryIso2') }
  get secondaryPhone() { return this.addressForm.get('secondaryPhone') }
  get dialCode2() { return this.addressForm.get('dialCode2') }
  get userInstructions() { return this.addressForm.get('userInstructions'); }

  ngOnInit(): void {
    if (!this.isShippingAddress) {
      this.addressForm.removeControl('secondaryPhone');
      this.addressForm.removeControl('dialCode2');
      this.addressForm.removeControl('countryIso2');
    }
    if (this.isModeEdit) {
      let address = null;
      if (this.isShippingAddress)
        address = {
          id: this.address!.id,
          firstname: this.address!.firstname,
          lastname: this.address!.lastname,
          email_receiver: this.address!.addressEmail,
          address1: this.address!.addressLine1,
          address2: this.address!.addressLine2 || null,
          city: this.address!.city,
          state_name: this.address!.stateName,
          country_iso: this.address!.phoneCountryIso2,
          dialCode: this.address!.phoneCountryPrefix,
          phone: `+${this.address!.fullPhone}`,
          secondaryPhone: this.address?.secondaryFullPhone ? `+${this.address?.secondaryFullPhone}` : null,
          countryIso2: this.address?.secondaryPhoneCountryIso2,
          dialCode2: this.address?.secondaryPhoneCountryPrefix,
          zipcode: this.address!.zipCode,
          userInstructions: this.address?.userInstructions
        }
      else
        address = {
          id: this.address!.id,
          firstname: this.address!.firstname,
          lastname: this.address!.lastname,
          email_receiver: this.address!.addressEmail,
          address1: this.address!.addressLine1,
          address2: this.address!.addressLine2 || null,
          city: this.address!.city,
          state_name: this.address!.stateName,
          country_iso: this.address!.phoneCountryIso2,
          dialCode: this.address!.phoneCountryPrefix,
          phone: `+${this.address!.fullPhone}`,
          zipcode: this.address!.zipCode,
          userInstructions: this.address?.userInstructions
        }


      this.addressForm.patchValue(address);
      this.countryIsoSelected = this.address?.phoneCountryIso2.toLowerCase() || 'cu';
    } else {
      if (!this.isShippingAddress) {
        this.changeViewPhone();
      }
    }

    this.initLoadData();
  }

  initLoadData() {
    let requestCountries;
    if (this.isShippingAddress) {
      requestCountries = this.newAddressService.getDeliveryCountries();
    } else {
      requestCountries = this.newAddressService.getBillingCountries();
    }


    requestCountries
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: GetDeliveryCountriesResponse) => {
        this.countries = response.data.sort((a, b) => a.name.localeCompare(b.name));
        this.isLoadingCountries = false;
        if (this.isModeEdit || this.isAutoComplete) {
          const countryIso = this.addressForm.get('country_iso')?.value;
          const countryAux = this.countries.find(it => it.iso2 === countryIso);
          this.showSelectInput = this.countryCuIso == countryIso;
          if (countryAux) {
            this.changeViewPhone();
            this.newAddressService.getDeliveryStates(countryAux.iso2).pipe(takeUntil(this.destroy$)).subscribe((statesResponse: GetDeliveryStatesResponse) => {
              this.states = [...statesResponse.data.sort((a, b) => a.name.localeCompare(b.name))];
              this.isLoadingStates = false;
              const stateAbbr = this.addressForm.get('state_name')?.value;
              let stateAux = this.states.find(it => it.name === stateAbbr);
              if (!stateAux) {
                stateAux = this.states.find(it => it.code === stateAbbr);
                if (stateAux) {
                  this.addressForm.get('state_name')?.patchValue(stateAux.name);
                }
              }
              if (stateAux && this.showSelectInput) {
                if (this.isShippingAddress && !this.isProfileAddress) {
                  this.setDisableAddress(this.showSelectInput);
                }
                // this.newAddressService.getCities(countryIso, stateAux.abbr).pipe(takeUntil(this.destroy$)).subscribe((cities: City[]) => {
                this.cities = [...stateAux.restrictedCities.sort((a, b) => a.name.localeCompare(b.name))];
                const cityName = this.addressForm.get('city')?.value;
                const cityAux = this.cities.find(it => it.name == cityName);
                if (cityAux) {
                  this.postalCodes = cityAux.zipCodes;
                }
                this.isLoadingCities = false;
                // });
              } else {
                this.isLoadingCities = false;
              }
            });
          } else {
            this.isLoadingStates = false;
            this.isLoadingCities = false;
          }
        } else {
          if (this.isShippingAddress) {
            this.getZoneDataAndPatchFormOnShippingAddress(this.countries);
          } else {
            this.isLoadingStates = false;
            this.isLoadingCities = false;
          }
        }
      });
  }

  getZoneDataAndPatchFormOnShippingAddress(countries: NewCountry[]) {
    const selectedZone = this.getSelectedZone;
    const countryAux = countries.find(it => it.iso2 === selectedZone.area_selected);
    if (countryAux) {
      const patchData: { country_iso: string, countryIso2: string, state_name?: string, city?: string } = { country_iso: countryAux.iso2, countryIso2: countryAux.iso2 };
      this.newAddressService.getDeliveryStates(countryAux.iso2).pipe(takeUntil(this.destroy$)).subscribe({
        next: (statesResponse: GetDeliveryStatesResponse) => {
          patchData['state_name'] = selectedZone.zone_name.trim();
          patchData['city'] = selectedZone.municipality_name.trim();
          this.addressForm.patchValue(patchData);

          this.showSelectInput = this.countryCuIso == countryAux.iso2;

          if (!this.isProfileAddress) {
            this.setDisableAddress(this.showSelectInput);
          }

          this.countryIsoSelected = countryAux.iso2.toLowerCase();
          this.changeViewPhone();

          this.states = [...statesResponse.data.sort((a, b) => a.name.localeCompare(b.name))];
          this.isLoadingStates = false;
          const stateAbbr = this.addressForm.get('state_name')?.value;
          let stateAux = this.states.find(it => it.name === stateAbbr);
          if (!stateAux) {
            stateAux = this.states.find(it => it.code === stateAbbr);
            if (stateAux) {
              this.addressForm.get('state_name')?.patchValue(stateAux.name);
            }
          }
          if (stateAux && this.showSelectInput) {
            this.cities = [...stateAux.restrictedCities.sort((a, b) => a.name.localeCompare(b.name))];
            const cityName = this.addressForm.get('city')?.value;
            const cityAux = this.cities.find(it => it.name == cityName);
            if (cityAux) {
              this.postalCodes = cityAux.zipCodes;
              if (this.postalCodes.length === 1) {
                this.zipcode?.patchValue(this.postalCodes[0]);
              }
            }
            this.isLoadingCities = false;
          } else {
            this.isLoadingCities = false;
          }
        }
      });
    }

  }

  handleClickEvent(event: any) {
    if (this.isShippingAddress && this.isCheckout && !this.isSetColorToOrange) {
      this.isSetColorToOrange = true;
      setTimeout(() => this.isSetColorToOrange = false, 1500);
      const elements = document.getElementById('notificationMessageCheckout');
      elements?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }

  changeCountry(event: any) {
    this.isLoadingStates = true;
    this.showSelectInput = this.countryCuIso == event.value;
    this.addressForm.patchValue({
      state_name: null,
      city: null,
      zipcode: null,
      phone: null,
    });
    const usCountry = this.countries.find(it => it.iso2 === event.value);
    if (usCountry) {
      this.countryIsoSelected = event.value.toLowerCase();
      this.changeViewPhone();
      if (this.isShippingAddress) {
        this.newAddressService.getDeliveryStates(usCountry.iso2).pipe(takeUntil(this.destroy$)).subscribe((statesResponse: GetDeliveryStatesResponse) => {
          this.states = [...statesResponse.data.sort((a, b) => a.name.localeCompare(b.name))];
          this.cities = [];
          this.postalCodes = [];
          this.isLoadingStates = false;
        });
      } else {
        this.newAddressService.getBillingStates(usCountry.iso2).pipe(takeUntil(this.destroy$)).subscribe((statesResponse: GetDeliveryStatesResponse) => {
          this.states = [...statesResponse.data.sort((a, b) => a.name.localeCompare(b.name))];
          this.cities = [];
          this.postalCodes = [];
          this.isLoadingStates = false;
        });
      }
    }
  }

  changeViewPhone() {
    this.isLoadingPhones = true;
    setTimeout(() => {
      this.isLoadingPhones = false;
    }, 300);
  }

  changeState(event: any) {
    this.isLoadingCities = true;
    this.addressForm.patchValue({
      city: null,
      zipcode: null,
    });
    const countryIso = this.addressForm.get('country_iso')?.value;
    const usCountry = this.countries.find(it => it.iso2 === countryIso);
    if (usCountry && this.countryCuIso == countryIso) {
      this.cities = [...this.states.find((state) => state.name === event.value)!.restrictedCities.sort((a: RestrictedCity, b: RestrictedCity) => a.name.localeCompare(b.name))];
      this.postalCodes = [];
      this.isLoadingCities = false;
    } else {
      this.isLoadingCities = false;
    }
  }

  changeCity(event: any) {
    this.addressForm.patchValue({
      zipcode: null,
    });
    const city = this.cities.find(it => it.name === event.value);
    if (city) {
      this.postalCodes = city.zipCodes;
      if (this.postalCodes.length === 1) {
        this.zipcode?.patchValue(this.postalCodes[0]);
      }
    } else {
      this.postalCodes = [];
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  onChangeCountryPhone(event: any) {
    this.addressForm.get('dialCode')?.patchValue(event.dialCode);
  }
  onChangeCountrySecondaryPhone(event: any) {
    this.addressForm.get('dialCode2')?.patchValue(event.dialCode);
  }

  onSubmit(): void {
    this.addressForm.markAllAsTouched();
    this.addressForm.get('phone')?.updateValueAndValidity();
    this.formMarkAsTouched = true;
    if (this.addressForm.valid) {
      this.isSaving = true;
      const address = this.addressForm.getRawValue();
      address.city = address.city.trim();
      address.countryIso2 = address.country_iso;
      this.setDisableForm();

      const selectedState = this.states.find((state) => state.name === address.state_name);

      const addressData: NewAddAddressRequest = {
        addressEmail: address.email_receiver,
        firstname: address.firstname,
        lastname: address.lastname,
        addressLine1: address.address1,
        addressLine2: address.address2,
        phoneNumber: address.phone.replace(`+${address.dialCode}`, ''),
        phoneCountryPrefix: address.dialCode,
        phoneCountryIso2: address.country_iso,
        secondaryPhoneNumber: address.secondaryPhone ? address.secondaryPhone.replace(`+${address.dialCode2}`, '') : null,
        secondaryPhoneCountryPrefix: address.secondaryPhone ? address.dialCode2 : null,
        secondaryPhoneCountryIso2: address.secondaryPhone ? address.countryIso2 : null,
        stateName: address.state_name,
        stateCode: selectedState!.code,
        city: address.city,
        zipCode: address.zipcode,
        userInstructions: address.userInstructions,
      }

      if (!this.isModeEdit) {
        delete address.id;
        if (this.isShippingAddress) {
          this.newAddressService.addAddresses(addressData).pipe(takeUntil(this.destroy$))
            .subscribe((response) => {
              this.isSaving = false;
              this.setEnableForm();
              this.dialogRef.close(Object.assign(response, { modified: true }));
            }, (err) => {
              // this.notificationService.showAndSubscribe('ADD_ADDRESS_SUCCESS', 'ACCEPT');
              this.setEnableForm();
              this.isSaving = false;
            });
        } else {
          this.setBillingAddressFunc(<AddressItem>addressData);
        }

      } else {
        if (this.isShippingAddress) {
          this.newAddressService.updateAddresses({ id: this.address!.id!, ...addressData }).pipe(takeUntil(this.destroy$))
            .subscribe((response) => {
              this.isSaving = false;
              this.setEnableForm();
              this.dialogRef.close(Object.assign(response, { modified: true, email_receiver: address.email_receiver }));
            }, (err) => {
              this.setEnableForm();
              this.isSaving = false;
            });
        } else {
          this.setBillingAddressFunc(<AddressItem>addressData);
        }
      }
    }
  }

  setBillingAddressFunc(addressData: AddressItem) {
    this.newAddressService.setBillingAddresses(addressData).pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.isSaving = false;
        this.setEnableForm();
        this.dialogRef.close(Object.assign(response, { modified: true }));
      }, (err) => {
        // this.notificationService.showAndSubscribe('ADD_ADDRESS_SUCCESS', 'ACCEPT');
        this.setEnableForm();
        this.isSaving = false;
      });
  }

  close(): void {
    this.dialogRef.close(null);
  }

  setDisableForm() {
    this.addressForm.get('zipcode')?.disable();
    this.addressForm.get('firstname')?.disable();
    this.addressForm.get('lastname')?.disable();
    this.addressForm.get('address1')?.disable();
    this.addressForm.get('address2')?.disable();
    this.addressForm.get('city')?.disable();
    this.addressForm.get('phone')?.disable();
    this.addressForm.get('zipcode')?.disable();
    this.addressForm.get('state_name')?.disable();
    this.addressForm.get('country_iso')?.disable();
  }

  setDisableAddress(showSelectInput: boolean) {
    if (showSelectInput) {
      this.addressForm.get('city')?.disable();
    }
    this.addressForm.get('state_name')?.disable();
    this.addressForm.get('country_iso')?.disable();
  }

  setEnableForm() {
    this.addressForm.get('zipcode')?.enable();
    this.addressForm.get('firstname')?.enable();
    this.addressForm.get('lastname')?.enable();
    this.addressForm.get('address1')?.enable();
    this.addressForm.get('address2')?.enable();
    this.addressForm.get('city')?.enable();
    this.addressForm.get('phone')?.enable();
    this.addressForm.get('zipcode')?.enable();
    this.addressForm.get('state_name')?.enable();
    this.addressForm.get('country_iso')?.enable();
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  protected readonly faClose = faClose;

  openChangeProvinceModal() {
    this.close();
    let dialoRef = this.dialog.open(ProvinceSelectionComponent, {
      disableClose: false,
      // position: { top: '60px' }
    });
    dialoRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ((data: any) => {

        }),
        error: (err => {
          throw new Error(err);
        })
      });
  }

  importShippingAddressData(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    const order: Order | undefined = this.newCartService.newCartOrderSubject$.value;
    this.isAutoComplete = target.checked;
    if (order && target.checked) {
      this.isLoading = true;
      this.isLoadingCountries = true;
      this.isLoadingStates = true;
      this.isLoadingCities = true;

      this.newAddressService.getAddresses({
        Filters: `id=${order.shippingAddress!.id}`,
        OnlyValidForMyCart: false,
        Page: 1,
        PageSize: 10
      }).subscribe({
        next: (addressesResponse: NewAddressResponse) => {
          console.log(addressesResponse);
          const address = {
            id: addressesResponse.data.items[0]!.id,
            firstname: addressesResponse.data.items[0]!.firstname,
            lastname: addressesResponse.data.items[0]!.lastname,
            email_receiver: addressesResponse.data.items[0]!.addressEmail,
            address1: addressesResponse.data.items[0]!.addressLine1,
            address2: addressesResponse.data.items[0]!.addressLine2 || null,
            city: addressesResponse.data.items[0]!.city,
            state_name: addressesResponse.data.items[0]!.stateName,
            country_iso: addressesResponse.data.items[0]!.phoneCountryIso2,
            dialCode: addressesResponse.data.items[0]!.phoneCountryPrefix,
            phone: `+${addressesResponse.data.items[0]!.fullPhone}`,
            countryIso2: addressesResponse.data.items[0]?.secondaryPhoneCountryIso2,
            dialCode2: addressesResponse.data.items[0]?.secondaryPhoneCountryPrefix,
            secondaryPhone: `+${addressesResponse.data.items[0]?.secondaryFullPhone}` ? addressesResponse.data.items[0]?.secondaryFullPhone : null,
            zipcode: addressesResponse.data.items[0]!.zipCode,
            userInstructions: addressesResponse.data.items[0]?.userInstructions
          };
          this.addressForm.patchValue(address);
          this.countryIsoSelected = order.shippingAddress?.countryCode.toLowerCase() || 'cu';
          this.initLoadData();
        },
        error: () => {

        },
        complete: () => {

        }
      });
    }
  }
}
