<div class="relative w-full flex flex-col gap-1 " *ngIf="message" [ngClass]="{'pt-2 mt-7': position > 0}">
    <div class="bg-yellow-#FFC35D flex flex-row p-17px font-Lato-Regular justify-between">
        <div>{{ message }}</div>
    </div>
</div>
<div class="relative w-full flex justify-start items-start
                            pt-2 mt-7" [ngClass]="{'pt-0 mt-0': message}">

    <div class="relative w-auto h-auto">
        <div [ngClass]="itemCount > 99 ? 'w-6 h-6' : 'w-5 h-5'"
            class="absolute z-10 rounded-full flex justify-center items-center bg-gray-#5d5d5d -right-1 -top-1">
            <p [ngClass]="itemCount > 99 ? 'text-10px' : 'text-xs'"
                class="relative font-Lato-Regular font-semibold text-white">{{itemCount}}</p>
        </div>
        <div class="w-100px h-100px">
            <img class="relative w-100px h-100px border-2"
                [src]="imageUrl || '../../../../../assets/imgs/product-card/no-product-image.png'" alt="">
        </div>
    </div>

    <div class="relative w-auto overflow-hidden flex flex-col justify-start items-start ml-18px">
        <p class="relative w-full break-words font-Lato-Regular text-base text-blue-#18396C cursor-pointer"
            [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'products', slug]">
            {{productName}}
        </p>

        <p class="relative w-full text-gray-#5d5d5d font-Lato-Regular font-bold
                                    text-13px tracking-0.7px">
            <!-- {{'VARIANT' | translate}}:  -->
            <span class="font-normal">{{variant}}</span>
        </p>

        <div class="relative w-full flex justify-start items-center mt-2" *ngIf="promotionCode">
            <p class="relative font-Lato-Regular font-semibold bg-gray-#D4E1EE text-gray-#252525 h-full
                        text-13px md:text-11px lg:text-13px pl-2 pr-2 rounded-10px">
                <!-- Código de promoción aplicado: -->
                {{'COMPONENTS.PAYMENTS.APPLIED_PROMO' | translate}}: {{promotionCode}}
            </p>
        </div>

        <p class="relative font-Lato-Regular font-bold text-gray-#252525
        text-base mt-1"><span class="line-through
        mr-2" *ngIf="promotionCode">$9.55</span> {{productPrice | currency: currency}}</p>

        <div class="relative flex justify-start items-center
        mt-10px">
            <div class="relative flex justify-center items-center border border-gray-#929292 overflow-hidden box-border
            rounded-l-20px rounded-r-20px h-27px">
                <button (click)="decreaseQuantity()" class="relative cursor-pointer h-full bg-gray-#f2f2f2 flex justify-center
                    items-center font-Lato-Regular text-gray-#979797 font-semibold
                text-lg w-7" [disabled]="quantity === 1 || data_extra_types.length !== 0">
                    -
                </button>
                <p class="relative h-full flex justify-center items-center font-Lato-Regular text-gray-#5d5d5d
                w-31px text-sm">{{quantity}}</p>
                <button (click)="increaseQuantity()" [disabled]="data_extra_types.length !== 0" class="relative cursor-pointer h-full bg-gray-#f2f2f2 flex justify-center
                    items-center font-Lato-Regular text-gray-#979797 font-semibold
                text-lg w-7">
                    +
                </button>
            </div>
            <button class="relative font-Lato-Regular font-medium text-gray-#252525
                    text-13px ml-18px" [ngClass]="{'cursor-pointer': !blockDeletingProduct}"
                [disabled]="blockDeletingProduct" (click)="deleteProductFromCart(id)" *ngIf="!isDeletingProduct">
                {{'COMPONENTS.PAYMENTS.DELETE' | translate}}
            </button>
            <fa-icon *ngIf="isDeletingProduct"
                class="relative ml-25px flex justify-center items-center animate-spin bg-gray-#5d5d5d rounded-full w-6 h-6 text-xl text-white"
                [icon]="faSpinner">
            </fa-icon>
            <div class="relative w-px bg-gray-#252525 h-3
            ml-3 mr-3" *ngIf="false"></div>
            <img class="relative w-15px cursor-pointer" src="../../../../../assets/imgs/icon-wish.svg" alt=""
                *ngIf="false">
        </div>
    </div>

</div>