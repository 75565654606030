import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {catchError, map, tap} from "rxjs/operators";
import { Address, Order, ProductInCart, ShipmentInterfaceModel } from "../models";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NewCartService } from "./newcart.service";
import {NewCart, PaymentRequestDetailsResponse, Root} from "../models/new-cart.model";
import { StoreOrderMeResponse } from "../models/store-order.model";
import {LanguageService} from "./language.service";

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private resource = '/storefront/account/orders'; // peticion para /api/v2
  private resource_v1 = '/Orders/StoreOrder/Me';
  private service_orders_resource = `${environment.wallet_api_url}${environment.v1}/Orders/StoreOrder/Me`;
  private service_orders_expired_resource = `${environment.wallet_api_url}${environment.v1}/Orders/StoreOrder/Me/Expired`;
  private service_order_detail_resource = `${environment.wallet_api_url}${environment.v1}/Orders/StoreOrder/Details`;
  private service_order_cancel_resource = `${environment.wallet_api_url}${environment.v1}/Orders/StoreOrder/Cancel`;
  private service_order_revert_resource = `${environment.wallet_api_url}${environment.v1}/Orders/StoreOrder/RevertPlacement`;
  private service_order_repurshase_resource = `${environment.wallet_api_url}${environment.v1}/Orders/StoreOrder/Repurchase`;
  private received_payment_requests = `${environment.wallet_api_url}${environment.v1}/Payment/PaymentRequest/ReceivedPaymentRequests`;
  private sent_payment_requests = `${environment.wallet_api_url}${environment.v1}/Payment/PaymentRequest/RequestedPaymentRequests`;
  private payment_request_resource = `${environment.wallet_api_url}${environment.v1}/Payment/PaymentRequest`;

  paginationSubject: BehaviorSubject<{ count: number, total_count: number, total_pages: number }>;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private translateService: TranslateService,
    private http: HttpClient,
    private newCartService: NewCartService,
    private languageService: LanguageService,
  ) { this.paginationSubject = new BehaviorSubject<{ count: number, total_count: number, total_pages: number }>({ count: 0, total_count: 0, total_pages: 0 }); }
  /**
   *
   * @param locale
   * @param page
   * @param per_page
   * @param include
   * @param sort
   */
  getOrders(
    locale = 'es',
    page = 1,
    per_page = 10,
    include = 'line_items,variants,variants.images,variants.product.images,billing_address,shipping_address,user,payments,shipments,promotions,shipments.shipping_rates,line_items.data_extras,variants.product.data_extra_types',
    // sort = '',
    sort = '-completed_at',
  ) {
    const httpParams = new HttpParams()
      .set('locale', locale)
      .set('page', page)
      .set('per_page', per_page)
      .set('include', include)
      .set('sort', sort);

    return this.apiService.get(this.resource, httpParams).pipe(
      map(response => {
        this.paginationSubject.next({ count: response.meta.count, total_count: response.meta.total_count, total_pages: response.meta.total_pages });
        const orders: Order[] = response.data.map((resOrder: any) => {
          const items = this.mapItemsInCart(resOrder, response);
          const shipments = this.mapShipments(resOrder.relationships?.shipments?.data, response);

          const groupedItemsByShipment: { shipment: ShipmentInterfaceModel, items: ProductInCart[] }[] = [];
          shipments.forEach((shipment: ShipmentInterfaceModel) => {
            const itemsFromShipment = items.filter((item: ProductInCart) => String(item.inventory_unit?.shipment_id) === shipment.id);
            groupedItemsByShipment.push({
              shipment: shipment,
              items: itemsFromShipment
            });
          });

          return <Order>{
            id: resOrder.id,
            number: resOrder.attributes.number,
            itemTotal: resOrder.attributes.item_total,
            total: resOrder.attributes.total,
            shipTotal: resOrder.attributes.ship_total,
            completedAt: resOrder.attributes.completed_at,
            createdAt: resOrder.attributes.created_at,
            updatedAt: resOrder.attributes.updated_at,
            currency: resOrder.attributes.currency,
            paymentState: resOrder.attributes.payment_state,
            state: resOrder.attributes.state,
            shippingAddress: this.mapShippingAddress(resOrder.relationships?.shipping_address?.data?.id, response),
            items,
            shipments,
            groupedItemsByShipment
          }
        });
        return orders;
      })
    );
  }

  getLastAddressByOrders(locale = 'es') {
    const httpParams = new HttpParams()
      .set('locale', locale)
      .set('page', 1)
      .set('per_page', 1)
      .set('include', 'shipping_address')
      .set('sort', '-completed_at');

    return this.apiService.get(this.resource, httpParams).pipe(
      map(response => {
        const orders: Order[] = response.data.map((resOrder: any) => {
          const shipmentId: string = resOrder.relationships.shipping_address.data.id;
          const shipmentAddress = response.included.find((it: any) => it.id == shipmentId);
          if (shipmentAddress && shipmentAddress.id) {
            return <Order>{
              id: resOrder.id,
              shipmentAddress: {
                id: shipmentAddress.id,
                firstname: shipmentAddress.attributes.firstname,
                lastname: shipmentAddress.attributes.lastname,
                address1: shipmentAddress.attributes.address1,
                address2: shipmentAddress.attributes.address2,
                city: shipmentAddress.attributes.city,
                zipcode: shipmentAddress.attributes.zipcode,
                phone: shipmentAddress.attributes.phone,
                state_name: shipmentAddress.attributes.state_name,
                company: shipmentAddress.attributes.company,
                country_name: shipmentAddress.attributes.country_name,
                country_iso3: shipmentAddress.attributes.country_iso3,
                country_iso: shipmentAddress.attributes.country_iso,
                label: shipmentAddress.attributes.label,
                state_code: shipmentAddress.attributes.state_code,
                email_receiver: resOrder.attributes.email_receiver
              }
            }
          } else {
            return <Order>{
              id: resOrder.id,
            }
          }
        });
        return orders;
      })
    );
  }

  /**
   * @description Mapea los datos de la direccion de entrega a partir de los datos del response
   * @param addressId
   * @param response
   * @private
   */
  private mapShippingAddress(addressId: number, response: any): Address {
    const address = response.included.find((include: any) => include.type === 'address' && include.id === addressId);
    return {
      id: address.id,
      city: address.attributes.city,
      address1: address.attributes.address1,
      address2: address.attributes.address2,
      state_name: address.attributes.state_name,
      firstname: address.attributes.firstname,
      zipcode: address.attributes.zipcode,
      lastname: address.attributes.lastname,
      company: address.attributes.company,
      country_iso: address.attributes.country_iso,
      country_iso3: address.attributes.country_iso3,
      country_name: address.attributes.country_name,
      label: address.attributes.label,
      phone: address.attributes.phone,
      state_code: address.attributes.state_code,
    }
  }

  private mapItemsInCart(orderResponse: any, response: any): ProductInCart[] {
    const productsInCart: ProductInCart[] = [];

    orderResponse.relationships.line_items.data.forEach((li: any) => {
      const lineItem = response.included.find((obj: any) => obj.type === 'line_item' && obj.id === li.id);
      const usedVariantId = lineItem.relationships.variant.data?.id; // "1056"
      const usedVariantObj = response.included.find((obj: any) => obj.type === 'variant' && obj.id === usedVariantId);
      let imageId = usedVariantObj?.relationships?.images?.data[0]?.id || null;
      if (imageId === null) {
        const usedProductId = usedVariantObj.relationships.product.data?.id; // "1029"
        const usedProductObj = response.included.find((obj: any) => obj.type === 'product' && obj.id === usedProductId);
        imageId = usedProductObj?.relationships?.images?.data[0]?.id || null;
      }


      const pic: ProductInCart = {
        id: lineItem.id,
        name: lineItem.attributes.name,
        quantity: lineItem.attributes.quantity,
        currency: lineItem.attributes.currency,
        price: lineItem.attributes.price,
        optionsText: lineItem.attributes.options_text,
        imageUrl: this.findImageFromResponse(imageId, response.included),
        inventory_unit: lineItem.attributes.inventory_unit,
      };
      productsInCart.push(pic);
    });

    return productsInCart;
  }

  private findImageFromResponse(imageId: string, dataArray: any[]) {
    if (imageId) {
      return dataArray.find((obj: any) => obj.type === 'image' && obj.id === imageId)?.attributes?.styles[9]?.url
        || '../../../../../assets/imgs/product-card/no-product-image.png';
    }
    return '../../../../../assets/imgs/product-card/no-product-image.png';
  }

  getOrder(
    orderNumber: string,
    include = 'line_items,variants,variants.images,variants.product.images,billing_address,shipping_address,user,payments,shipments,promotions,shipments.shipping_rates,line_items.data_extras,variants.product.data_extra_types',
  ) {
    const httpParams = new HttpParams()
      .set('include', include);
    return this.apiService.get(`${this.resource}/${orderNumber}`, httpParams);
  }

  private mapShipments(shipments: any[], response: any): ShipmentInterfaceModel[] {
    return response.included.filter((include: any) => include.type === 'shipment' && shipments.some((s) => s.id === include.id))
      .map((shipment: any) => {
        return <ShipmentInterfaceModel>{
          id: shipment.id,
          store_ids: shipment.attributes.store_ids,
          final_price: shipment.attributes.final_price,
          display_final_price: shipment.attributes.display_final_price,
          number: shipment.attributes.number,
          free: shipment.attributes.free,
          shipping_rates: shipment.relationships.shipping_rates.data,
        }
      });
  }

  getServiceApiOrders(page: number, page_size: number, filters?: any): Observable<StoreOrderMeResponse> {
    let httpParams = new HttpParams()
      .set('page', page)
      .set('sorts', '-CreatedAt')
      .set('pageSize', page_size);
    if (filters) httpParams = httpParams.set('date', filters.date);
    return this.http.get<StoreOrderMeResponse>(`${this.service_orders_resource}`, { headers: this.getHeader(), params: httpParams });
  }

  getServiceApiExpiredOrders(page: number, page_size: number, filters?: any): Observable<StoreOrderMeResponse> {
    let httpParams = new HttpParams()
      .set('page', page)
      .set('sorts', '-CreatedAt')
      .set('pageSize', page_size);
    if (filters) httpParams = httpParams.set('date', filters.date);
    return this.http.get<StoreOrderMeResponse>(`${this.service_orders_expired_resource}`, { headers: this.getHeader(), params: httpParams });
  }

  cancelServiceOrder(body: { storeOrderId: string }): Observable<any> {
    return this.http.put(`${this.service_order_cancel_resource}`, body, { headers: this.getHeader() });
  }

  revertServiceOrder(body: { storeOrderId: string }): Observable<any> {
    return this.http.post(`${this.service_order_revert_resource}`, body, { headers: this.getHeader() })
      .pipe(
        tap((response: any) => {
          this.newCartService.saveInSessionAndSubject(<NewCart>response.data.cart);
          this.newCartService.cleanStorage();
          this.newCartService.cleanStorageDeliveryAndPayment();
        })
      );
  }

  repurchaseServiceOrder(body: { storeOrderId: string }): Observable<any> {
    return this.http.post(`${this.service_order_repurshase_resource}`, body, { headers: this.getHeader() })
      .pipe(
        tap((response: any) => {
          this.newCartService.saveInSessionAndSubject(<NewCart>response.data.cart);
          this.newCartService.cleanStorage();
          this.newCartService.cleanStorageDeliveryAndPayment();
        })
      );
  }

  getServiceApiOrderDetails(id: string): Observable<any> {
    const httpParams = new HttpParams()
      .set('StoreOrderId', id)
    return this.http.get(`${this.service_order_detail_resource}`, { headers: this.getHeader(), params: httpParams })
        .pipe(
            catchError((error) => this.apiService.handleErrors(error, true))
        );
  }

  /**
   * Get headers
   * @private
  */
  private getHeader(): HttpHeaders {
    let httpHeaders = this.authService.getHeader();
    httpHeaders = httpHeaders
      .set('X-K-App', '1')
      // .set('X-Payment-Client', environment.xApiClient)
      .set('Accept-Language', this.languageService.selectedLanguage.value);
    return httpHeaders;
  }

  getReceivedPaymentRequests(page: number, page_size: number, filters?: any): Observable<any> {
    let httpParams = new HttpParams()
      .set('page', page)
      .set('sorts', '-CreatedAt')
      .set('pageSize', page_size);
    if (filters) httpParams = httpParams.set('date', filters.date);
    return this.http.get(`${this.received_payment_requests}`, { headers: this.getHeader(), params: httpParams });
  }

  getSentPaymentRequests(page: number, page_size: number, filters?: any): Observable<any> {
    let httpParams = new HttpParams()
      .set('page', page)
      .set('sorts', '-CreatedAt')
      .set('pageSize', page_size);
    if (filters) httpParams = httpParams.set('date', filters.date);
    return this.http.get(`${this.sent_payment_requests}`, { headers: this.getHeader(), params: httpParams });
  }

  getPaymentRequestData(publicCode: string): Observable<PaymentRequestDetailsResponse> {
    let httpParams = new HttpParams()
      .set('PublicCode', publicCode);

    return this.http.get<PaymentRequestDetailsResponse>(`${this.payment_request_resource}/AvailablePaymentRequests`, { headers: this.getHeader(), params: httpParams });
  }

  cancelPaymentRequest(paymentRequestId: string): Observable<Root> {
    return this.http.post<Root>(`${this.payment_request_resource}/Cancel`, {paymentRequestId}, { headers: this.getHeader() })
      .pipe(
        catchError((error): Observable<never> => this.apiService.handleErrors(error, true))
      );
  }
}
