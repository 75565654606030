<section class="relative bg-white
us:w-full us:h-auto us:pl-4 us:pr-5 us:pt-5 us:pb-8
us-landscape:w-568px us-landscape:pb-5 us-landscape:max-h-75vh us-landscape:overflow-y-auto
md:w-528px md:max-h-max
md-landscape:w-528px md-landscape:max-h-max
lg-landscape:w-603px lg-landscape:h-auto lg-landscape:min-h-0 lg-landscape:p-23px">
    <div class="relative w-full flex justify-center items-center">
        <img class="relative w-5/12" src="../../../../../assets/menu/logo.svg" alt="">
    </div>
    <div class="relative w-full border-t border-t-gray-#E0E0E0
    us:mt-3
    lg-landscape:mt-3"></div>
    <div class="relative flex justify-start">
        <div class="relative
        us:w-full
        lg-landscape:w-full">
            <p class="relative w-full font-Lato-Regular font-bold text-center
                      us:text-base us:leading-5 us:mt-3
                      us-landscape:text-base
                      md:text-lg
                      md-landscape:text-lg
                      lg-landscape:text-2xl lg-landscape:mt-2 lg-landscape:px-10">
                <span class="w-full text-center
                us:text-base
                us-landscape:text-base
                md:text-lg
                md-landscape:text-xl
                lg-landscape:text-3xl"></span><br>
                {{'COMPONENTS.AGE_MODAL.AGE_RESTRICTION_TEXT' | translate}},
                {{'COMPONENTS.AGE_MODAL.AGE_RESTRICTION_TEXT_2' |
                translate}}
            </p>
            <!-- <p class="relative w-full font-Lato-Regular text-center
                      us:text-sm us:leading-5 us:mt-3
                      us-landscape:text-sm
                      md:text-base
                      md-landscape:text-base
                      lg-landscape:text-base lg-landscape:leading-5 lg-landscape:mt-3">{{'AGE_RESTRICTION_TEXT_2' |
                translate}}
            </p> -->
        </div>
        <img class="relative" src="" alt="">
    </div>
    <form class="relative w-full
                us:grid-cols-1 us:gap-y-6 us:mt-6
                us-landscape:grid-cols-1 us-landscape:gap-x-1.5 us-landscape:mt-4
                md:grid-cols-1 md:gap-x-1.5 md:mt-10
                lg-landscape:gap-x-6px lg-landscape:mt-7 " [formGroup]="ageForm">

        <div class="relative w-full grid
                us:grid-cols-1 us:gap-y-3 us:mt-0
                us-landscape:grid-cols-2 us-landscape:gap-x-1.5 us-landscape:mt-4
                md:grid-cols-3 md:gap-x-1.5 md:mt-6
                 lg-landscape:grid-cols-3 lg-landscape:gap-x-6px lg-landscape:mt-5">
            <div class="relative w-full pb-3">
                <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                            us:text-15px us:ml-3 us:mb-1
                            md:mb-2.5
                            lg-landscape:text-15px lg-landscape:ml-18px lg-landscape:mb-3" for="">
                    {{'COMPONENTS.COMMON.DAY' | translate}}*</p>
                <mat-select required class="relative font-Lato-Regular w-full
                                            us:text-15px us:h-auto us:rounded-3px
                                            us-landscape:h-auto
                                            md:h-auto
                                            lg-landscape:text-15px px-4 py-3 bg-gray-#f2f2f2
                                            active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                                            focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1
            " placeholder="{{'COMPONENTS.COMMON.SELECT' | translate}}" formControlName="day">
                    <mat-option [value]="day" *ngFor="let day of days">{{day}}</mat-option>
                </mat-select>
                <!-- <div *ngIf="day?.invalid && (zone?.dirty || day?.touched)"
                    class="absolute bottom-0.5 text-sm text-red-500">
                    <p *ngIf="day?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                </div> -->
            </div>
            <!-- <div class="relative flex flex-col flex-1 justify-start pb-6">
                <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                  us:text-15px us:ml-3 us:mb-1
                  md:mb-2.5
                  lg-landscape:text-15px lg-landscape:ml-18px lg-landscape:mb-3" for="">{{'ZONE' | translate}}*
                </p>
                <div
                    class="bg-gray-#f2f2f2 flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272 min-h-48px">
                    <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
                </div>
            </div> -->


            <div class="relative w-full pb-3">
                <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                            us:text-15px us:ml-3 us:mb-1
                            md:mb-2.5
                            lg-landscape:text-15px lg-landscape:ml-18px lg-landscape:mb-3" for="">
                    {{'COMPONENTS.COMMON.MONTH' | translate}}*</p>
                <mat-select class="relative font-Lato-Regular w-full
                                    us:text-15px us:h-12 us:rounded-3px
                                    us-landscape:h-auto
                                    md:h-auto
                                    lg-landscape:text-15px px-4 py-3 bg-gray-#f2f2f2
                                    active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                                    focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1
            " placeholder="{{'COMPONENTS.COMMON.SELECT' | translate}}" formControlName="month">
                    <mat-option [value]="month" *ngFor="let month of months">{{month}}</mat-option>
                </mat-select>
                <!-- <div class="absolute bottom-0.5 text-sm text-red-500">
                    <p *ngIf="month.length && month?.hasError('required')">{{ 'REQUIRED_FIELD' |
                        translate }}</p>
                    <p *ngIf="months.length == 0">{{ 'EMPTY_OPTION_LIST' | translate }}</p>
                </div> -->
            </div>
            <!-- <div class="relative flex flex-col flex-1 justify-start pb-6">
                <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                  us:text-15px us:ml-3 us:mb-1
                  md:mb-2.5
                  lg-landscape:text-15px lg-landscape:ml-18px lg-landscape:mb-3" for="">{{'MUNICIPALITY' | translate}}*
                </p>
                <div
                    class="bg-gray-#f2f2f2 flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272 min-h-48px">
                    <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
                </div>
            </div> -->

            <div class="relative w-full pb-3">
                <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                            us:text-15px us:ml-3 us:mb-1
                            md:mb-2.5
                            lg-landscape:text-15px lg-landscape:ml-18px lg-landscape:mb-3" for="">
                    {{'COMPONENTS.COMMON.YEAR' | translate}}*</p>
                <mat-select class="relative font-Lato-Regular w-full
                                    us:text-15px us:h-12 us:rounded-3px
                                    us-landscape:h-auto
                                    md:h-auto
                                    lg-landscape:text-15px px-4 py-3 bg-gray-#f2f2f2
                                    active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                                    focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1
            " placeholder="{{'COMPONENTS.COMMON.SELECT' | translate}}" formControlName="year">
                    <mat-option [value]="year" *ngFor="let year of years">{{year}}</mat-option>
                </mat-select>
                <!-- <div class="absolute bottom-0.5 text-sm text-red-500">
                    <p *ngIf="month.length && month?.hasError('required')">{{ 'REQUIRED_FIELD' |
                        translate }}</p>
                    <p *ngIf="months.length == 0">{{ 'EMPTY_OPTION_LIST' | translate }}</p>
                </div> -->
            </div>
        </div>
        <p [ngClass]="!is_older && show_error ? 'opacity-100' : 'opacity-0'"
            class="relative w-full text-red-500 text-sm font-Lato-Regular font-semibold transition-all ease-in-out">
            {{'COMPONENTS.AGE_MODAL.MIN_AGE' | translate}}</p>

        <div class="relative w-full">
            <div class="relative w-full pt-1">
                <input
                    class="focus:ring-offset-0 relative cursor-pointer border border-gray-#9D9D9D focus:outline-none active:outline-none focus:ring-0 active:ring-0"
                    type="checkbox" formControlName="agree">
                <label class="relative font-Lato-Regular text-sm lg-landscape:ml-3 text-gray-#252525" for="">
                    {{'COMPONENTS.AGE_MODAL.BY_ENTERING' | translate}}
                </label>
            </div>
        </div>
    </form>


    <div class="relative w-full flex
    us:justify-start us:mt-6
    lg-landscape:mt-7 lg-landscape:justify-end">
        <button class="relative bg-yellow-#FFC35D flex items-center
        us:w-111px us:h-41px us:rounded-3px
        lg-landscape:w-111px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px" type="button"
            [ngClass]="{'opacity-50 cursor-not-allowed': ageForm.invalid || !is_older || !ageForm.controls['agree']?.value,
             'cursor-pointer': ageForm.valid && is_older && ageForm.controls['agree']?.value}"
            [disabled]="ageForm.invalid || !is_older || ageForm.controls['agree']?.value == false" (click)="onSubmit()">
            <img class="relative
            us:w-22% us:ml-2
         lg-landscape:w-22% lg-landscape:ml-2" src="../../../assets/menu/icon-location.svg" alt="">
            <p class="relative font-Lato-Bold
            us:text-15px us:leading-4
         lg-landscape:text-15px lg-landscape:leading-4">{{'COMPONENTS.COMMON.ACCEPT' | translate}}</p>
        </button>

        <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D
        us:w-111px us:h-41px us:rounded-3px
        lg-landscape:w-111px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px ml-2" type="button"
            (click)="close()">
            <p class="relative font-Lato-Bold
         text-15px leading-4">{{'COMPONENTS.COMMON.CANCEL' | translate}}</p>
        </button>
    </div>
</section>