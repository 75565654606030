<!--w-220px-->
<div class="flex bg-white box-border cursor-pointer flex-col sm:flex-col justify-between p-17px gap-4"
  [ngClass]="{'w-220px': !resize, 'us:flex-row h-auto sm:h-362px': turn, 'h-362px': !turn }">
  <a [routerLink]="cardNavigation()"  [queryParams]="queryParams" class="relative"
    [ngClass]="{'h-100px w-100px sm:w-full sm:h-149px': turn, 'w-full h-149px': !turn}">
    <!-- <img class="absolute w-6 top-4 right-4 z-10" src="../../../../../assets/imgs/product-card/icon-wish.svg" alt=""> -->
    <div class="grid-img absolute w-full h-full z-10 opacity-70 transition-all ease-in-out duration-700">
    </div>
    <img (load)="loadedImg($event)" class="relative object-contain transition-all ease-in-out duration-700 opacity-0"
      [ngClass]="{'h-100px w-100px sm:w-full sm:h-149px': turn, 'w-full h-149px': !turn}"
      src="{{imageUrl || '../../../../../assets/imgs/product-card/no-product-image'}}" alt="">
  </a>

  <div class="flex flex-col"
    [ngClass]="turn ? 'us:w-64% us-landscape:w-full md:w-full lg-landscape:w-full' : 'w-full'">
    <a class="relative w-full font-Lato-Regular text-blue-#18396C line-clamp-3 text-sm leading-tight"
      [routerLink]="cardNavigation()"  [queryParams]="queryParams">
      {{name}}
    </a>
    <a class="relative font-Lato-Regular font-semibold text-10px"
      [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', store]">
      {{store}}
    </a>
    <a class="relative w-full font-Lato-Regular text-gray-#212121 font-bold text-xl"
       [routerLink]="cardNavigation()"  [queryParams]="queryParams">
      <div>
        {{price | currency: currency}}
      </div>
    </a>

    <div class="relative w-full flex justify-between items-center mt-3 gap-2">
      <div
        class="relative flex justify-center items-center border border-gray-#929292 overflow-hidden box-border rounded-l-20px rounded-r-20px h-35px">
        <button (click)="decreaseQuantity()" class="relative cursor-pointer h-full bg-gray-#f2f2f2 flex justify-center items-center font-Lato-Regular text-gray-#979797
            text-25px w-38px">
          -
        </button>
        <p (click)="setCustomQuantity()"
          class="relative overflow-hidden cursor-pointer h-full flex justify-center items-center font-Lato-Regular text-gray-#5d5d5d w-38px text-17px">
          {{quantity}}</p>
        <button (click)="increaseQuantity()"
          class="relative cursor-pointer h-full bg-gray-#f2f2f2 flex justify-center items-center font-Lato-Regular text-gray-#979797
            text-25px w-38px">
          +
        </button>
      </div>
      <div *ngIf="!isAddProduct">
        <fa-icon *ngIf="!spin"
                 class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-9 h-9 text- text-white"
                 [icon]="cart_icon" (click)="addToCart()">
        </fa-icon>
        <fa-icon *ngIf="spin"
                 class="relative flex justify-center items-center animate-spin bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                 [icon]="faSpinner">
        </fa-icon>
      </div>
      <div *ngIf="!isAddProduct">
        <fa-icon *ngIf="spinDeleting"
                 class="relative flex justify-center items-center animate-spin bg-blue-#18396C rounded-full w-9 h-9 text-xl text-white"
                 [icon]="faSpinner">
        </fa-icon>
        <fa-icon *ngIf="!spinDeleting"
                 class="relative flex justify-center items-center  bg-blue-#18396C rounded-full w-9 h-9 text- text-white"
                 [icon]="faTrash" (click)="removeFromShoppingList()">
        </fa-icon>
      </div>
      <div *ngIf="isAddProduct">
        <fa-icon *ngIf="spinAddingProduct"
                 class="relative flex justify-center items-center animate-spin bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                 [icon]="faSpinner">
        </fa-icon>
        <img *ngIf="!spinAddingProduct" (click)="addProductToShoppingList()"
          class="relative flex justify-center items-center rounded-full w-9 h-9 p-1 stroke-white fill-white text-white bg-yellow-#FF6D03"
          src="assets/shop-list/list-add_white.svg" alt="list add icon"/>
      </div>
    </div>

  </div>
</div>
