export interface SearchFiltersType { filter: string, filterBy: string, text: string };

export const SEARCH_FILTER_DEFAULT_VALUE: SearchFiltersType = { filter: '-updated_at', filterBy: 'newest-first', text: 'RELEVANCE' };

export const SEARCH_FILTERS: SearchFiltersType[] = [
  SEARCH_FILTER_DEFAULT_VALUE,
  { filter: 'price', filterBy: 'price-low-to-high', text: 'COMPONENTS.STORES.LOWEST_PRICE' },
  { filter: '-price', filterBy: 'price-high-to-low', text: 'COMPONENTS.STORES.HIGHEST_PRICE' },
  { filter: 'discount_desc', filterBy: 'discount-price', text: 'COMPONENTS.STORES.DISCOUNT' },
  // { filter: 'discount', filterBy: 'discount', text: 'LOWEST_DISCOUNT' },
  // { filter: '-discount', filterBy: '-discount', text: 'HIGHEST_DISCOUNT' },
];

export const ORDERS_FILTERS: any[] = [
  { value: 1, text: 'COMPONENTS.PROFILE.ORDERS.LAST_MONTH' },
  { value: 2, text: 'COMPONENTS.PROFILE.ORDERS.LAST_3_MONTH' },
  { value: 3, text: 'COMPONENTS.PROFILE.ORDERS.LAST_YEAR' },
  { value: 4, text: 'COMPONENTS.PROFILE.ORDERS.ALL' },
];
