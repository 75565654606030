import { Injectable } from '@angular/core';
import { catchError, map } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { ACHDepositType, EnumType, MultipleWalletAccount, ProfileWalletDataType, WalletAccount } from "../models";
import { StorageService } from "./storage.service";
import { Router } from "@angular/router";
import { ZonesService } from "./zones.service";
import { ApiService } from "./api.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, retry, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { NotificationService } from "./notification.service";
import { DebitDepositType, TransactionType, WalletResponse } from "../models";
import { ErrorsEnum } from "../enum/errors.enum";
import { TranslateService } from "@ngx-translate/core";
import { FingerprintService } from "./fingerprint.service";
import { RemittanceStatusEnum } from "../../modules/remittance/enums/remittance-status.enum";
import { TransferRequestEnum } from "../enum/transfer-request.enum";

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  // private resource: string = '/storefront/wallet';  // api/v2
  private resource: string = `${environment.wallet_api_url}${environment.v1}/Payment/Wallet/Me`;  // api/v1
  private resourceNewImplementation: string = `${environment.wallet_api_url}${environment.v1}/Payment/Wallets`;  // api/v1
  private resourceEnumDiscover: string = `${environment.wallet_api_url}${environment.v1}/discovery/enum`;  // api/v1
  private apply_gift_card: string = `${environment.wallet_api_url}${environment.v1}/Payments/GiftCards/Redeem`;  // api/v1
  private la_nave_payment: string = `${environment.wallet_api_url}${environment.v1}/LaNave/LaNaveOrders/Options`;  // api/v1
  private giftCard_products: string = `${environment.wallet_api_url}${environment.v1}/GiftCardOrders/GiftCardProducts`;  // api/v1
  private la_nave_validate_destination: string = `${environment.wallet_api_url}${environment.v1}/LaNave/LaNaveOrders/ValidateDestination`;  // api/v1
  private transferRequestUrl: string = `${environment.wallet_api_url}${environment.v1}/Payment/Wallet/TransferRequest`;  // api/v1
  walletSubject: BehaviorSubject<WalletAccount | null> = new BehaviorSubject<WalletAccount | null>(null);
  walletSubjectNewImplementation: BehaviorSubject<MultipleWalletAccount | null>;
  maxRetries = environment.retry_on_fail;

  WALLET_IMPL_STORAGE_ROUTE = 'walletsSubjectImpl';

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private http: HttpClient,
    private notificationService: NotificationService,
    private fingerprintService: FingerprintService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private router: Router,
    private zoneService: ZonesService,
  ) {
    this.walletSubjectNewImplementation = new BehaviorSubject<MultipleWalletAccount | null>(this.getWalletSubjectNewImpl());
  }

  /**
   * @description get wallet account
   */
  getAccount(): Observable<WalletAccount> {
    // @ts-ignore
    return this.http.get(`${this.resource}`, { headers: this.getHeader() }).pipe(
      // @ts-ignore
      map((walletResponse: WalletResponse) => {
        const data: WalletAccount = <WalletAccount>walletResponse.data;
        if (walletResponse.fails) {
          if (walletResponse.responseCode !== ErrorsEnum.WALLET_USAGE_NOT_ALLOWED) {
            this.walletSubject.next(null);
            this.showError(walletResponse.message, true);
          }
        } else {
          if (data.balance && data.balance > 0) {
            data.balance = Number(Number(data.balance / 100).toFixed(2));
          }
          if (data.pendingBalance && data.pendingBalance > 0) {
            data.pendingBalance = Number(Number(data.pendingBalance / 100).toFixed(2));
          }
          this.walletSubject.next(data);
        }
        return data;
      }),
      retry(this.maxRetries),
      catchError((error) => {
        this.walletSubject.next(null);
        return this.handleErrors(error, true);
      })
    );
  }

  /**
   * @description get wallet accounts (New Implementation)
   */
  getAccountsNewImplementation(): Observable<MultipleWalletAccount> {
    // @ts-ignore
    return this.http.get(`${this.resourceNewImplementation}/Me`, { headers: this.getHeader() })
      .pipe(
        // @ts-ignore
        map((walletResponse: WalletResponse) => {
          const data: MultipleWalletAccount = <MultipleWalletAccount>walletResponse.data;
          if (walletResponse.fails) {
            if (walletResponse.responseCode !== ErrorsEnum.WALLET_USAGE_NOT_ALLOWED) {
              this.walletSubjectNewImplementation.next(null);
              this.saveWalletSubjectNewImpl(null);
              this.showError(walletResponse.message, true);
            }
          } else {
            data.profileWalletData.forEach((wallet: ProfileWalletDataType) => {
              if (wallet?.walletData?.balance > 0) {
                wallet.walletData.balance = Number(Number(wallet.walletData.balance / 100).toFixed(2));
              }
              if (wallet?.walletData?.pendingBalance > 0) {
                wallet.walletData.pendingBalance = Number(Number(wallet.walletData.pendingBalance / 100).toFixed(2));
              }
            });
            this.walletSubjectNewImplementation.next(data);
            this.saveWalletSubjectNewImpl(data);
          }
          return data;
        }),
        retry(this.maxRetries),
        catchError((error) => {
          this.walletSubjectNewImplementation.next(null);
          this.saveWalletSubjectNewImpl(null);
          return this.handleErrors(error, true);
        })
      );
  }

  getWalletSubjectNewImpl(): MultipleWalletAccount | null {
    const data = this.storageService.get(this.WALLET_IMPL_STORAGE_ROUTE);
    if (data) {
      return <MultipleWalletAccount>data;
    }
    return null;
  }

  saveWalletSubjectNewImpl(data: MultipleWalletAccount | null) {
    if (data) {
      this.storageService.set(this.WALLET_IMPL_STORAGE_ROUTE, data);
    } else {
      this.storageService.remove(this.WALLET_IMPL_STORAGE_ROUTE);
    }
  }

  /**
   * @description get wallet transactions
   * @param page
   * @param per_page
   * @param walletTierId
   */
  getTransactions(
    page: number, per_page: number, walletTierId: number
  ): Observable<TransactionType> {
    const httpParams = new HttpParams()
      .set('Page', page)
      .set('PageSize', per_page);
    // @ts-ignore
    return this.http.get(`${this.resource}/Operations2?walletTierId=${walletTierId}`, { headers: this.getHeader(), params: httpParams })
      .pipe(
        // @ts-ignore
        map((response: WalletResponse) => {
          if (response.fails) {
            this.showError(response.message, true);
          }
          return <TransactionType>response.data;
        }),
        retry(this.maxRetries),
        catchError((error) => {
          return this.handleErrors(error, true);
        })
      );
  }

  /**
   * @description make a wallet deposit (Debit)
   */
  makeADeposit(data: DebitDepositType) {
    return this.http.post(`${this.resource}/TopUp/Debit`, data, { headers: this.getHeader() });
  }

  /**
   * @description make a wallet deposit (ACH)
   */
  makeADepositACH(data: ACHDepositType) {
    return this.http.post(`${this.resource}/TopUp/ACH`, data, { headers: this.getHeader() });
  }

  /**
   * @description is wallet active?
   */
  getIsActive() {
    return this.walletSubject.value?.isActive;
  }

  /**
   * @description set wallet founds
   */
  sendFunds(data: { amount: number, publicAddress: string, senderWalletTierId: number, transferSecret: string }): Observable<WalletResponse> {
    // @ts-ignore
    return this.http.post(`${this.resource}/Transfer`, data, { headers: this.getHeader() });
  }


  /**
   * @description activate wallet
   */
  activateWallet(): Observable<WalletResponse> {
    // @ts-ignore
    return this.http.post(`${this.resource}/v2`, {}, { headers: this.getHeader() });
  }

  /**
   * Get headers
   * @private
  */
  private getHeader(): HttpHeaders {
    let httpHeaders = this.authService.getHeader();
    httpHeaders = httpHeaders
      .set('X-Payment-Client', environment.xApiClient)
      .set('Accept-Language', this.translateService.currentLang);
    return httpHeaders;
  }

  getBinLookupInfo(cardNum: string) {
    if (!cardNum || cardNum === '') {
      return Promise.resolve({});
    }
    const noSpaceCardNum = cardNum.trim().replace(' ', '');
    const NUM_OF_DIGITS_TO_SEND = 6;
    if (noSpaceCardNum.length >= NUM_OF_DIGITS_TO_SEND) {
      // @ts-ignore
      const lookup = require('binlookup')();
      return lookup(noSpaceCardNum.slice(0, NUM_OF_DIGITS_TO_SEND));
    }
    return Promise.resolve({});
  }

  private handleErrors(error: any, showError: boolean = true): any {
    const msg = error?.error?.error || error?.error?.message || null;
    this.showError(msg, showError);

    window.scrollTo(0, 0);
    return throwError(error);
  }

  private showError(msg: string, show: boolean): void {
    if (show && msg) {
      this.notificationService.showAndSubscribe(msg, 'COMPONENTS.COMMON.CLOSE');
      console.log('Response error => ', msg);
    } else {
      if (show) {
        this.notificationService.showAndSubscribe('NOTIFICATION_MESSAGES.GENERIC_ERROR', 'COMPONENTS.COMMON.CLOSE');
      }
    }
  }

  async getFingerprintData() {
    let sign = null;
    const data = await this.fingerprintService.getVisitorData(this.maxRetries);
    if (data) {
      sign = {
        requestId: data.requestId,
        visitorId: data.visitorId,
      };
    }

    return sign;
  }

  /**
   *
   * @param EnumName
   * @description EnumName values <BankAccountOwnershipType, BankAccountType>
  */
  enumDiscover(EnumName: string = ''): Observable<EnumType[]> {
    // @ts-ignore
    return this.http.get(`${this.resourceEnumDiscover}`,
      {
        headers: this.getHeader(),
        params: new HttpParams().set('EnumName', EnumName)
      })
      .pipe(
        // @ts-ignore
        map((response: WalletResponse) => {
          if (response.fails) {
            this.showError(response.message, true);
          }
          return <EnumType>response.data;
        }),
        retry(this.maxRetries),
        catchError((error) => {
          return this.handleErrors(error, true);
        })
      );
  }

  getTransferCode(data: { walletAddress: string, amount: number }) {
    return this.http.post(`${this.resourceNewImplementation}/TransferCode`,
      data,
      {
        headers: this.getHeader(),
      })
      .pipe(
        retry(this.maxRetries),
        catchError((error) => {
          return this.handleErrors(error, true);
        })
      );
  }

  redirectInCaseWalletInfoIsNotFound(walletData: MultipleWalletAccount | null) {
    if (this.authService.isAuthenticated()) {
      if (!walletData) {
        this.router.navigateByUrl(`/${this.zoneService.getZonesValuesStorage()?.area_selected?.toLowerCase()}/profile/payments/1`);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  setWalletsSettings(data: { walletOperationConfirmationTypeId: number, enableVerificationOnPurchase: boolean }) {
    return this.http.put(`${this.resourceNewImplementation}/Settings/Me`,
      data,
      {
        headers: this.getHeader(),
      })
      .pipe(
        retry(this.maxRetries),
        catchError((error) => {
          return this.handleErrors(error, true);
        })
      );
  }

  /**
   * @description activate wallet
   */
  applyGiftCard(body: any): Observable<any> {
    // @ts-ignore
    return this.http.post(`${this.apply_gift_card}`, body, { headers: this.getHeader() });
  }

  /**
   * @description get nave products
   */
  getNaveCards(): Observable<any> {
    // @ts-ignore
    return this.http.get(`${this.la_nave_payment}`, { headers: this.getHeader() });
  }

  /**
   * @description get giftcards products
   */
  getGiftCards(): Observable<any> {
    // @ts-ignore
    return this.http.get(`${this.giftCard_products}`, { headers: this.getHeader() });
  }

  /**
   * @description activate wallet
   */
  validateNaveDestination(destination: string, destination_type: number): Observable<any> {
    // @ts-ignore
    return this.http.get(`${this.la_nave_validate_destination}`, {
      headers: this.getHeader(),
      params: new HttpParams()
        .set('Destination', destination)
        .set('DestinationTypeId', destination_type)
    });
  }

  getReceivedCount(walletTierId: number) {
    const httpParams = new HttpParams()
      .set('WalletTierId', walletTierId);
    return this.http.get(this.transferRequestUrl + '/ReceivedCount', {
      headers: this.getHeader(), params: httpParams
    });
  }

  requestTransfer(data: any) {
    return this.http.post(this.transferRequestUrl, data, {
      headers: this.getHeader()
    });
  }

  accept(data: { id: string, transferSecret: string }) {
    return this.http.post(this.transferRequestUrl + '/Approve', data, {
      headers: this.getHeader()
    });
  }

  reject(id: string) {
    return this.http.post(this.transferRequestUrl + '/Reject', { id: id }, {
      headers: this.getHeader()
    });
  }

  getRequestTransferByStatus(walletTierId: number, status: TransferRequestEnum, pageSize: number, currentPage: number) {
    let httpParams;
    // if (status === TransferRequestEnum.RECEIVED) {
    httpParams = new HttpParams()
      .set('WalletTierId', walletTierId)
      .set('page', currentPage)
      .set('pageSize', pageSize)
      .set('sorts', 'statusId');
    // }
    // else {
    //     httpParams = new HttpParams()
    //         .set('page', currentPage)
    //         .set('pageSize', pageSize)
    //         .set('sorts', '-transactionTime');
    // }

    return this.http.get(this.transferRequestUrl + '/' + status, { params: httpParams, headers: this.getHeader() })
      .pipe(
        retry(environment.retry_on_fail),
        catchError((error: any) => this.apiService.handleErrors(error, true))
      );
  }
}
