<section class="relative bg-white
us:w-full us:h-auto us:pl-4 us:pr-5 us:pt-5 us:pb-8
us-landscape:w-568px us-landscape:pb-5 us-landscape:max-h-75vh us-landscape:overflow-y-auto
md:w-528px md:max-h-max
md-landscape:w-528px md-landscape:max-h-max
lg-landscape:w-603px lg-landscape:h-auto lg-landscape:min-h-0 lg-landscape:p-23px">
  <p class="relative uppercase font-Lato-Regular font-semibold
    us:text-sm us:tracking-1.75px
    lg-landscape:text-sm lg-landscape:tracking-1.75px">{{'COMPONENTS.COMMON.AGREE_SCND' | translate}}</p>
  <div class="relative w-full border-t border-t-gray-#E0E0E0
    us:mt-3
    lg-landscape:mt-3"></div>
  <div class="relative flex justify-start text-sm text-gray-#727272 mt-1 h-auto">
    <div *ngIf="!isLoadingAgreement" [innerHTML]="agreementData?.attributes?.Text"
      class="relative text-left font-Lato-Regular text-gray-#5d5d5d us:text-13px us:font-medium mt-4 h-4 lg-landscape:text-sm lg-landscape:font-medium">
    </div>
    <div *ngIf="isLoadingAgreement" class="relative w-full h-40
                  flex flex-col gap-0.5 mt-4">
      <p *ngFor="let pos of [0,1,2,3,4,5,6,7,8]" class="relative text-left font-Lato-Regular text-gray-#5d5d5d
                us:text-13px us:font-medium h-2 animate-pulse bg-gray-300
                lg-landscape:text-sm lg-landscape:font-medium rounded">
      </p>
    </div>
  </div>
  <div class="relative w-full flex
    us:justify-start us:mt-6
    lg-landscape:mt-7 lg-landscape:justify-end">
    <!--    <button class="relative bg-yellow-#FFC35D flex items-center-->
    <!--        us:w-111px us:h-41px us:rounded-3px-->
    <!--        lg-landscape:w-111px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px" type="button" (click)="onSubmit()">-->
    <!--      <img class="relative-->
    <!--            us:w-22% us:ml-2-->
    <!--         lg-landscape:w-22% lg-landscape:ml-2" src="../../../assets/menu/icon-location.svg" alt="">-->
    <!--      <p class="relative font-Lato-Bold-->
    <!--            us:text-15px us:leading-4-->
    <!--         lg-landscape:text-15px lg-landscape:leading-4">{{'ACCEPT' | translate}}</p>-->
    <!--    </button>-->

    <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D
        us:w-111px us:h-41px us:rounded-3px
        lg-landscape:w-111px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px ml-2" type="button"
      mat-dialog-close="close">
      <p class="relative font-Lato-Bold
         text-15px leading-4">{{'COMPONENTS.COMMON.CLOSE' | translate}}</p>
    </button>
  </div>
</section>