<div *ngIf="form" [formGroup]="form" class="relative flex flex-col justify-start items-start w-fullmt-4">
    <label *ngIf="meta.label"
        class="relative font-Lato-Regular font-semibold text-gray-#5d5d5d text-sm leading-21px mb-1" for="">
        {{meta.label}}
    </label>
    <div class="relative w-full box-border pb-6">
        <input [placeholder]="meta.placeHolder" (keyup)="keyup($event)" [id]="meta.fieldId"
            [formControlName]="meta.fieldId" [type]="meta.receiverFormFieldType" [maxLength]="meta.maxLength"
            (paste)="onPaste($event)" [ngClass]="{
                   'border-none': selfControl?.valid || (selfControl?.invalid && !formMarkAsTouched && !(selfControl?.dirty || selfControl?.touched)),
                   'border-red-500': (selfControl?.invalid && formMarkAsTouched) || (selfControl?.invalid && (selfControl?.dirty || selfControl?.touched))
               }" class="border relative px-3 bg-white text-gray-#252525 font-Lato-Regular w-full active:ring-0
                      placeholder-gray-#9D9D9D active:outline-none focus:ring-0 focus:outline-none
                      outline-none ring-0 border-none h-47px text-base">
        <div *ngIf="selfControl?.invalid && formMarkAsTouched || (selfControl?.invalid && !formMarkAsTouched && (selfControl?.dirty || selfControl?.touched))"
            class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="selfControl?.hasError('required')">{{ 'COMPONENTS.COMMON.REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="selfControl?.hasError('pattern')">{{ 'COMPONENTS.COMMON.WRONG_FORMAT' | translate }}</p>
            <p *ngIf="selfControl?.hasError('minlength') && !selfControl?.hasError('pattern')">{{
                'COMPONENTS.COMMON.MIN_LENGTH' |
                translate: {minLength: meta.minLength} }}</p>
            <p *ngIf="selfControl?.hasError('maxlength') && !selfControl?.hasError('pattern')">{{
                'COMPONENTS.COMMON.MAX_LENGTH' |
                translate: {maxLength: meta.maxLength} }}</p>
        </div>
        <!--        <div *ngIf="selfControl?.invalid && (selfControl?.dirty || selfControl?.touched)"-->
        <!--            class="absolute top-105% text-sm text-red-500 pl-1">-->
        <!--            <p *ngIf="selfControl?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>-->
        <!--            <p *ngIf="selfControl?.hasError('pattern')">{{ 'WRONG_FORMAT' | translate }}</p>-->
        <!--        </div>-->
    </div>

    <!--    <div class="flex flex-1 flex-col relative pb-6 w-full">-->
    <!--      &lt;!&ndash;  formMarkAsTouched&ndash;&gt;-->
    <!--        <input [placeholder]="meta.placeholder" [id]="meta.fieldId" [formControlName]="meta.fieldId" [type]="meta.receiverFormFieldType"-->
    <!--               [ngClass]="{-->
    <!--                   'border-none': selfControl?.valid || (selfControl?.invalid && !formMarkAsTouched && !(selfControl?.dirty || selfControl?.touched)),-->
    <!--                   'border-red-500': (selfControl?.invalid && formMarkAsTouched) || (selfControl?.invalid && (selfControl?.dirty || selfControl?.touched))-->
    <!--               }"-->
    <!--               class="border relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none-->
    <!--                     focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">-->
    <!--        <div *ngIf="selfControl?.invalid && formMarkAsTouched || (selfControl?.invalid && !formMarkAsTouched && (selfControl?.dirty || selfControl?.touched))"-->
    <!--             class="absolute bottom-0.5 text-sm text-red-500">-->
    <!--            <p *ngIf="selfControl?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>-->
    <!--            <p *ngIf="selfControl?.hasError('pattern')">{{ 'WRONG_FORMAT' | translate }}</p>-->
    <!--        </div>-->
    <!--    </div>-->

    <!--  valid: {{selfControl?.valid}}-->
    <!--  formMarkAsTouched: {{formMarkAsTouched}}-->
</div>